import React, {useEffect, useState} from 'react';
import {InfoBanner, TableHeader} from '@services/ui-components';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import OnlineBookingSettingsTable from './OnlineBookingSettingsTable';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import { useGetModulesListQuery } from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import { useUpdateCompanyModuleRelationMutation } from '@me-team/host/main/clients/graphql/clients.hooks';

const OnlineBookingSettings: React.FC = () => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const {data: modulesList} = useGetModulesListQuery()
  const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation()
  const onlineBookingModuleId = modulesList?.relations?.modules.find(module => module.name === "Online Booking");

  useEffect(() => {
    if (onlineBookingModuleId) {
      updateCompanyModuleRelation({
        variables: {
          companyId: currentCompanyId && +currentCompanyId,
          input: {
            id: onlineBookingModuleId && +onlineBookingModuleId?.id,
            isActive: true
          }
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
    }
  }, [onlineBookingModuleId, currentCompanyId]);

  return (
    <div className='mb-5 px-2 px-xl-0 pb-5'>
      <InfoBanner>
        <p
          className='fs-7 mb-0'>{t('On this page, you can enable or disable online registration for your company as a whole, or set it up separately for each of the branches, as well as copy the URL or download the QR code to post it for your customers.')}
        </p>
      </InfoBanner>
      <TableHeader
        title={t('Online Booking Settings')}
        paths={[
          {path: `/booking/company/${currentCompanyId}/config`, label: t('Online Booking')},
          {
            path: `/booking/company/${currentCompanyId}/config`, label: t('Online Booking Settings')
          },
        ]}
        colSize={6}
        btnColSize={6}
      >
      </TableHeader>
      <Row>
        <Col>
          <span className='fs-4'>{t('Main Settings')}</span>
        </Col>
      </Row>
      <OnlineBookingSettingsTable/>
      {
        isErrorModalOpen ?
          <ErrorModalComponent
            i18n={i18next}
            onClose={() => {
              setIsErrorModalOpen(null)
            }}
            isOpen={!!isErrorModalOpen}
            currentError={isErrorModalOpen}
          /> : null
      }
    </div>

  );
};

export default OnlineBookingSettings;
