/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($companyId: Int!, $input: EmployeeInput!) {
  createEmployee(companyId: $companyId, input: $input) {
    id
    name
    surname
    dateOfBirth(format: "Y-m-d")
    email
    phone
    specialization
    aboutMe
    passportData
    itn
    image
    contract
    qualificationConfirmations {
      id
      image
    }
  }
}
    `;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const CreateEmployeePositionDocument = gql`
    mutation CreateEmployeePosition($companyId: Int!, $name: String!, $description: String!) {
  createEmployeePosition(
    companyId: $companyId
    input: {company: $companyId, name: $name, description: $description}
  ) {
    id
    name
  }
}
    `;
export type CreateEmployeePositionMutationFn = Apollo.MutationFunction<CreateEmployeePositionMutation, CreateEmployeePositionMutationVariables>;

/**
 * __useCreateEmployeePositionMutation__
 *
 * To run a mutation, you first call `useCreateEmployeePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeePositionMutation, { data, loading, error }] = useCreateEmployeePositionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateEmployeePositionMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeePositionMutation, CreateEmployeePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeePositionMutation, CreateEmployeePositionMutationVariables>(CreateEmployeePositionDocument, options);
      }
export type CreateEmployeePositionMutationHookResult = ReturnType<typeof useCreateEmployeePositionMutation>;
export type CreateEmployeePositionMutationResult = Apollo.MutationResult<CreateEmployeePositionMutation>;
export type CreateEmployeePositionMutationOptions = Apollo.BaseMutationOptions<CreateEmployeePositionMutation, CreateEmployeePositionMutationVariables>;
export const UpdateEmployeePositionDocument = gql`
    mutation UpdateEmployeePosition($companyId: Int!, $positionId: Int!, $name: String!, $description: String!) {
  updateEmployeePosition(
    positionId: $positionId
    input: {company: $companyId, name: $name, description: $description}
  ) {
    id
    name
  }
}
    `;
export type UpdateEmployeePositionMutationFn = Apollo.MutationFunction<UpdateEmployeePositionMutation, UpdateEmployeePositionMutationVariables>;

/**
 * __useUpdateEmployeePositionMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeePositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeePositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeePositionMutation, { data, loading, error }] = useUpdateEmployeePositionMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      positionId: // value for 'positionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateEmployeePositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeePositionMutation, UpdateEmployeePositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeePositionMutation, UpdateEmployeePositionMutationVariables>(UpdateEmployeePositionDocument, options);
      }
export type UpdateEmployeePositionMutationHookResult = ReturnType<typeof useUpdateEmployeePositionMutation>;
export type UpdateEmployeePositionMutationResult = Apollo.MutationResult<UpdateEmployeePositionMutation>;
export type UpdateEmployeePositionMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeePositionMutation, UpdateEmployeePositionMutationVariables>;
export const GetServicesDocument = gql`
    query GetServices {
  user {
    company {
      services {
        services {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
      }
export function useGetServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
        }
export function useGetServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesQuery, GetServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesQuery, GetServicesQueryVariables>(GetServicesDocument, options);
        }
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<typeof useGetServicesLazyQuery>;
export type GetServicesSuspenseQueryHookResult = ReturnType<typeof useGetServicesSuspenseQuery>;
export type GetServicesQueryResult = Apollo.QueryResult<GetServicesQuery, GetServicesQueryVariables>;
export const GetEmployeesPositionsDocument = gql`
    query GetEmployeesPositions {
  user {
    company {
      employeePositions {
        employeePositions {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesPositionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeesPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesPositionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeesPositionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
      }
export function useGetEmployeesPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
        }
export function useGetEmployeesPositionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>(GetEmployeesPositionsDocument, options);
        }
export type GetEmployeesPositionsQueryHookResult = ReturnType<typeof useGetEmployeesPositionsQuery>;
export type GetEmployeesPositionsLazyQueryHookResult = ReturnType<typeof useGetEmployeesPositionsLazyQuery>;
export type GetEmployeesPositionsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesPositionsSuspenseQuery>;
export type GetEmployeesPositionsQueryResult = Apollo.QueryResult<GetEmployeesPositionsQuery, GetEmployeesPositionsQueryVariables>;
export const GetEmployeeByIdDocument = gql`
    query GetEmployeeById($employeeId: Int!) {
  user {
    id
    company {
      employees(id: $employeeId) {
        employees {
          id
          status {
            id
            name
          }
          name
          surname
          patronymic
          email
          phone
          passportData
          itn
          aboutMe
          position {
            id
            name
          }
          role {
            id
            name
          }
          tariffBlocking
          manualBlocking
          specialization
          reviews {
            id
            rating
          }
          dateOfBirth
          image
          contract
          qualificationConfirmations {
            id
            image
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeByIdQuery__
 *
 * To run a query within a React component, call `useGetEmployeeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeByIdQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useGetEmployeeByIdQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables> & ({ variables: GetEmployeeByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>(GetEmployeeByIdDocument, options);
      }
export function useGetEmployeeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>(GetEmployeeByIdDocument, options);
        }
export function useGetEmployeeByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>(GetEmployeeByIdDocument, options);
        }
export type GetEmployeeByIdQueryHookResult = ReturnType<typeof useGetEmployeeByIdQuery>;
export type GetEmployeeByIdLazyQueryHookResult = ReturnType<typeof useGetEmployeeByIdLazyQuery>;
export type GetEmployeeByIdSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeByIdSuspenseQuery>;
export type GetEmployeeByIdQueryResult = Apollo.QueryResult<GetEmployeeByIdQuery, GetEmployeeByIdQueryVariables>;
export const UpdateEmployeeDocument = gql`
    mutation updateEmployee($id: Int!, $input: EmployeeInput!) {
  updateEmployee(id: $id, input: $input) {
    id
    name
    tariffBlocking
    manualBlocking
  }
}
    `;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, options);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const GetRolesDocument = gql`
    query GetRoles {
  role {
    id
    name
  }
}
    `;

/**
 * __useGetRolesQuery__
 *
 * To run a query within a React component, call `useGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export function useGetRolesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesSuspenseQueryHookResult = ReturnType<typeof useGetRolesSuspenseQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetSpecSpecializationDocument = gql`
    query GetSpecSpecialization {
  employeeSpecializations {
    name
  }
}
    `;

/**
 * __useGetSpecSpecializationQuery__
 *
 * To run a query within a React component, call `useGetSpecSpecializationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecSpecializationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecSpecializationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSpecSpecializationQuery(baseOptions?: Apollo.QueryHookOptions<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>(GetSpecSpecializationDocument, options);
      }
export function useGetSpecSpecializationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>(GetSpecSpecializationDocument, options);
        }
export function useGetSpecSpecializationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>(GetSpecSpecializationDocument, options);
        }
export type GetSpecSpecializationQueryHookResult = ReturnType<typeof useGetSpecSpecializationQuery>;
export type GetSpecSpecializationLazyQueryHookResult = ReturnType<typeof useGetSpecSpecializationLazyQuery>;
export type GetSpecSpecializationSuspenseQueryHookResult = ReturnType<typeof useGetSpecSpecializationSuspenseQuery>;
export type GetSpecSpecializationQueryResult = Apollo.QueryResult<GetSpecSpecializationQuery, GetSpecSpecializationQueryVariables>;
export type CreateEmployeeMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.EmployeeInput;
}>;


export type CreateEmployeeMutation = { __typename?: 'Mutation', createEmployee: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, dateOfBirth?: string | null, email: string, phone: string, specialization?: string | null, aboutMe?: string | null, passportData?: string | null, itn?: string | null, image?: string | null, contract?: string | null, qualificationConfirmations?: Array<{ __typename?: 'QualificationConfirmationProxy', id: number, image?: string | null }> | null } };

export type CreateEmployeePositionMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  description: Types.Scalars['String']['input'];
}>;


export type CreateEmployeePositionMutation = { __typename?: 'Mutation', createEmployeePosition: { __typename?: 'EmployeePositionProxy', id: number, name: string } };

export type UpdateEmployeePositionMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  positionId: Types.Scalars['Int']['input'];
  name: Types.Scalars['String']['input'];
  description: Types.Scalars['String']['input'];
}>;


export type UpdateEmployeePositionMutation = { __typename?: 'Mutation', updateEmployeePosition: { __typename?: 'EmployeePositionProxy', id: number, name: string } };

export type GetServicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServicesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', services?: { __typename?: 'ServicePaginated', services?: Array<{ __typename?: 'ServiceProxy', id: number, name: string }> | null } | null } | null } | null };

export type GetEmployeesPositionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmployeesPositionsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', employeePositions?: { __typename?: 'EmployeePositionPaginated', employeePositions?: Array<{ __typename?: 'EmployeePositionProxy', id: number, name: string }> | null } | null } | null } | null };

export type GetEmployeeByIdQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
}>;


export type GetEmployeeByIdQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, patronymic?: string | null, email: string, phone: string, passportData?: string | null, itn?: string | null, aboutMe?: string | null, tariffBlocking: boolean, manualBlocking: boolean, specialization?: string | null, dateOfBirth?: string | null, image?: string | null, contract?: string | null, status?: { __typename?: 'EmployeeStatusProxy', id: number, name?: string | null } | null, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null, role?: { __typename?: 'RoleProxy', id: number, name: string } | null, reviews?: Array<{ __typename?: 'ReviewProxy', id: number, rating: number }> | null, qualificationConfirmations?: Array<{ __typename?: 'QualificationConfirmationProxy', id: number, image?: string | null }> | null }> | null } | null } | null } | null };

export type UpdateEmployeeMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.EmployeeInput;
}>;


export type UpdateEmployeeMutation = { __typename?: 'Mutation', updateEmployee: { __typename?: 'EmployeeProxy', id: number, name?: string | null, tariffBlocking: boolean, manualBlocking: boolean } };

export type GetRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetRolesQuery = { __typename?: 'Query', role: Array<{ __typename?: 'RoleProxy', id: number, name: string }> };

export type GetSpecSpecializationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSpecSpecializationQuery = { __typename?: 'Query', employeeSpecializations: Array<{ __typename?: 'EmployeeSpecializationProxy', name: string } | null> };
