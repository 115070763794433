import React from 'react';
import { ReviewProxy } from '@me-team/host/main/graphql/types';

const RenderClientName: React.FC<ReviewProxy> = (review) => {
  return (
    <>
      {review?.booking ? (
        <span>{review?.booking?.client?.name} {review?.booking?.client?.surname} ({review?.name})</span>
      ) : (
        <span>{review?.name}</span>
      )}
    </>
  );
};

export default RenderClientName;
