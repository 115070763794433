import React from 'react';
import { useTranslation } from 'react-i18next';

const TooltipTextForName: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className='text-start text-dark'>
      <p className='m-0'>{t("ClientNameTooltip.The name is displayed in the format 'Olga" +
        " Semenova (Olya)'," +
        " where:")}
      </p>
      <ul className="list-unstyled">
        <li>{t("ClientNameTooltip.Olga Semenova - the client's first and last name in the client" +
          " database")}</li>
        <li>{t("ClientNameTooltip.(Olya) - the name that the client specified when adding a" +
          " review. It will be displayed in the online registration form")}</li>
      </ul>
    </div>
  );
};

export default TooltipTextForName;