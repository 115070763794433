import React from 'react';
import { Modal, Form as Formb, Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import EmployeeCell from '@services/ui-components/src/EmployeeCell/EmployeeCell';
import { useUpdateEmployeeRecordSettingsMutation } from '@me-team/host/main/employeeList/graphql/employeeList.hooks';

interface Employee {
    id: number;
    name: string;
    surname: string;
    position: {
        id: number;
        name: string;
    };
    recordSetting: {
        id: number;
        isDefaultEmployee: boolean;
        isOnlineSignUpAvailable: boolean;
        isReviewsAvailable: boolean;
    };
}

const BookingSettingsModal: React.FC<{ show: boolean; toggleModal: () => void; selectedEmployee: Employee; refetch: () => void; toggleShowToast?: () => void, goToStep: (direction: string) => void, run: boolean  }> = ({ show, toggleModal, selectedEmployee, refetch, toggleShowToast, goToStep, run }) => {
    const { t } = useTranslation();
    const initialRecordSetting = selectedEmployee?.recordSetting;

    const [recordSettings] = useUpdateEmployeeRecordSettingsMutation({
        context: {
            errorType: 'local',
        },
    });

    const handleSave = async (values) => {
        try {
            await recordSettings({
                variables: {
                    id: selectedEmployee.recordSetting.id,
                    isOnlineSignUpAvailable: values.isOnlineSignUpAvailable,
                    isDefaultEmployee: values.isDefaultEmployee,
                    isReviewsAvailable: values.isReviewsAvailable,

                }
            });
            toggleShowToast()
            refetch();
            toggleModal();
            console.log(values.isOnlineSignUpAvailable)
            run && values.isOnlineSignUpAvailable ? goToStep('next') : goToStep('back')
        } catch (error) {
            console.error('Error updating settings:', error);
        }
    };
    const handleCancel = () => {
        toggleModal();
        run && goToStep('back')
    };
    return (
        <Modal show={show} onHide={handleCancel} centered={window?.innerWidth > 745}>
            <div className='booking-modal-step'>
                <Modal.Header closeButton className='border-0'>
                    <h5 className="m-0">{t('Booking Settings')}</h5>
                </Modal.Header>
                <Formik
                    initialValues={{
                        isOnlineSignUpAvailable: initialRecordSetting?.isOnlineSignUpAvailable,
                        isDefaultEmployee: initialRecordSetting?.isDefaultEmployee,
                        isReviewsAvailable: initialRecordSetting?.isReviewsAvailable
                    }}
                    onSubmit={handleSave}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Modal.Body >

                                <div className="d-flex align-content-center mb-3 border-bottom pb-4">
                                    <EmployeeCell i={selectedEmployee} />

                                </div>

                                <Formb.Check
                                    type="switch"
                                    id="isOnlineSignUpAvailable"
                                    label={t('Online Booking')}
                                    checked={values.isOnlineSignUpAvailable}
                                    onChange={() => setFieldValue('isOnlineSignUpAvailable', !values.isOnlineSignUpAvailable)}
                                />
                                <p className='text-grey fs-7'>{t('Disallow \\ Allow displaying employee and their schedule available for online booking form.')}</p>
                                <Formb.Check
                                    type="switch"
                                    id="isDefaultEmployee"
                                    label={t('Any Specialist')}
                                    checked={values.isDefaultEmployee}
                                    onChange={() => setFieldValue('isDefaultEmployee', !values.isDefaultEmployee)}
                                />
                                <p className='text-grey fs-7'>{t('Disallow \\ Allow online booking to this employee if client has chosen the option “Employee is not important”.')}</p>
                                <Formb.Check
                                    type="switch"
                                    id="isReviewsAvailable"
                                    label={t('Rating and Reviews')}
                                    checked={values.isReviewsAvailable}
                                    onChange={() => setFieldValue('isReviewsAvailable', !values.isReviewsAvailable)}
                                />
                                <p className='text-grey fs-7 mb-0'>{t('Hide Show employees rating and reviews.')}</p>
                            </Modal.Body>
                            <Modal.Footer className='border-0'>
                                <Col>
                                    <Button variant="outline-primary" type="button" className="btn btn-outline-primary border-grey w-100 h-100 fw-normal " onClick={handleCancel}>
                                        {t('cancel')}
                                    </Button>
                                </Col>
                                <Col>
                                    <Button type="submit" className="btn btn-primary w-100 text-truncate ">
                                        {t('save')}
                                    </Button>
                                </Col>
                            </Modal.Footer>

                        </Form>
                    )}
                </Formik>
            </div>

        </Modal >
    );
};

export default BookingSettingsModal;
