import {
  ModalDelete,
  Pagination,
  RenderEmptyList,
  Spinner,
  TooltipCustom
} from '@services/ui-components';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import {Button} from 'react-bootstrap';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {
  currencySingVar,
  currentCompanyIdVar,
  currentUserRoleVar
} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../../services/ErrorService';
import SocketConnection from '../../../services/SocketConnection';
import {debounce} from '@me-team/host/src/utils/utils';
import ToastComponent from "../../ui-components/ToastComponent/ToastComponent";
import {useToast} from "../../../hooks/useToast";
import {UserRole} from '../../../utils/enums';
import ServiceNameWithOptionalLink from './ServiceNameWithOptionalLink';
import {
  useGetAllServicesQuery,
  useGetCurrencyQuery,
  useGetModulesQuery, useGetOneServiceQuery, useUpdateServiceMutation
} from '@me-team/host/main/services/graphql/services.hooks';
import { JobContainerProxy, ServiceInput, ServiceProxy } from '@me-team/host/main/graphql/types';
import JoyrideWrapper from "../../Joyride/JoyrideWrapper";
import {changeStepStorage} from "../../Joyride/joyrideCallbackHandler";

export type filterType = {
  name: string;
  categoryId: number;
  subCategoryId: number;
  id?: number
};

export type TableServicesPropsType = {
  filter: filterType;
};

const TableServices: React.FC<TableServicesPropsType> = ({filter}) => {
  const {t} = useTranslation();

  const ENTITY_IMPORT_TYPE: string = 'service';
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const userRole = useReactiveVar(currentUserRoleVar);
  const isEmployeeRole = +userRole === UserRole.EMPLOYEE;
  const currencySing = useReactiveVar(currencySingVar);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [onlineRegistrations, setOnlineRegistrations] = useState<{ [key: number]: boolean }>({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showCannotDelete, setShowCannotDelete] = useState(false);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [isLoadingRefetchServices, setIsLoadingRefetchServices] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const runJoy = localStorage.getItem('run') || false;
  const step = localStorage.getItem('step') || '0';
  const [run, setRun] = useState(runJoy);
  const [stepIndex, setStepIndex] = useState(+step);


  const { showToast, toastText, toggleShowToast, setShowToast } = useToast();

  const {data: getCurrency} = useGetCurrencyQuery(
    {
      context: {
        errorType: 'global'
      },
    }
  )
  const {data: getModules} = useGetModulesQuery({
    context: {
      errorType: 'global'
    },
  })
  const moduleWarehouses = getModules?.user?.company?.modules.find((
    module) => module.name === 'Warehouses'
  ) || null
  const [jobList, setJobList] = useState<JobContainerProxy>(null);

  const currency = getCurrency?.user?.company?.currency

  const categoryId = filter?.subCategoryId ? filter?.subCategoryId : filter?.categoryId;


  const {
    data: servicesData,
    loading,
    error,
    refetch: refetchServicesData,
  } = useGetAllServicesQuery({
    variables: {
      page: currentPage,
      itemsPerPage: itemsPerPage,
      categoryId: categoryId,
      name: filter?.name,
      id: filter?.id
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  useEffect(() => {
    refetchServicesData()
  }, []);

  const debouncedRefetch = useCallback(
    debounce(() => {
      setIsLoadingRefetchServices(true);
      refetchServicesData().then((data) => {
        setIsLoadingRefetchServices(false);
      });
    }, 1000),
    [refetchServicesData]
  );

  useEffect(() => {
    if (currentCompanyId) {
      new SocketConnection(`${process.env.REACT_APP_SOCKET}/?companyId=${currentCompanyId}`, (data: any) => {
        if (data.entity === ENTITY_IMPORT_TYPE) {
          debouncedRefetch()
        }
      })
    }
  }, [currentCompanyId, refetchServicesData]);

  const {data: oneServiceData, loading: oneServiceDataLoading, refetch: refetchOneService} = useGetOneServiceQuery({
    variables: {
      id: deleteServiceId && deleteServiceId,
    },
    context: {
      errorType: 'global'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const oneService = oneServiceData?.user?.company?.services?.services[0];

  const [updateService] = useUpdateServiceMutation();
  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  useEffect(() => {
    if (servicesData) {
      const initialRegistrations: { [key: number]: boolean } = {};
      servicesData?.user?.company?.services?.services?.forEach((service) => {
        initialRegistrations[service?.id] = service?.onlineRegistration;
      });
      setOnlineRegistrations(initialRegistrations);
    }
  }, [servicesData]);
  useEffect(() => {
      const element = document.querySelector('#table'); // Замените селектор на нужный

      if (element && window?.innerWidth <= 930) {
          element.scrollLeft = element.scrollWidth - element.clientWidth;
      }
  }, [loading, oneServiceDataLoading, isLoadingRefetchServices]);
  const handleConfirmationClose = () => setShowDeleteConfirmation(false);
  const handleCannotDeleteClose = () => setShowCannotDelete(false);

  const handleDelete = async () => {
    const input: ServiceInput = {
      name: oneService.name,
      company: oneService.company.id,
      category: oneService?.serviceCategoryNew?.id,
      duration: oneService?.duration,
      price: oneService?.price,
      onlineBooking: oneService?.onlineRegistration,
      deleted: true,
      allowDeleteDuplicate: true,
      description: oneService?.description
    }
     await updateService({
      variables: {id: deleteServiceId, input},
      context: {
        errorType: 'global'
      },
      onCompleted: (data) => {
        data && setShowDeleteConfirmation(false);
        data && refetchServicesData();
        toggleShowToast(t('Deleted'))
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  };

  const handleShowModalDelete = async (id: number) => {
    setDeleteServiceId(id);
    const {data} = await refetchOneService({id: id});
    const getEmployee = data?.user?.company?.services?.services[0];
    if (getEmployee?.employeeServiceRelations?.length < 1) {
      setShowDeleteConfirmation(true);
    } else {
      setShowCannotDelete(true);
    }
  };

  const toggleOnlineRegistration = async (id: number) => {
    const {data} = await refetchOneService({id: id});
    const serviceData = data?.user?.company?.services?.services[0]
    setOnlineRegistrations((prevState) => {
      const newValue = !prevState[id];
      const updatedState = {
        ...prevState,
        [id]: newValue,
      };
      const input: ServiceInput = {
        name: serviceData?.name,
        company: serviceData?.company?.id,
        category: serviceData?.serviceCategoryNew?.id,
        duration: serviceData?.duration,
        price: serviceData?.price,
        deleted: serviceData?.deleted,
        description: serviceData?.description,
        onlineBooking: newValue
      }
      updateService({
        variables: {id: id, input},
        context: {
          errorType: 'local'
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
      })
      return updatedState;
    });
  };
  const services = servicesData?.user?.company?.services?.services;
  const pageCount = parseInt(servicesData?.user?.company?.services?.pageCount || '0');
  const totalItems = servicesData?.user?.company?.services?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const isFilterNotEmpty = Object.values(filter).some(value => value !== '' && value !== null)

  const sortedServices =
    services &&
    [...services].sort((a, b) => {
      if (a.deleted && !b.deleted) return 1;
      if (!a.deleted && b.deleted) return -1;
      return 0;
    });

  const handleCreateService = () => {
    navigate(`/services/service/${currentCompanyId}/new`);
  };

  const handleEditService = (id: number) => {
    navigate(`/services/service/${currentCompanyId}/service-list/${id}/edit`);
  }

  const handleRecoveryService = (id: number) => {
    navigate(`/services/service/${currentCompanyId}/service-list/${id}/restore`);
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleNavigatteToServiceProviders = (id: number) => {
    run && changeStepStorage('next')
    navigate(`/services-providers/${id}`);
  }

  if (loading || oneServiceDataLoading || isLoadingRefetchServices) return <Spinner/>;

  return (
    <>
      {step === '12' && <JoyrideWrapper run={run} />}
      <div id={'table'} className={step === '12' && run && window?.innerWidth > 930 ? 'col-12 table-responsive scrollbar' : "col-12  overflow-auto"}>
        {!!services?.length ? (
          <table className='table'>
            <thead>
            <tr className='fs-7'>
              <th className='py-3 col-3 ps-4 align-middle'>{t('service')}</th>
              <th className='py-3 col-2 align-middle'>{t('onlineRegistration')}</th>
              <th className='py-3 col-1 align-middle'>{t('cost')}</th>
              <th className='py-3 col-2 align-middle'>{t('category')}</th>
              <th className='py-3 col-1 align-middle'>{t('performers')}</th>
              {moduleWarehouses ?
                <th className='py-3 col-1 align-middle '>
                  {t('techCards')}
                </th>
                : null
              }
              <th className='col-2'></th>
            </tr>
            </thead>
            <tbody>
            {sortedServices.map((service: ServiceProxy, index) => (
              <tr
                key={service?.id}
                className='align-middle '>
                <td
                  className={`align-middle ps-4 ${
                    service?.deleted ? 'text-secondary' : 'text-primary'
                  }`}
                >
                  <ServiceNameWithOptionalLink
                    service={service}
                    currentCompanyId={+currentCompanyId}
                    isEmployeeRole={isEmployeeRole}
                  />
                </td>
                <td className={`align-middle ${service?.deleted ? 'text-secondary' : ''}`}>
                  <Form>
                    <Form.Check
                      type='switch'
                      id='servicesTable-onlineBooking-field'
                      label={onlineRegistrations[service?.id] ? t('allowed') : t('Forbidden')}
                      checked={!!onlineRegistrations[service?.id]}
                      disabled={isEmployeeRole || service?.deleted}
                      onChange={() => toggleOnlineRegistration(service?.id)}
                    />
                  </Form>
                </td>

                <td className={`align-middle ${service?.deleted ? 'text-secondary' : ''}`}>
                  {service?.price} {currencySing}
                </td>
                <td
                  className={`align-middle ${
                    service?.deleted ? 'text-secondary' : 'text-dark'
                  }`}>
                  <span className='d-block'>
                    {service?.serviceCategoryNew?.name}
                  </span>
                  <span
                    className={`d-block fs-7 ${
                      service?.deleted ? 'text-secondary' : 'text-grey'
                    }`}>
                    {
                      service?.serviceCategoryNew?.parentCategory?.name
                        ?
                        `(${service?.serviceCategoryNew?.parentCategory?.name})`
                        :
                        ''
                    }
                  </span>
                </td>
                <td
                  className={`align-middle ${
                    service?.deleted ? 'text-secondary' : 'text-primary'
                  }`}>
                  {/*TODO переделать на относительный путь переход когда будет готова страница для
                   перехода*/}
                  <Link
                    to={`/services-providers/${service?.id}`}
                    className={service?.deleted ? 'text-secondary' : 'text-primary'}
                    style={{textDecoration: 'none'}}>
                    {service?.employeeServiceRelations?.length}
                  </Link>
                </td>
                {moduleWarehouses ?
                  <td
                    className={`align-middle ${
                      service?.deleted ? 'text-secondary' : 'text-primary'
                    }`}>
                    {/*TODO переделать на относительный путь переход когда будет готова страница
                   для перехода*/}
                    <Link
                      to={`https://mepos-2.dev.me-team.org/ua/services/service/${currentCompanyId}/service-list/${service?.id}/technological/maps`}
                      className={service?.deleted ? 'text-secondary' : 'text-primary'}
                      style={{textDecoration: 'none'}}>
                      {service?.technologicalMapServiceRelations?.length}
                    </Link>
                  </td>
                  : null
                }
                <td className={`align-middle text-end px-0 ${service?.deleted && 'd-none'}`}>
                  <div className="d-flex gap-2 justify-content-end align-items-center flex-nowrap">
                    {
                      !isEmployeeRole
                          ?
                          <TooltipCustom placement='bottom' text={t('Edit services')}>
                            <Button
                                className='btn-sm p-2 btn-square'
                                style={{width: '39px'}}
                                variant='outline-secondary' onClick={() => handleEditService(service?.id)}>
                              <i className='bi bi-pencil text-dark'></i>
                            </Button>
                          </TooltipCustom>
                          : null
                    }
                    <TooltipCustom placement='bottom' text={t('Related employees')}>
                      {/*TODO переделать на относительный путь переход когда будет готова страница для
                   перехода*/}
                      <Button
                          variant='outline-secondary'
                          className={`btn-sm p-2  btn-square service-performer service-performer`}
                          style={{width: '39px'}}
                          onClick={() => handleNavigatteToServiceProviders(service?.id) }
                      >
                        <i className='bi bi-people text-dark'></i>
                      </Button>
                    </TooltipCustom>

                    {moduleWarehouses &&
                        <TooltipCustom placement='bottom' text={t('Technological' +
                            ' maps of the service')}>
                          {/*TODO переделать на относительный путь переход когда будет готова страница для
                   перехода*/}
                          <Button
                              className='btn-sm p-2 btn-square'
                              style={{width: '39px'}}
                              variant='outline-secondary'
                              onClick={() => window.location.href = `https://mepos-2.dev.me-team.org/ua/services/service/${currentCompanyId}/service-list/${service?.id}/technological/maps`}
                          >
                            <i className='bi bi-map text-dark'></i>
                          </Button>
                        </TooltipCustom>
                    }
                    {
                      !isEmployeeRole
                          ?
                          <TooltipCustom placement='bottom' text={t('Remove a services')}>
                            <Button
                                className='btn-sm p-2 btn-square'
                                style={{width: '39px'}}
                                variant='outline-secondary'
                                onClick={() => handleShowModalDelete(service?.id)}
                            >
                              <i className='bi bi-trash3 text-dark'></i>
                            </Button>
                          </TooltipCustom>
                          : null
                    }
                  </div>
                </td>
                <td
                  className={`align-middle text-end px-0 h-100 ${
                    service?.deleted ? '' : 'd-none'
                  }`}
                >
                  <div className="d-flex justify-content-end align-items-center flex-nowrap">
                    <span
                        className='badge bg-additional-grey align-middle text-grey d-inline-block px-3  py-2 rounded'>
                      {t('Archive')}
                  </span>
                    {!isEmployeeRole
                        ?
                        <TooltipCustom placement='bottom' text={t('Restore')}>
                          <Button
                              className='btn-square align-middle ms-2 p-2'
                              variant='outline-secondary'
                              style={{width: '39px'}} onClick={() => handleRecoveryService(service?.id)}>
                            <i className='bi bi-arrow-counterclockwise text-dark'></i>
                          </Button>
                        </TooltipCustom>
                        : null
                    }
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        ) :
          <RenderEmptyList
            isFilterNotEmpty={isFilterNotEmpty}
            emptyFilterTitle='No data found for the given values'
            emptyListTitle='servicesNotYetCreated'
            onClickButton={() => navigate(`/services/service/${currentCompanyId}/new`)}
            buttonName={
              <span>
                <i className='bi bi-plus-lg fw-bold me-1 w-100'></i>
                {t('addService')}
              </span>
            }
          />
        }
      </div>
      {
        !!services?.length && (
          <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
            <Pagination
              t={t}
              pages={Number(pageCount)}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              firstItemIndex={firstItemIndex}
              lastItemIndex={lastItemIndex}
              totalItems={totalItems}
            />
          </div>
        )
      }
      <ModalDelete
        show={showDeleteConfirmation}
        handleClose={handleConfirmationClose}
        title={t('Deleting a service')}
        body={t('Are you sure you want to delete the selected service?')}
        buttons={[
          {
            text: t('Cancel'),
            onClick: handleConfirmationClose,
            variant: 'btn btn-outline-violet border-primary text-primary fw-normal',
            className: 'd-flex justify-content-center align-items-center w-100',
          },
          {
            text: t('delete'),
            onClick: handleDelete,
            variant: 'primary',
            className: 'w-100',
          }
        ]}
      />
      <ModalDelete
        show={showCannotDelete}
        handleClose={handleCannotDeleteClose}
        title={t('It is not possible to delete the service')}
        variantTitle='text-danger'
        body={t(
          'Deletion is not possible. The service has related employees. Clear the executors to complete the removal of the service.'
        )}
        buttons={[
          {
            text: t('servicesPage.deleteModal.okBtn'),
            onClick: handleCannotDeleteClose,
            variant: 'danger',
            className: 'w-100',
          }
        ]}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
      { showToast &&  <ToastComponent show={showToast} setShow={setShowToast}  text={toastText}/>}
    </>
  )
};

export default TableServices;
