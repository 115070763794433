import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ProvideServicesStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    return (
        <div>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={11}>
                            <p className='mb-0 fs-7'><span
                                className="fs-normal fw-bold fs-7">{t('Click \"Add service\"')}</span> {t('to open the form for creating new services')}</p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    )
};

export default ProvideServicesStep;
