import React from 'react';
import {Badge} from 'react-bootstrap';

interface StatusBadgeProps{
  text:string,
  variant:string
}

const StatusBadge:React.FC<StatusBadgeProps> = ({text,variant}) => {

  let bg, colorText;

  switch (variant) {
    case 'ACTIVE':
    case 'SUCCESS':
      bg = 'additional-green';
      colorText = 'text-green';
      break;
    case 'CANCELED':
      bg = 'additional-grey';
      colorText = 'text-grey';
      break;
    case 'FAILED':
    case 'EXPIRED':
      bg = 'bg-danger-light';
      colorText = 'text-danger';
      break;
    case 'PAUSED':
    case 'PENDING':
      bg = 'bg-additional-orange';
      colorText = 'text-orange';
      break;
  }

  return (
    <Badge
      bg={bg}
      className={`${colorText} py-2 px-3 rounded-1 fs-7 w-100`}
      style={{height:'31px'}}>
      {text}
    </Badge>

  );
};

export default StatusBadge;
