import React, {useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import {ServicePickCategoryInterfaceInner, ServicePickSubcategoryInner} from "../../../interfaces/interfaces";
import {ServiceItem} from "./ServiceItem/ServiceItem";

type ServiceDropdownProps = {
    category: ServicePickCategoryInterfaceInner
    companyCurrencySign: string
    onServicePick: (categoryId: number, serviceId: number, isPicked: boolean) => void
    employeeId : number
}

export const ServiceDropdown: React.FC<ServiceDropdownProps> = (props) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsDropdownOpen(props.category.isOpen);
    }, [props.category]);

    return (
        <>
            <Button
                className={`text-dark ${props.category.isOpen || props.category.isServicePicked ? 'bg-additional-grey' : 'bg-white'} hover-bg-additional-grey fs-normal fw-normal mt-2 border-0 w-100 d-flex align-items-center`}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                aria-controls={`control-category-id-${props.category.id}`}
                aria-expanded={isDropdownOpen}
            >
                <i className={`bi bi-chevron-right me-1 fs-7 transition d-flex ${isDropdownOpen ? 'rotate-icon-90' : ''}`}></i>
                <span className={'ps-1'}>{props.category.name}</span>
            </Button>
            <Collapse in={isDropdownOpen}>
                <div id={`control-category-id-${props.category.id}`} className={'ps-4'}>
                    {props.category.servicePickerList.map((service: ServicePickSubcategoryInner) => (
                        !service.isHidden && (
                            <ServiceItem
                                key={`service-kay-${service.id}`}
                                service={service}
                                onServicePick={props.onServicePick}
                                categoryId={props.category.id}
                                companyCurrencySign={props.companyCurrencySign}
                                employeeId={props.employeeId}
                                categoryName={props.category.name}
                            />
                        )
                    ))}
                </div>
            </Collapse>
        </>
    )
}