import {TFunction} from 'i18next';
import * as Yup from 'yup';
import {formatTimeZone} from '@me-team/host/src/utils/utils';
import {PhoneNumberUtil} from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

interface Option {
  value: any;
  label: string;
}

interface Country {
  id: number;
  name: string;
  timezone?: { id: number; name: string }[];
}

interface City {
  id: number;
  name: string;
}

interface Currency {
  id: number;
  code: string;
  currency: string;
}

interface Language {
  id: number;
  name: string;
}

export const updateCompanyFormValidationSchema = (t: TFunction) => Yup.object().shape({

  name: Yup.string().required(t('validation.required')),
  country: Yup.string().required(t('validation.required')),
  city: Yup.number()
    .nullable()
    .test(
      'city-required-if-no-otherCity',
      t('validation.required'),
      function (value) {
        let otherCity = this.resolve(Yup.ref('otherCity'));
        return otherCity ? true : value !== null && value !== undefined;
      }
    ),
  otherCity: Yup.string()
    .nullable()
    .test(
      'otherCity-required-if-no-city',
      t('validation.required'),
      function (value) {
        let city = this.resolve(Yup.ref('city'));
        return city !== -1 ? true : value !== null && value !== undefined && value !== '';
      }
    ),
  address: Yup.string().required(t('validation.required')),
  phone: Yup.string()
    .required(t('validation.required'))
    .test('is-valid-phone',
      t('Phone number is not valid'),
      value => isPhoneValid(value || '')
    ),
  timezone: Yup.number().required(t('validation.required')),
  currency: Yup.number().required(t('validation.required')),
});

export const countryOptionsList = (relationsData: any): Option[] =>
  relationsData?.relations?.countries?.map((country: Country) => ({
    value: country.id,
    label: country.name
  })) || [];

export const cityOptionsList = (cityData: any, t: TFunction): Option[] =>
  (cityData?.relations?.cities?.map((city: City) => ({
    value: city.id,
    label: city.name
  })) || []).concat({
    value: -1,
    label: t('Other...')
  });

export const currencyOptionsList = (relationsData: any): Option[] =>
  relationsData?.relations?.currencies?.map((currency: Currency) => ({
    value: currency.id,
    label: `${currency.code} - ${currency.currency}`
  })) || [];

export const timezoneOptionsList = (relationsData: any): Option[] =>
  relationsData?.relations?.countries?.map((country: Country) => ({
    value: country?.timezone?.[0]?.id,
    label: formatTimeZone(country?.timezone?.[0]?.name)
  })) || [];

export const messageLanguageOptionsList = (relationsData: any): Option[] =>
  relationsData?.relations?.languages?.map((language: Language) => ({
    value: language.id,
    label: language.name
  })) || [];

export const languagesMenuOptionsList = (infoCompany: any): Option[] =>
  infoCompany?.languages?.map((language: Language) => ({
    value: language.id,
    label: language.name
  })) || [];
