import React, {useState} from 'react';
import {Button, Col, Container, Form, Row, Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import StatusBadge from './StatusBadge';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {calculateNextPaymentDate, getSecondWord} from '../constants';
import SubscriptionNameField from './SubscriptionNameField';
import PaymentHistoryTable from './PaymentHistoryTable';
import PaymentHistoryDefaultTable from './PaymentHistoryDefaultTable';
import {Pagination} from '@services/ui-components';
import {ApolloError, useReactiveVar} from '@apollo/client';
import CustomModal from '@services/ui-components/src/Modal/CustomModal';
import {Link} from 'react-router-dom';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import { GeneratePaymentUrlInput, PaidFeatureProxy } from '@me-team/host/main/graphql/types';
import {
  useGenerateManageUrlMutation,
  useGeneratePaymentUrlMutation,
  useGetPaidFeaturesQuery
} from '@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks';

const PaymentHistoryAndManagementTable: React.FC = () => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);

  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState(null);

  const handleOpenSubscribeModal = (subscription: PaidFeatureProxy) => {
    setCurrentSubscription(subscription)
    setIsSubscribeModalOpen(true);
  }
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);


  const handleCloseSubscribeModal = () => {
    setIsSubscribeModalOpen(false)
  }

  const handleSubscribe = () => {
    const input: GeneratePaymentUrlInput = {
      plan: currentSubscription?.paymentOrder?.planId,
      currency: currentSubscription?.currency
    }
    generatePaymentUrl({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        if (data) {
          window.location.href = data.generatePaymentUrl;
        }
        handleCloseSubscribeModal()
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e && setIsCheckboxChecked(e.target.checked);
  };

  const {data: subscriptionsData, loading, error} = useGetPaidFeaturesQuery(
    {
      variables: {
        "page": currentPage,
        "itemsPerPage": itemsPerPage,
      },
      context: {
        errorType: 'global'
      },
    }
  );
  const [expandedRows, setExpandedRows] = useState<{ [key: string]: boolean }>({});

  const userEmail = subscriptionsData?.user?.email

  const handleToggleRow = (id: number) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const subscriptions = subscriptionsData?.user?.company?.paidFeaturesPaginated?.paidFeatures
  const pageCount = parseInt(subscriptionsData?.user?.company?.paidFeaturesPaginated?.pageCount || '0');
  const totalItems = subscriptionsData?.user?.company?.paidFeaturesPaginated?.totalCount || 0;
  const firstItemIndex = (currentPage - 1) * itemsPerPage + 1;
  const lastItemIndex = Math.min(currentPage * itemsPerPage, totalItems);
  const timeZone = subscriptionsData?.user?.company?.timeZone?.name

  const [generatePaymentUrl] = useGeneratePaymentUrlMutation()

  const [generateManageUrl] = useGenerateManageUrlMutation()
  const handleManagementSubscription = (paidFeatureId: number) => {
    generateManageUrl({
      variables: {paidFeatureId: paidFeatureId},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {

        if (data) {
          window.location.href = data?.generateManageUrl;
        }
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });
  }

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  if (loading) return <Spinner/>;

  return (
    <>
      <Container fluid className='px-0 table-responsive scrollbar position-relative'>
        {!!subscriptions?.length ? (
          <Table className='table table-borderless'>
            <thead>
            <tr className='py-3 pe-0 ps-4'>
              <th className='ps-4 col-4 pe-2 align-middle fs-7'>{t('Subscription')}</th>
              <th className=' px-2 col-1 align-middle fs-7'>{t('Status')}</th>
              <th className=' px-2 col-1 align-middle fs-7'>{t('cost')}</th>
              <th className=' px-2 col-1 align-middle fs-7'>{t('Next Payment')}</th>
              <th className=' ps-2 col-5 align-middle'></th>
            </tr>
            </thead>
            <tbody>
            {subscriptions.map((subscription: PaidFeatureProxy) => (
              <React.Fragment key={subscription?.id}>
                <tr key={subscription?.id} className='pe-0 ps-4'>
                  <td className='align-middle ps-4 py-4 pe-2'>
                    <SubscriptionNameField subscriptionName={subscription?.name}
                                           isYearly={subscription?.isYearly}/>
                  </td>
                  <td className='align-middle py-3 px-2'>
                    <StatusBadge text={t(subscription?.status)} variant={subscription?.status}/>
                  </td>
                  <td className='align-middle py-3 px-2 fs-7'>
                    <Row>
                      <Col className='px-2'>
                        {
                          subscription?.isYearly ? (subscription?.price / 12).toFixed(2)
                          :
                          subscription?.price
                        }
                        <span className='ms-1'>{t(subscription?.currency)}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className='px-2'>
                      <span
                        className='p-0 text-grey'>{t('/month')}</span>
                      </Col>
                    </Row>
                  </td>
                  <td className='align-middle py-3 px-2'>
                    {calculateNextPaymentDate(subscription?.isYearly, subscription?.dateOfLastPayment)}
                  </td>
                  <td className='align-middle py-3 ps-2 pe-0'>
                    <div className='d-flex flex-lg-nowrap justify-content-between gap-2'>
                      <div className='d-flex gap-3'>

                        {subscription?.status === 'EXPIRED' ?
                          <Button
                            variant='green'
                            className='px-4 py-2 text-white'
                            onClick={() => handleOpenSubscribeModal(subscription)}
                          >
                            {t('Update Payment')}
                          </Button>
                          :
                          <Button
                            variant='outline-primary'
                            className='px-4 py-2 fw-normal'
                            onClick={() => handleManagementSubscription(subscription?.id)}
                          >
                            {t('Subscription Management')}
                          </Button>
                        }
                        <Button
                          variant='primary'
                          className='px-4 py-2'
                          onClick={() => handleManagementSubscription(subscription?.id)}
                        >
                          {t('Change Card')}
                        </Button>
                      </div>
                      <Button
                        variant='outline-secondary'
                        style={{width: '39px', height: "39px"}}
                        onClick={() => handleToggleRow(subscription?.id)}
                      >
                        <i
                          className={`bi bi-chevron-${expandedRows[subscription?.id] ? 'up' : 'down'} text-dark`}></i>
                      </Button>
                    </div>
                  </td>
                </tr>
                {expandedRows[subscription?.id] && (
                  <tr className='text-center'>
                    <td colSpan={5} className='px-5 py-0'>
                      <div className='px-5'>
                        <PaymentHistoryTable
                          subscription={subscription}
                          userEmail={userEmail}
                          timeZone={timeZone}
                        />
                      </div>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={5} className='p-0'>
                    <hr className="w-100 m-0 border-grey"/>
                  </td>
                </tr>
              </React.Fragment>
            ))}
            </tbody>
          </Table>
        ) : (
          <PaymentHistoryDefaultTable/>
        )}

        <CustomModal
          show={isSubscribeModalOpen}
          handleClose={handleCloseSubscribeModal}
          title={
            <Container>
              <Row>
                <h5 className='m-0 p-0'>{t('Registration of Subscription')}</h5>
              </Row>
              <Row>
                <p className='fs-normal text-grey p-0 m-0'>
                  <span
                    className='me-1'>{t(currentSubscription?.name && getSecondWord(currentSubscription?.name))}</span>
                  ({
                  currentSubscription?.isYearly
                    ?
                    t('Annual Subscription')
                    :
                    t('Monthly Subscription')
                })
                </p>
              </Row>
            </Container>
          }
          variantTitle='fs-5'
          body={
            <Container>
              <Row className='mb-3'>
                <Col className='d-flex text-start p-0'>
                  <span className='fs-6 '>{t('Payment information')}</span>
                </Col>
              </Row>
              <Row className='fs-7 text-grey py-2'>
                <Col xs={8} className='p-0 text-start'>
                  <span
                    className='me-1'>{currentSubscription?.name && t(getSecondWord(currentSubscription?.name))}</span>
                  <span>({
                    currentSubscription?.isYearly ? t('Annual Subscription') : t('Monthly Subscription')
                  })</span>
                </Col>
                <Col xs={4} className='p-0 text-end'>
                <span className='text-dark'>
                  {`${currentSubscription?.price.toFixed(2)} ${currentSubscription?.currency === "UAH" ? "₴" : "€"}`}
                </span>
                </Col>
              </Row>
              <Row>
                <hr className='my-2'/>
              </Row>
              <Row className='fs-7 text-grey py-2 mb-4'>
                <Col xs={8} className='p-0 text-start'>
                  <span className='me-1'>{t('Total')}</span>
                </Col>
                <Col xs={4} className='p-0 text-end'>
                <span className='text-dark fs-6 fw-bold'>
                  {`${currentSubscription?.price.toFixed(2)} ${currentSubscription?.currency === "UAH" ? "₴" : "€"}`}
                </span>
                </Col>
              </Row>
              <Row>
                <Form.Check
                  className='text-start'
                  required
                  label={<Col className='fs-7'>
                    <span className='me-1'>{t('By clicking "To Payment", you accept')}</span>
                    <Link to={'/'}>
                      <span className='me-1'>{t('User Agreement')}</span>
                    </Link>
                    <span className='me-1'>{t('and')}</span>
                    <Link to={'/'}>
                      <span>{t('Privacy Policy')}</span>
                    </Link>
                  </Col>

                  }
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                  onChange={handleCheckboxChange}
                />
              </Row>
            </Container>
          }
          buttons={[
            {
              text: t('Cancel'),
              onClick: handleCloseSubscribeModal,
              variant: 'outline-primary',
              className: 'w-100 fw-normal',
            },
            {
              text: t('To Payment'),
              onClick: handleSubscribe,
              variant: 'primary',
              className: 'w-100 fw-normal',
              disabled: !isCheckboxChecked,
            },
          ]}
        />
      </Container>
        <div className='d-flex justify-content-between align-items-center mt-4 mb-5'>
          <Pagination
            t={t}
            pages={Number(pageCount) || 1}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            firstItemIndex={firstItemIndex}
            lastItemIndex={lastItemIndex || 2}
            totalItems={totalItems || 2}
          />
        </div>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default PaymentHistoryAndManagementTable;
