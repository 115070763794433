import React, {useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import {ErrorModalComponent} from "@me-pos/error-modal";

type ExportBtnPropsType = {
    companyId : number
    exportType : string
    classes?: string
}

export const ExportBtn : React.FC<ExportBtnPropsType> = (props) => {
    const { t : translate } = useTranslation();
    const [currentStatusError, setCurrentStatusError] = useState<number>(null);
    const startGenerationFileHandler = async () => {
        try {
            const response = await fetch(`
            ${process.env.REACT_APP_POS_URL}${i18next.language === 'uk' ? '/ua' : ''}/ajax/export/company/${props.companyId}/${props.exportType}/start-csv-generation`, {
                method: 'POST',
                headers: {'Content-type': 'application/json'},
                credentials: "include",
            });

            if (!response.ok) {
                setCurrentStatusError(response.status);
            }
        } catch (error) {
            setCurrentStatusError(500);
        }
    }

    return (
        <>
            <Button variant={'outline-violet'}
                    className='border-primary text-primary fw-normal d-flex justify-content-center  align-items-center w-100 mb-3 mb-xl-0'
                    onClick={startGenerationFileHandler}>
                <i className='bi bi-box-arrow-up'></i>
                <span className={`${props.classes} ms-1`}>{translate('export')}</span>
            </Button>
            <ErrorModalComponent i18n={i18next} onClose={() => {}} isOpen={!!currentStatusError} currentError={currentStatusError} />
        </>
    )
}