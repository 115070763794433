import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import {
    EmployeeServiceRelationProxy,
    ServiceProxy
} from '@me-team/host/graphql/types';
import {useReactiveVar} from "@apollo/client";
import {currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";

type PriceComponentProps = {
    service: ServiceProxy;
    updateEmployeeServices: (updatedService: EmployeeServiceRelationProxy) => void;
    currencySign: string;
    index?: number
};

const PriceComponent: React.FC<PriceComponentProps> = ({ service, updateEmployeeServices, currencySign, index }) => {
    const userRole = useReactiveVar(currentUserRoleVar);
    const [price, setPrice] = useState<string | number>(service.employeeServiceRelations[0]?.price || '');

    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const updatePrice = (i: ServiceProxy, target: string) => {
        if (timeoutIdRef.current) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            const updatedPrice = parseFloat(target);
            if (!isNaN(updatedPrice)) {
                updateEmployeeServices({ ...i.employeeServiceRelations[0], price: updatedPrice });
            }
        }, 1300);
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputValue = e.target.value;
        inputValue = inputValue.replace(',', '.');
        const regex = /^\d{0,6}(\.\d{0,2})?$/;
        if (regex.test(inputValue) || inputValue === '') {
            let numericValue = parseFloat(inputValue);
            if (inputValue === '' || numericValue < 0.01) {
                inputValue = '0.01';
                numericValue = 0.01;
            }
            if (numericValue > 999999.99) {
                inputValue = '999999.99';
                numericValue = 999999.99;
            }
            setPrice(inputValue);
            if (numericValue >= 0.01 && numericValue <= 999999.99) {
                updatePrice(service, inputValue);
            }
        }
    };

    const getMaxWidth = (value: string) => {
        return value.length > 6 ? '100px' : '90px';
    };

    return (
        <>
            <div className="position-relative" style={{ width: getMaxWidth(price.toString()) }}>
                <Form.Group className="mb-3" >
                    <Form.Control
                        id={`priceComponent-${index}-field`}
                        size="sm"
                        onChange={handlePriceChange}
                        placeholder=""
                        value={price}
                        disabled={userRole === 5}
                        contenteditable="true"
                    />
                </Form.Group>
                <div className='position-absolute top-50 end-0 translate-middle-y '>
                    <i style={{ paddingRight: '12px' }}>
                        {currencySign}
                    </i>
                </div>
            </div>
        </>
    );
};

export default PriceComponent;