import React, {useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

type SearchComponentProps = {
    searchHandling: (currentValue: string) => void
}

export const SearchComponent: React.FC<SearchComponentProps> = (props) => {

    const [searchValue, setSearchValue] = useState<string>('')
    const {t: translate} = useTranslation();
    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value)
    }

    const applySearchHandler = () => {
        props.searchHandling(searchValue.toLowerCase())
    }

    const onClearHandler = () => {
        setSearchValue('')
        props.searchHandling('')
    }

    const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            applySearchHandler();
        }
    };

    return (
        <Row className='gx-3'>
            <Col lg={8} xxl={9}>
                <div className="position-relative mb-3 mb-lg-0">
                    <input value={searchValue} type="text" className="form-control pe-5 text-truncate"
                           placeholder={translate("searchByServiceName")}
                           onChange={onSearch}
                           onKeyDown={handleSearchKeyDown}
                    />
                    <i className="bi bi-search position-absolute top-50 end-0 translate-middle-y text-dark me-6"></i>
                </div>
            </Col>
            <Col lg={4} xxl={3}>
                <Row className='gx-3 h-100'>
                    <Col xs={6}>
                        <button className="btn btn-outline-dark border-grey w-100 h-100 fw-normal"
                                onClick={onClearHandler}
                        >{translate("clear")}</button>
                    </Col>
                    <Col xs={6}>
                        <Button variant={'primary'} className="w-100 h-100 fw-normal"
                                onClick={applySearchHandler}
                        >{translate("apply")}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}