import {CSSObjectWithLabel, StylesConfig} from 'react-select';

export const stylesSelect: StylesConfig = {
  control: (provided: CSSObjectWithLabel, {isFocused}: {
    isFocused: boolean,
  }) => ({
    ...provided,
    borderColor: isFocused ? '#ACA1DF' : '#DEDEDE',
    borderRadius: '4px',
    height: '32px',
    minHeight: '100%',
    width:'62px',
    boxShadow: isFocused && '0 0 0 0.2rem rgba(111, 66, 193, 0.25)',
    '&:hover': {
      borderColor: '#ACA1DF',
    },
  }),

  option: (provided: CSSObjectWithLabel, {isSelected}: { isSelected: boolean }) => ({
    ...provided,
    borderRadius: "4px",
    marginBottom: "8px",
    padding: "4px 12px",
    cursor: "pointer",
    backgroundColor: isSelected ? '#5842BE' : '#FFFFFF',
    color: isSelected ? '#FFFFFF' : '#1F3354',
    '&:hover': {
      backgroundColor: '#EEECF9',
      color: '#1F3354',
    },
  }),

  placeholder: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: "14px",
    color: "#9D9D9D",
  }),

  input: (provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: "14px",
    color: "#1F3354",
  }),

  dropdownIndicator: (provided: CSSObjectWithLabel, {selectProps}: any) => ({
    ...provided,
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    transition: 'transform 0.2s ease',
    padding: '4px'
  }),

  menu: (provided: CSSObjectWithLabel) => ({
    ...provided,
    marginTop: "4px",
  }),

  menuList: (provided: CSSObjectWithLabel) => ({
    ...provided,
    padding: '8px 12px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  singleValue:(provided: CSSObjectWithLabel) => ({
    ...provided,
    alignItems: 'center',
    paddingTop: '2px',
    paddingRight: '0'
  }),
  valueContainer:(provided: CSSObjectWithLabel) => ({
    ...provided,
    fontSize: "14px",
    paddingRight: '0'
  }),
}