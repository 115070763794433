import { PixelCrop } from 'react-image-crop';

const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.imageSmoothingQuality = 'high';

  // Scale the crop rectangle to the natural image size.
  const pixelCrop = {
    x: crop.x * scaleX,
    y: crop.y * scaleY,
    width: crop.width * scaleX,
    height: crop.height * scaleY,
  };

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // const scaleX = image.naturalWidth / image.width;
  // const scaleY = image.naturalHeight / image.height;

  // // Set the canvas size sufficient to center the image  canvas.width = Math.max(image.naturalWidth, crop.width * scaleX);
  // canvas.height = Math.max(image.naturalHeight, crop.height * scaleY);

  // // Center the image
  // const offsetX = (canvas.width - crop.width * scaleX) / 2;
  // const offsetY = (canvas.height - crop.height * scaleY) / 2;

  // ctx.clearRect(0, 0, canvas.width, canvas.height);
  // ctx.imageSmoothingQuality = 'high';

  // // Position and draw the image
  // ctx.drawImage(
  //   image,
  //   crop.x * scaleX,
  //   crop.y * scaleY,
  //   crop.width * scaleX,
  //   crop.height * scaleY,
  //   offsetX,
  //   offsetY,
  //   crop.width * scaleX,
  //   crop.height * scaleY
  // );

  // ctx.restore();
}
