import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

const InfoFooter: React.FC = () => {
  const {t,i18n} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;
  const languageUK = i18n?.language === 'uk' ? 'ua/' : ''

  return (
    <Row
      className='text-grey fs-7 mt-4 mx-0 px-1 py-2 border rounded border-additional-grey'
    >
      <Col>
        <i className='bi bi-info-circle me-2'></i>
        <span>{t('Get acquainted with')}</span>
        <a href={`${basePath}/${languageUK}terms-and-conditions`} target="_blank">
          <span className='text-primary mx-1'>
            {t('Terms and Conditions')}
          </span>
        </a>
        <span>{t('payment at ME-Pos.')}</span>
      </Col>
    </Row>
  );
};

export default InfoFooter;