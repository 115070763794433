import { useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { currentUserRoleVar} from '@me-team/host/src/apollo/globalVar/state';

const useNavigateOnRole = (role?: number, targetPath?: string) => {
    const userRole = useReactiveVar(currentUserRoleVar);
    const navigate = useNavigate();

    useEffect(() => {
        if (userRole ===  5) {
            navigate(targetPath || '/');
        }
    }, [userRole, role, navigate, targetPath]);
};

export default useNavigateOnRole;