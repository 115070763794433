import React from 'react';
import {Col, Row, Table} from 'react-bootstrap';
import StatusBadge from './StatusBadge';
import {useTranslation} from 'react-i18next';

const PaymentHistoryDefaultTable: React.FC = () => {
  const {t} = useTranslation();

  return (
    <Table className='table table-borderless'>
      <thead>
      <tr className='py-3 pe-0 ps-4'>
        <th className='ps-4 col-4 pe-2 align-middle fs-7'>{t('Subscription')}</th>
        <th className=' px-2 col-1 align-middle fs-7'>{t('Status')}</th>
        <th className=' px-2 col-1 align-middle fs-7'>{t('cost')}</th>
        <th className=' px-2 col-1 align-middle fs-7'>{t('Next Payment')}</th>
        <th className=' ps-2 col-5 align-middle'></th>
      </tr>
      </thead>
      <tbody>
      <tr className='pe-0 ps-4 border-bottom'>
        <td className='align-middle ps-4 py-4 pe-2'>
          <p className='fs-normal mb-0'>
            <span className='me-1'>{t('STARTER')}</span>
          </p>
          <span className='fs-7 text-grey'>
        {t('Available by default upon registration')}
      </span>
        </td>
        <td className='align-middle py-3 px-2'>
          <StatusBadge text={t('ACTIVE')} variant={'ACTIVE'}/>
        </td>
        <td className='align-middle py-3 px-2 fs-7'>
          <Row>
            <Col className='px-2'>
              0
            </Col>
          </Row>
        </td>
        <td className='align-middle py-3 px-2'>
          -
        </td>
      </tr>
      <tr className='pe-0 ps-4 border-bottom'>
        <td className='align-middle ps-4 py-4 pe-2'>
          <p className='fs-normal mb-0'>
            <span className='me-1'>{t('Informing Customers (Email)')}</span>
          </p>
          <span className='fs-7 text-grey'>
        {t('Informing customers via e-mails')}
      </span>
        </td>
        <td className='align-middle py-3 px-2'>
          <StatusBadge text={t('ACTIVE')} variant={'ACTIVE'}/>
        </td>
        <td className='align-middle py-3 px-2 fs-7'>
          <Row>
            <Col className='px-2'>
              0
            </Col>
          </Row>
        </td>
        <td className='align-middle py-3 px-2'>
          -
        </td>
      </tr>
      </tbody>
    </Table>
  );
};

export default PaymentHistoryDefaultTable;
