import { Modal, Button, Row, Col, } from 'react-bootstrap';
import { EmployeeProxy } from '@me-pos/service-modal/dist/src/__generated__/graphql';
import {useTranslation} from "react-i18next";
import { EmployeeServiceRelationProxy, SalaryType } from '@me-team/host/main/graphql/types';
import React from 'react';

interface CustomModalProps {
    show: boolean;
    handleToggleModal: () => void;
    selectedEmployee: EmployeeProxy;
    updateFunc: (values: EmployeeServiceRelationProxy) => Promise<void>;
    text?: string
}

const ServicesModalConfirmation: React.FC<CustomModalProps> = ({ show, handleToggleModal, selectedEmployee, updateFunc, text }) => {
    const { t } = useTranslation();
    const updateOnlineBooking = () => {
        updateFunc({
            ...selectedEmployee.employeeServiceRelations[0],
            online: !selectedEmployee.employeeServiceRelations[0].online,
            salaryType: selectedEmployee.employeeServiceRelations[0].salaryType
        })
        handleToggleModal()
    }
    return (
        <Modal
            show={show}
            onHide={handleToggleModal}
            centered>
            <Modal.Header
                closeButton
                className='border-0'>
                <Modal.Title className={`h5 fw-normal`}>
                    {t('Online booking')}

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-center'>
                {text || `${selectedEmployee?.name} ${t('is not available for Online Registration form. Should we make')} ${selectedEmployee?.name} ${t('available in the Online Registration form for your clients?')}`}
            </Modal.Body>
            <Modal.Footer className='border-0 p-2'>
                <Row className='gx-3 w-100'>
                    <Col className='p-0'>
                        <Button
                            variant="outline-primary"
                            className='w-100 me-1'
                            onClick={() => handleToggleModal()}>
                            {t('Cancel')}

                        </Button>
                    </Col>
                    <Col className='p-0'>
                        <Button
                            variant="primary"
                            className='w-100 text-truncate ms-1'
                            onClick={() => updateOnlineBooking()}>
                            {t('Yes')}

                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal >
    );
};

export default ServicesModalConfirmation;
