import React, {ReactElement} from "react";
import {PauseSpinner} from "../Spinners/PauseSpinner";
import {LoadingSpinner} from "../Spinners/LoadingSpinner";
import {useTranslation} from "react-i18next";
import {CombinedJobInterface} from "../../../interfaces/interfaces";
import {DateTime} from "luxon";
import i18next from "i18next";
import {EntityType, JobTypeCase, Status} from "../../../utils/enums";


type JobItemProps = {
    job: CombinedJobInterface;
};

export const JobItem : React.FC<JobItemProps> = (props) => {
    const { t: translate } = useTranslation();

    const VALIDATION_FILE_URL : string = `/api/import/csv/company/${props.job.company.id}/get-validation-file/${props.job.uuid}`

    const jobServiceTranslation = (service: string) => {
        switch (service) {
            case EntityType.CLIENTS: return translate('clients');
            case EntityType.EMPLOYEES: return translate('Employees');
            case EntityType.SERVICES: return translate('Services');
            case EntityType.SERVICE_CATEGORIES: return translate('serviceCategory');
            default: return '';
        }
    };

    const statusIcon = (status: number) : ReactElement | null => {
        switch (status) {
            case Status.CREATED: return <PauseSpinner />;
            case Status.IN_PROGRESS: return <LoadingSpinner />;
            case Status.SUCCESS: return <i className="bi bi-check-circle text-green fs-6"></i>;
            case Status.FAIL:
            case Status.VALIDATION_FAILED: return <i className="bi bi-exclamation-circle fs-6 text-danger"></i>;
            default: return null;
        }
    };

    const jobTypeTranslation = (type : string) : string => {
        switch (type) {
            case JobTypeCase.IMPORT: return translate('import');
            case JobTypeCase.EXPORT: return translate('export');
            default: return '';
        }
    }

    const convertDate = (currentDate : string) : string => {
        const dateTime = DateTime.fromFormat(currentDate, 'yyyy-MM-dd HH:mm:ss');
        return dateTime.toFormat('dd.MM.yy HH:mm');
    }

    const errorsContent = (currentError : number) : ReactElement | null => {
        switch (currentError) {
            case Status.FAIL: return <p className={'mb-0 text-danger mt-1'}>{translate('anErrorOccurredPleaseTryAgain')}</p>
            case Status.VALIDATION_FAILED: return <p className={'mb-0 text-danger mt-1'}>
                {translate('dataWasNotSavedTheFileContainsInvalidData')}
                <a href={`${process.env.REACT_APP_POS_URL}${VALIDATION_FILE_URL}`} className={'text-danger'} download> {translate('clickHereToDownloadTheValidationFile')}</a>
            </p>;
            default: return null;
        }
    }

    return (
        <div className="p-3 pb-0 text-dark">
            <div className="p-3 border rounded">
                <div className="d-flex align-items-center position-relative">
                    <i className="bi bi-box-arrow-in-down me-2 d-flex mb-1"></i>
                    <p className="mb-0">
                        {jobTypeTranslation(props.job.jobType)} - {jobServiceTranslation(props.job.type)}
                    </p>
                    <span className="text-grey ms-2">{convertDate(props.job.createdAt)}</span>
                    <div className="position-absolute top-0 end-0">
                        {statusIcon(props.job.status)}
                    </div>
                </div>
                {(props.job.jobType === `${JobTypeCase.EXPORT}` && props.job.status === Status.SUCCESS) &&
                    <div className={'d-flex justify-content-end mt-2'}>
                        <a href={`${process.env.REACT_APP_POS_URL}${i18next.language === 'uk' ? '/ua' : ''}/ajax/${props.job.jobType.toLowerCase()}/company/${props.job.company.id}/${props.job.type}/download-csv-file`}
                           download>{translate('Download')}</a>
                    </div>
                }
                {props.job.jobType === `${JobTypeCase.IMPORT}` && <p className="mb-0 ms-3 ps-2">{props.job.filename}</p>}
                {errorsContent(props.job.status)}
            </div>
        </div>
    );
}