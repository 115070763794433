import {ServiceProxy} from '@me-team/host/main/graphql/types';

export interface CalendarFilterI {
  statusId?: number,
  positionId?: number,
  employeeId?: number,
  serviceId?: number
}

export interface Option {
  value: number;
  label: string;
}

export const renderingOptionServices = (service: ServiceProxy) => {
  return (
    `${service?.name} - ${service?.serviceCategoryNew?.name}
      ${
      service.serviceCategoryNew?.parentCategory?.name
        ?
        `(${service.serviceCategoryNew?.parentCategory?.name})`
        :
        ''
    }`
  )
}