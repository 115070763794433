import React, {useCallback, useEffect, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {Col, Container, Row} from 'react-bootstrap';
import {FileRejection, useDropzone} from 'react-dropzone';
import Papa, {ParseResult} from 'papaparse';
import {ErrorImportFile} from './ErrorImportFile/ErrorImportFile';
import {importErrorInterface, requirementsInfoType} from '../../interfaces/interfaces';
import {useTranslation} from "react-i18next";
import Spinner from "@services/ui-components/src/Spinner/Spinner"
import {SimpleModal} from "@services/ui-components/src/SimpleModal/SimpleModal";
import Collapse from 'react-bootstrap/Collapse';
import i18next from "i18next";
import ErrorImportFilesHandling from "../../services/importFiles/ErrorImportFilesHandling";
import {ErrorModalComponent} from "@me-pos/error-modal";

interface CsvRow {
    [key: string]: string;
}

type ImportFilesProps = {
    currentRequirementsInfo: requirementsInfoType
    isOpen : boolean
    onClose: () => void
}

export const ImportFiles: React.FC<ImportFilesProps> = (props) => {
    const [requirementsInfo, setRequirementsInfo] = useState<requirementsInfoType>(props.currentRequirementsInfo)
    const [showImportModal, setShowImportModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isOpenRequirementsToFile, setIsOpenRequirementsToFile] = useState<boolean>(false);
    const [importFileError, setImportFileError] = useState<importErrorInterface>(null);
    const [showPreview, setShowPreview] = useState<boolean>(false);
    const [csvData, setCsvData] = useState<CsvRow[] | null>(null);
    const [fileFields, setFileFields] = useState<string[]>()
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [currentStatusError, setCurrentStatusError] = useState<number>(null);
    const [fileToImport, setFileToImport] = useState<File>(null);
    const {t: translate} = useTranslation();

    useEffect(() => {
        setRequirementsInfo(props.currentRequirementsInfo)
    }, [props.currentRequirementsInfo]);

    useEffect(() => {
        setShowImportModal(props.isOpen);
        setCurrentStatusError(null);
        setShowCancelModal(false);
        setIsLoading(false);
    }, [props.isOpen]);

    const onShowErrorModal = (currentStatus: number) => {
        setShowImportModal(false);
        setCurrentStatusError(currentStatus);
    }

    const handleCloseImportModal = () => {
        setShowImportModal(false);
        setImportFileError(null);
        setIsLoading(false);
        setIsOpenRequirementsToFile(false);
        props.onClose();
    };

    const onOpenDropdownHandler = () => {
        setIsOpenRequirementsToFile(!isOpenRequirementsToFile);
    };

    const onCloseCancelModalHandler = () => {
        setShowCancelModal(false);
        setShowImportModal(true);
    }

    const onOpenCancelModalHandler = () => {
        setShowCancelModal(true);
        setShowImportModal(false);
    }

    const clearAllStates = () => {
        setShowImportModal(false);
        setShowCancelModal(false);
        setIsOpenRequirementsToFile(false);
        setFileFields(null);
        setCsvData(null);
        setShowPreview(false);
        setImportFileError(null)
    }

    const onCancelImportHandler = () => {
        clearAllStates();
        props.onClose();
    }

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        for (const file of acceptedFiles) {
            setIsLoading(true);
            const reader = new FileReader();

            try {
                const result = await readFileAsArrayBuffer(reader, file);
                setImportFileError({hasError: false, errorContent: null});

                const decoder = new TextDecoder('utf-8');
                const text = decoder.decode(new Uint8Array(result));

                Papa.parse(text, {
                    header: true,
                    complete: async (parsedResult: ParseResult<CsvRow>) => {
                        const data = parsedResult.data;
                        const fields = parsedResult.meta.fields;

                        if (data.length === 0 || (data.length === 1 && Object.values(data[0]).every(value => value === ""))) {
                            setIsLoading(false);
                            setImportFileError({
                                hasError: true,
                                errorContent: <p className="mb-0">
                                    {translate('No data was saved. An empty file has been uploaded. Select the correct file to import data')}
                                </p>
                            });
                            return;
                        }

                        if (data.length === 0 || data.length > 1001 || (data.length === 1 && Object.values(data[0]).every(value => value === ""))) {
                            setIsLoading(false);
                            setImportFileError({
                                hasError: true,
                                errorContent: <p className="mb-0">
                                    {translate('File cannot be larger then 1000 rows')}
                                </p>
                            });
                            return;
                        }

                        setFileToImport(file);
                        setFileFields(fields);

                        const count: number = parsedResult.data.length > 10 ? 10 : parsedResult.data.length - 1;
                        const first10Rows = data.slice(0, count);

                        setCsvData(first10Rows);

                        await validationHeader(fields);
                    },
                    error: () => {
                        setIsLoading(false);
                        setImportFileError({
                            hasError: true,
                            errorContent: <p className="mb-0">
                                {translate('Invalid file format selected. Select a .csv file')}
                            </p>
                        });
                    }
                });
            } catch (error) {
                setIsLoading(false);
                setImportFileError({
                    hasError: true,
                    errorContent: <p className="mb-0">file reading has failed</p>
                });
            }
        }
    }, []);

    const readFileAsArrayBuffer = (reader: FileReader, file: File): Promise<ArrayBuffer> => {
        return new Promise<ArrayBuffer>((resolve, reject) => {
            reader.onabort = () => reject(new Error('file reading has failed'));
            reader.onerror = () => reject(new Error('file reading has failed'));
            reader.onload = () => resolve(reader.result as ArrayBuffer);
            reader.readAsArrayBuffer(file);
        });
    };
    const validationHeader = async (fields: string[]) => {
        try {
            const response = await fetch(requirementsInfo.validationHeaderUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-LOCALE': i18next.language
                },
                credentials: "include",
                body: JSON.stringify(fields)
            });
            const responseData = await response.json();
            if (response.ok) {
                setIsLoading(false);
                if (!responseData.error) {
                    setShowPreview(true);
                } else {
                    setImportFileError(
                        {
                            hasError: true,
                            errorContent:
                                <p className={'mb-0'}>{ErrorImportFilesHandling.validationHeaderErrorHandling(responseData.code, responseData.columns, translate)}</p>
                        }
                    )
                }
            } else {
                onShowErrorModal(response.status)
            }

        } catch (error) {
            console.log('Error:', error);
        }
    };


    const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
        fileRejections.forEach(rejection => {
            const {file, errors} = rejection;

            errors.forEach(error => {
                if (error.code === 'file-too-large') {
                    setImportFileError({
                        hasError: true,
                        errorContent: <p className="mb-0">
                            {translate('The selected file exceeds the maximum allowed size of 30 MB. Please choose a smaller file')}.
                        </p>
                    });
                } else {
                    setImportFileError({
                        hasError: true,
                        errorContent: <p className="mb-0">
                            {translate('Invalid file format or another error occurred')}.
                        </p>
                    });
                }
            });
        });
    }, []);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/csv': ['.csv']
        },
        maxSize: 30000000,
        maxFiles: 1,
        onDrop: onDrop,
        onDropRejected: onDropRejected
    });

    const uploadFileHandler = async () => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('import', fileToImport);

        try {
            const response = await fetch(requirementsInfo.uploadFileUrl, {
                method: 'POST',
                headers : {
                    'X-LOCALE': i18next.language
                },
                credentials: "include",
                body: formData
            });

            const responseData = await response.json();

            if (response.ok) {
                if (!responseData.error) {
                    clearAllStates();
                    props.onClose()
                }
            } else {
                clearAllStates();
                onShowErrorModal(response.status);
            }

        } catch (error) {
            clearAllStates();
            onShowErrorModal(500);
        }
    };


    return (
        <>
            <Modal show={showImportModal} size={showPreview ? 'lg' : undefined} centered
                   onHide={showPreview ? onOpenCancelModalHandler : handleCloseImportModal}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title className="h5 text-dark">{translate('File import')}</Modal.Title>
                </Modal.Header>
                <div className={'position-relative'}>
                    {isLoading &&
                        <div
                            className={'bg-white h-100 w-100 rounded position-absolute top-0 end-0 z-3 d-flex justify-content-center align-items-center'}>
                            <Spinner heightContent={true}/>
                        </div>
                    }
                    <Modal.Body>
                        <div className="px-2 mx-1">
                            {showPreview && csvData &&
                                <>
                                    <p className="mb-3">
                                        {translate('View the file structure')} <span
                                        className="text-secondary">({translate('part of the data is displayed')})</span>
                                    </p>
                                    <div className="scrollbar  table-responsive overflow-x-auto">
                                        <table className="table">
                                            <thead className="table-header-background">
                                            <tr>
                                                {fileFields.slice(0, requirementsInfo.requirements.length).map((requirement: string, index: number) => (
                                                    <th key={index} className={"pe-4"}>
                                                        {requirement}
                                                    </th>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {csvData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Object.keys(row).slice(0, requirementsInfo.requirements.length).map((key, colIndex) => (
                                                        <td key={colIndex} className={"pe-4 align-middle text-center"}>
                                                            {row[key]}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }
                            {!showPreview &&
                                <>
                                    {(importFileError && importFileError.hasError) && (
                                        <ErrorImportFile
                                            errorContent={importFileError.errorContent}
                                        />
                                    )}
                                    <Button
                                        className="mb-0 text-dark bg-transparent text-start p-0 border-0 fw-normal fs-normal w-100 d-flex justify-content-between align-items-center"
                                        onClick={onOpenDropdownHandler}
                                        aria-controls="requirementsToFile"
                                        aria-expanded={isOpenRequirementsToFile}
                                    >
                                        <div>
                                            <i className="bi bi-card-checklist me-2 fs-normal"></i> {translate('File import requirements')}
                                        </div>
                                        <i className={`bi bi-chevron-down fs-6 ${isOpenRequirementsToFile ? 'rotated' : ''}`}></i>
                                    </Button>
                                    <Collapse in={isOpenRequirementsToFile}>
                                        <div id={"requirementsToFile"}>
                                            <p className='mt-3 mb-2'>
                                                <span className="fw-bold">{translate('Columns for import')} </span>
                                                <span
                                                    className="text-grey fs-7">({translate('mandatory are marked with an asterisk')} - <span
                                                    className="fs-7 text-danger">*</span>)</span>
                                            </p>
                                            <ul className="list-unstyled fs-7 mb-0">
                                                {requirementsInfo.requirements.map((requirement, index) => (
                                                    <li key={index}>
                                        <span className="fw-bold">
                                            {requirement.title} {requirement.isRequirement &&
                                            <span className="text-danger">*</span>}
                                        </span> –
                                                        <span> {requirement.description}</span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Collapse>
                                    <div {...getRootProps()}
                                         className="dropzone cursor-pointer dropzone-style mt-4 py-5 px-2 d-flex align-items-center justify-content-center flex-column">
                                        <input {...getInputProps()} />
                                        <i className="bi bi-box-arrow-in-down fs-1 text-additional-violet d-flex mb-3"></i>
                                        <p className="mb-0 w-50 text-center">
                                            {translate('Transfer it')} <span
                                            className="fw-bold">.csv</span> {translate("file here or")} <a
                                            className="text-primary">{translate('selectAFileFrom your computer')}</a>
                                        </p>
                                        <p className="mb-0 text-grey fs-7 mt-2">
                                            {translate("Maximum size up to")} <span className="fw-bold">30 Mb</span>
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="border-0 px-0">
                        <Container>
                            <Row className={`${showPreview ? 'justify-content-end' : 'justify-content-center'}`}>
                                {!showPreview &&
                                    <Col lg={5}>
                                        <a
                                            href={requirementsInfo.templateUrl}
                                            download
                                            className="btn btn-outline-primary w-100 fw-normal">
                                            {translate("Download the template")}
                                        </a>
                                    </Col>
                                }
                                {showPreview &&
                                    <>
                                        <Col lg={3}>
                                            <Button variant="outline-primary" className="w-100 fw-normal"
                                                    onClick={onOpenCancelModalHandler}>
                                                {translate("cancel")}
                                            </Button>
                                        </Col>
                                        <Col lg={3}>
                                            <Button variant="primary" className="w-100 fw-normal"
                                                    onClick={uploadFileHandler}>
                                                {translate("Upload")}
                                            </Button>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </Container>
                    </Modal.Footer>
                </div>
            </Modal>

            <SimpleModal isOpen={showCancelModal} onClose={onCloseCancelModalHandler} title={`${translate('Cancel import')}?`}
                         description={`${translate('The import has not yet completed. You really want to cancel import?')}`}>
                <Container>
                    <Row>
                        <Col xs={6} className={"ps-0"}>
                            <Button variant="outline-primary" className={"w-100 fw-normal"}
                                    onClick={onCloseCancelModalHandler}>
                                {translate('Continue')}
                            </Button>
                        </Col>
                        <Col xs={6} className={"pe-0"}>
                            <Button variant="primary" className={"w-100 fw-normal"}
                                    onClick={onCancelImportHandler}>
                                {translate('Cancel import')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </SimpleModal>

            <ErrorModalComponent i18n={i18next} onClose={() => {}} isOpen={!!currentStatusError} currentError={currentStatusError} />

        </>
    );
};
