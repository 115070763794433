import React, {useState} from 'react';
import { InfoBanner, TableHeader } from '@services/ui-components';
import { Row } from 'react-bootstrap';
import CreateServiceForm from './CreateServiceForm';
import { useTranslation } from 'react-i18next';
import {useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import JoyrideWrapper from "../../Joyride/JoyrideWrapper";

const CreateService: React.FC = () => {
  const { t } = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  if (!currentCompanyId) return <Spinner/>;

  return (
    <div className='mb-3 '>
      <InfoBanner>
        <p className='fs-7 mb-0'>{t('On this page, you can create a new service. After' +
          ' creating it, be sure to select the related employees to make the service available for booking.')}</p>
      </InfoBanner>
      <TableHeader
        title={t('Add Service')}
        paths={[
          { path: `/services/service/${currentCompanyId}/new`, label: t('services') },
          { path: `/services/service/${currentCompanyId}/service-list`, label: t('Service list') },
          { path: `/services/service/${currentCompanyId}/new`, label: t('Add Service') },
        ]}
        colSize={12}
      />
      <CreateServiceForm />
    </div>
  );
};

export default CreateService;
