import React from 'react';
import { useTranslation } from 'react-i18next';

const TooltipTextForType: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-start text-dark">
      <ul className="list-unstyled">
        <li>
          <span className="text-primary fw-bold">
            {t("ToolTypeForFeedbackType.Original")}
          </span>
          {t("ToolTypeForFeedbackType.reviews that were created by customers")}
        </li>
        <li>
          <span
          className="text-orange fw-bold">
            {t("ToolTypeForFeedbackType.Edited")}
          </span>
          {t("ToolTypeForFeedbackType.reviews that are created or edited by the administrator")}
        </li>
      </ul>
    </div>
  );
};

export default TooltipTextForType;