import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { PhoneNumberUtil } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

export const clientFormValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().trim().required(t('validation.required')),
    email: Yup.string()
      .email(t('validation.requiredEmail'))
      .test(
        'email-required-if-no-phone',
        t('validation.at least one of the fields phone or email must be filled'),
        function (value) {
          let phone = this.resolve(Yup.ref('phone'));
          return phone ? true : !!value;
        }
      ),
    phone: Yup.string()
      .test(
        'phone-required-if-no-email',
        t('validation.at least one of the fields phone or email must be filled'),
        function (value) {
          const email = this.resolve(Yup.ref('email'));
          if (!value) {
            return !!email;
          } else if (!isPhoneValid(value)) {
            return this.createError({ message: t('Phone number is not valid') });
          }
          return true;
        }
      )
  });
