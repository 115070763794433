import React from 'react';
import AsyncSelect from "react-select/async";
import { useFormikContext } from "formik";
import customStyles from "../ReactSelect/customStyles";
import { CustomDropdownIndicator } from '../ReactSelect/CustomDropdownIndicator';

interface Option {
    value: string | number;
    label: string;
}

interface ReactAsyncSelectProps {
    name?: string;
    value: Option | null,
    options: Array<any>,
    placeholder: string,
    onChange: (option: Option | null) => void,
    isSearchable: boolean
    disabled?: boolean
    key?: string,
    isLoading?: boolean,
    loadOptions?: any,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
    id?: string
}

const filterOption = (option: Option, inputValue: string) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
};

const ReactAsyncSelect: React.FC<ReactAsyncSelectProps> = ({ name, value, loadOptions, placeholder, onChange, isSearchable, disabled = false, key, isLoading, id, onKeyDown, options }) => {
    const formikContext = useFormikContext<any>();
    const errors = formikContext?.errors && formikContext.errors[name];
    const touched = formikContext?.touched && formikContext.touched[name];

    return (
        <div>
            <AsyncSelect
                id={id}
                name={name}
                key={key}
                inputId={name}
                value={value}
                defaultOptions={options}
                loadOptions={loadOptions}
                placeholder={placeholder}
                onChange={onChange}
                isSearchable={isSearchable}
                styles={customStyles(errors, touched)}
                isDisabled={disabled}
                filterOption={filterOption}
                components={{
                    DropdownIndicator: CustomDropdownIndicator
                }}
                isLoading={isLoading}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

export default ReactAsyncSelect;
