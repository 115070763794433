import React, { useState, useImperativeHandle, forwardRef } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useTranslation } from 'react-i18next';
import {useFormikContext} from "formik";

interface Option {
  id?: string;
  name?: string;
  value?: string;
  label?: string;
}


interface CustomReactSelectProps {
  optionsArray: { id?: number; name?: string }[];
  placeholder: string;
  setField: (field: string, value: string) => void;
  fieldName: string;
  value: string;
  additionalParam?: (field: string, value: string) => void;
  customPropsStyles?: any;
  isTooltip?: boolean;
  initialValue?: Option
  name?: string
  inputId?: string
  id?: string
  disabled?: boolean
}

const customStyles = (
    error: any,
    touched: any,
    isTooltip: boolean
): StylesConfig<Option, false> => ({
  control: (provided, { isFocused, isSelected }) => {
    const defaultBorderColor = isFocused ? '#ACA1DF' : '#DEDEDE';
    const borderColor = touched && error ? '#DC3545' : defaultBorderColor;
    return {
      ...provided,
      borderColor: borderColor,
      padding: "6px 0px",
      borderRadius: '4px',
      minHeight: '100%',
      boxShadow: isFocused || isSelected ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : 'none',
      '&:hover': {
        borderColor: '#ACA1DF',
      },
    };
  },

  option: (provided, { isSelected }) => ({
    ...provided,
    borderRadius: "4px",
    marginBottom: "8px",
    padding: "4px 12px",
    cursor: "pointer",
    backgroundColor: isSelected ? '#5842BE' : '#FFFFFF',
    color: isSelected ? '#FFFFFF' : '#1F3354',
    '&:hover': {
      backgroundColor: '#EEECF9',
      color: '#1F3354',
    },
  }),

  placeholder: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#9D9D9D",
    margin: "0px 4px",
  }),

  input: (provided) => ({
    ...provided,
    fontSize: "14px",
    color: "#1F3354",
  }),

  dropdownIndicator: (provided, { selectProps }) => ({
    ...provided,
    transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    transition: 'transform 0.2s ease',
    marginRight: isTooltip ? '26.26px' : undefined,
  }),

  menu: (provided) => ({
    ...provided,
    fontSize: "14px",
    marginTop: "4px",
  }),

  menuList: (provided) => ({
    ...provided,
    padding: '8px 12px',
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  singleValue: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
});

const CustomReactSelect = forwardRef<{ reset: () => void }, CustomReactSelectProps>(({
                                                                                       optionsArray,
                                                                                       placeholder,
                                                                                       setField,
                                                                                       fieldName,
                                                                                       value,
                                                                                       additionalParam,
                                                                                       isTooltip,
                                                                                       initialValue,
                                                                                       name,
                                                                                       inputId,
                                                                                        id,
                                                                                       disabled = false,
                                                                                     }, ref) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<Option | null>(null || initialValue);
  const formikContext = useFormikContext<any>();
  const errors = formikContext?.errors && formikContext.errors[fieldName];
  const touched = formikContext?.touched && formikContext.touched[fieldName];

  const handleChange = (option: Option | null) => {
    setSelectedOption(option);
    if (option) {
      const selectedValue = value === "id" ? option?.id : option?.value;
      setField(fieldName, selectedValue!);
      if (additionalParam) {
        additionalParam(fieldName, selectedValue!);
      }
    }
  }

  const handleReset = () => {
    setSelectedOption(null);
    setField(fieldName, '');
    if (additionalParam) {
      additionalParam(fieldName, '');
    }
  }

  useImperativeHandle(ref, () => ({
    reset: handleReset,
  }));

  const transformedOptions = optionsArray?.map(i => ({
    id: i?.id,
    value: i.name,
    label: i.name,
    name: i.name
  }));

  return (
      <>
        <Select
            placeholder={placeholder}
            styles={customStyles(errors, touched, isTooltip)}
            options={transformedOptions}
            value={selectedOption}
            onChange={handleChange}
            noOptionsMessage={() => t("No options")}
            inputId={name}
            id={id}
            isDisabled={disabled}
        />
      </>
  );
});

export default CustomReactSelect;