import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import React, {ReactElement} from 'react';
import {Popover} from 'react-bootstrap';

type PlacementType = 'top' | 'right' | 'bottom' | 'left';


interface ToolTipProps {
  text: string | ReactElement;
  placement: PlacementType;
  children: ReactElement;
  show?: boolean;
  styleBdy?: string;
  maxWidth?: string;
  trigger?: any;
}

const TooltipPopover: React.FC<ToolTipProps> = ({
                                                  text,
                                                  placement,
                                                  children,
                                                  show,
                                                  maxWidth = '402px',
                                                  styleBdy = 'py-2 px-3',
                                                  trigger = ['hover', 'focus']
                                                }) => {
  const renderTooltip = (text: string | ReactElement) =>
    <Popover id="popover-contained" style={{maxWidth: maxWidth}} className='py-1 me-2'>
      <Popover.Body className={styleBdy}>
        {text}
      </Popover.Body>
    </Popover>
  return (
    <OverlayTrigger
      placement={placement}
      overlay={renderTooltip(text)}
      show={show}
      trigger={trigger}
    >
      {children}
    </OverlayTrigger>
  );
};

export default TooltipPopover;
