import {TFunction} from 'i18next';
import {Option} from './interface';
import {DateTime} from 'luxon';
import {PlanPriceProxy} from '@me-team/host/graphql/types';

export const getInitialPlans = (t: TFunction, currency: Option['label']) => [
  {
    id: 1,
    planName: t('STARTER'),
    planNameForFind: 'STARTER',
    price: 0,
    planId: 1,
    currency: currency,
    period: 'YEARLY',
    features: [
      '2 branches',
      '1 owner + 1 administrator + 5 employees',
      'Services;',
      'Journal of Records',
      'Online Booking (with advertising)',
      'Customers;',
      'Record Statistics;',
    ],
    currentPlan: true,
  },
  {
    id: 2,
    planName: t('LITE'),
    planNameForFind: 'LITE',
    price: 0,
    planId: 0,
    currency: '₴',
    period: 'YEARLY',
    features: [
      '2 branches',
      '1 owner + 1 administrator + 5 employees',
      'Services;',
      'Journal of Records',
      'Online Booking (without advertising)',
      'Customers;',
      'Record Statistics;',
    ],
    currentPlan: false,
  },
  {
    id: 3,
    planName: t('PREMIUM'),
    planNameForFind: 'PREMIUM',
    price: 0,
    planId: 0,
    currency: '₴',
    period: 'YEARLY',
    features: [
      'Unlimited Branches',
      'Unlimited Employees',
      'Services;',
      'Journal of Records',
      'Online Booking (without advertising)',
      'Customers;',
      'Record Statistics;',
    ],
    currentPlan: false,
  }
];

// extracting word after "_"
export const getSecondWord = (str: string) => {
  const parts = str.split('_');
  return parts.length > 1 ? parts[1] : '';
};

// return a short description for the tariff plan
export const getTariffDescription = (tariff: string) => {
  switch (tariff) {
    case 'TARIFF_STARTER':
    case 'STARTER':
      return 'Available by default upon registration';
    case 'TARIFF_LITE':
    case 'LITE':
      return 'Disables the display of ads in the online booking';
    case 'TARIFF_PREMIUM':
    case 'PREMIUM':
      return 'Without ads and restrictions of branches and employees';
    default:
      return 'Unknown tariff';
  }
};

export const calculateNextPaymentDate = (isYearly: boolean, lastPaymentDate: string) => {
  // Parse the date of the last payment
  let date = DateTime.fromFormat(lastPaymentDate, 'yyyy-MM-dd');
  if (!date.isValid) {
    throw new Error('Invalid date format');
  }
  // Add the year or month depending on the isYearly value
  if (isYearly) {
    date = date.plus({years: 1});
  } else {
    date = date.plus({months: 1});
  }
  // format the date of the next payment in the required format
  return date.toFormat('dd.MM.yyyy');
};

export const getPrice = (currency: string, period: string, planData: PlanPriceProxy) => {
  if (currency && period) {
    const rate = currency === 'uah' ? planData?.uah : planData?.eur;
    return period === 'MONTHLY' ? rate : period === 'YEARLY' ? rate / 12 : null;
  }
};

export const getButtonProps = (
  isCurrentTariff: boolean,
  currentPlan: boolean,
  planNameForFind: string
) => {
  if (isCurrentTariff) {
    if (currentPlan) {
      return {isDisabled: true, text: 'Current Tariff'};
    }
    if (planNameForFind !== 'STARTER') {
      return {isDisabled: false, text: 'Connect'};
    }
  } else {
    if (planNameForFind === 'STARTER') {
      return {isDisabled: true, text: 'Current Tariff'};
    }
    return {isDisabled: false, text: 'Connect'};
  }
  return null;
};

export const variantButton = (tariff: string) => {
  switch (tariff) {
    case 'PREMIUM':
      return 'primary';
    case 'LITE':
      return 'outline-primary';
    case 'STARTER':
      return 'outline-dark';
    default:
      return
  }
};