import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const GenerateQr: React.FC<StepFunctions> = ({ goToStep , setRun, setModalJoyride}) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    const finish = () => { localStorage.setItem('run', 'false'); setRun(false); setModalJoyride(true) }

    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={11}>
                            <Row className='p-0'>
                                <Col xs={1}>
                                    <i className="bi bi-info-circle"></i>
                                </Col>
                                <Col xs={11}>
                                    <p className='fs-7 text-dark'>{t('When you click the “Generate QR” button, a QR code with the corresponding link to the company\'s/branch\'s online booking form will be created and uploaded.')}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className=' p-0'>
                            <Button className="w-100" onClick={finish}>{t('Finish')}</Button>
                        </Col>
                    </Row>
                </>
            )}

        </>
    )
}

export default GenerateQr;
