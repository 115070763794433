import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Icon from './image/subscribe-to-online-registration.svg';
import {useState} from "react";
import CommonQuestionsModal from "./CommonQuestionsModal";
import {useTranslation} from "react-i18next";
interface SuccessfulModalProps {
    setModalJoyride: (value: boolean) => void;
    modalJoyride: boolean;
}

const SuccessfulModal: React.FC<SuccessfulModalProps> = ({ setModalJoyride, modalJoyride }) => {
    const { t } = useTranslation();
    const [commonQuestionsModal, setCommonQuestionsModal] = useState(false);
    return (
        <>
            <Modal show={modalJoyride} onHide={() => setModalJoyride(false)} centered  >

                <Modal.Header className='border-0 pb-0' closeButton>

                </Modal.Header>
                <Modal.Body className='text-center pt-0'>
                    <h5 className='fw-normal'>{t('Successful connection!')}</h5>
                    <Icon className='my-2' />
                    <p className='mb-0 mt-2'>{t('Congratulations! You have successfully set up Online Booking and are ready to accept bookings from clients.')}</p>

                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Col >
                        <Button variant="outline-primary" className="w-100 fw-normal"
                                onClick={() => {
                                    setModalJoyride(false);
                                    setCommonQuestionsModal(true);
                                }}
                        >
                            {t('Frequently asked questions')}
                        </Button>
                    </Col>
                    <Col>
                        <Button variant="primary" className="w-100" onClick={() => setModalJoyride(false)}>
                            {t('Complete')}
                        </Button>
                    </Col>
                </Modal.Footer>

            </Modal>

            <CommonQuestionsModal setCommonQuestionsModal={setCommonQuestionsModal} commonQuestionsModal={commonQuestionsModal}  />
        </>

    );
};

export default SuccessfulModal;
