import React, {useEffect, useState} from 'react';
import {useFormikContext} from 'formik';

const StarRatingChoice: React.FC = () => {
  const { setFieldValue, setFieldTouched, errors, touched } = useFormikContext();
  const [hoverRating, setHoverRating] = useState(0);
  const [rating, setRating] = useState(0);

  // function when hovering on a star
  const handleMouseEnter = (starRating: number) => {
    setHoverRating(starRating);
  };

  // function when the hover leaves the star
  const handleMouseLeave = () => {
    setHoverRating(0);
  };

  const handleClick = (starRating: number) => {
    setRating(starRating)
    setFieldValue('rating', starRating);
    setFieldTouched('rating', true);
  };

  useEffect(() => {
    if (rating > 0 && touched && errors) {
      setFieldTouched('rating', true);
      setFieldValue('rating', rating);
    }
  }, [rating, setFieldTouched, setFieldValue, errors, touched]);

  return (
    <>
    <div style={{ display: 'flex' }} >
      {[1, 2, 3, 4, 5].map((star) => (
        <i
          key={star}
          id='addReview-rating-field'
          className={`bi ${(hoverRating || rating) >= star ? 'bi-star-fill' : 'bi-star'} text-orange me-1`}
          onMouseEnter={() => handleMouseEnter(star)}
          onMouseLeave={handleMouseLeave}
          onClick={() => handleClick(star)}
          style={{ cursor: 'pointer',transition: 'color 0.3s' }}
        />
      ))}
    </div>
    </>
  );
};

export default StarRatingChoice;
