import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';

interface CommentFieldProps {
  label: string;
  placeholder: string;
  name: string;
  id: string;
  disabled?: boolean;
}

const CommentField: React.FC<CommentFieldProps> = ({
                                                     label,
                                                     placeholder,
                                                     name,
                                                     id,
                                                     disabled
}) => {
  const [field, meta, helpers] = useField('comment');
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(field.value.length);
  }, [field.value]);

  return (
    <>
      <div className='col-md-2 mb-2 mb-md-0 d-flex pt-3'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold'>
          {label}
        </label>
      </div>
      <div className='col-md-10'>
        <Field name='comment'>
          {({field, meta, form}: FieldProps) => (
            <textarea
              {...field}
              id={id}
              placeholder={placeholder}
              className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
              onBlur={() => form.setFieldTouched(field.name, true, true)}
              maxLength={400}
              disabled={disabled}
            />
          )}
        </Field>
        <span
          id='countSymbols'
          className='fs-7 text-grey'>
          {charCount}/400
        </span>
        <ErrorMessage name="comment">
          {msg => <div className='invalid-feedback'>{msg}</div>}
        </ErrorMessage>
      </div>
    </>
  );
};

export default CommentField;
