import React from 'react';
import {useTranslation} from 'react-i18next';

interface LabelTypeProps {
  status: string
}

const LabelType: React.FC<LabelTypeProps> = ({status}) => {
  const {t} = useTranslation()
  const isModified = status === 'edited';

  return (
    <div className="text-end">
      <div
        className={`d-flex align-items-center justify-content-center badge rounded-1 p-2 fs-7  ${isModified ? 'bg-additional-orange' +
          ' text-orange' : 'bg-additional-violet' +
          ' text-primary'}`} style={{height: '32px'}}>
        {isModified ? t('ToolTypeForFeedbackType.Edited') : t('ToolTypeForFeedbackType.Original')}
      </div>
    </div>
  );

};

export default LabelType;