import React, {useState} from 'react';
import {Button, Col, Container, Row, Table} from 'react-bootstrap';
import StatusBadge from './StatusBadge';
import { getSecondWord} from '../constants';
import {useTranslation} from 'react-i18next';
import {formatDate, formatDateTime} from '@me-team/host/src/utils/utils';
import CustomModal from '@services/ui-components/src/Modal/CustomModal';
import {Link} from 'react-router-dom';
import { PaidFeatureProxy, PaymentOrderProxy } from '@me-team/host/main/graphql/types';

interface PaymentHistoryTableProps {
  subscription:PaidFeatureProxy,
  userEmail:string,
  timeZone: string,
}

const PaymentHistoryTable: React.FC<PaymentHistoryTableProps> = ({subscription,userEmail, timeZone}) => {
  const {t} = useTranslation();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const periodText = subscription?.isYearly ? t('Annual Subscription') : t('Monthly Subscription');
  const payment = subscription?.paymentOrder?.paymentOrders.find((p) => p.id === selectedPayment?.id);
  const firstPayment = subscription?.paymentOrder
  const handleOpenDetailsModal = (payment:PaymentOrderProxy) => {
    setSelectedPayment(payment);
    setIsDetailsModalOpen(true);
  };

  const handleCloseDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedPayment(null);
  };

  return (
    <Container className='px-0 table-responsive scrollbar position-relative px-5'>
      <div className='d-flex justify-content-center gap-5 bg-violet-light fs-7 p-3'>
        <div>
          <span>
            {t('Subscription Registration Date:')}
          </span>
          <span className='fw-bold ms-1'>
            {formatDate(subscription?.subscriptionRegistrationDate)}
          </span>
        </div>
        <div>
          <span>
            {t('Last Payment Date')}
          </span>
          <span className='fw-bold ms-1'>
            {formatDate(subscription?.dateOfLastPayment)}
          </span>
        </div>
      </div>
      {firstPayment?.id ? (
        <Table className='table table-borderless mb-0'>
          <thead>
          <tr className='py-3 pe-0 ps-4'>
            <th className='ps-4 col-2 pe-2 text-start align-middle fs-7'>{t('Sum')}</th>
            <th className=' px-2 col-2 text-start align-middle fs-7'>{t('Payment method')}</th>
            <th className=' px-2 col-2 text-start align-middle fs-7'>{t('dateAndTime')}</th>
            <th className=' px-2 col-2 text-start align-middle fs-7'>{t('Payment status')}</th>
            <th className=' ps-2 col-1 text-end align-middle'></th>
          </tr>
          </thead>
          <tbody>
            <tr className='py-3 pe-0 ps-4 border-bottom'>
              <td className='align-middle text-start ps-2 py-4 pe-2'>
                {firstPayment?.amount} {t(firstPayment?.currency)}
              </td>
              <td className='align-middle text-start ps-2 py-4 pe-2'>
                SWIFT
              </td>
              <td className='align-middle text-start ps-2 py-4 pe-2'>
                {formatDateTime(firstPayment?.createdAt, timeZone)}
              </td>
              <td className='align-middle text-start ps-2 py-4 pe-2'>
                <StatusBadge text={t(firstPayment?.status)} variant={firstPayment?.status} />
              </td>
              <td className='align-middle text-end ps-2 py-4 pe-0'>
                <Button
                  variant='outline-secondary'
                  style={{ width: '39px', height: "39px" }}
                  onClick={() => handleOpenDetailsModal(firstPayment)}
                >
                  <i className="bi bi-three-dots text-grey"></i>
                </Button>
              </td>
            </tr>
            {!!subscription?.paymentOrdersByDate?.length ? subscription?.paymentOrdersByDate?.map((payment: PaymentOrderProxy) => (
              <tr className='py-3 pe-0 ps-4 border-bottom' key={payment?.id}>
                <td className='align-middle text-start ps-2 py-4 pe-2'>
                  {payment?.amount} {t(payment?.currency)}
                </td>
                <td className='align-middle text-start ps-2 py-4 pe-2'>
                  SWIFT
                </td>
                <td className='align-middle text-start ps-2 py-4 pe-2'>
                  {formatDateTime(payment?.createdAt, timeZone)}
                </td>
                <td className='align-middle text-start ps-2 py-4 pe-2'>
                  <StatusBadge text={t(payment?.status)} variant={payment?.status}/>
                </td>
                <td className='align-middle text-end ps-2 py-4 pe-0'>
                  <Button
                    variant='outline-secondary'
                    style={{width: '39px', height: "39px"}}
                    onClick={() => handleOpenDetailsModal(payment)}
                  >
                    <i className="bi bi-three-dots text-grey"></i>
                  </Button>
                </td>
              </tr>
            )) : null}
          </tbody>
        </Table>) : null}

      <CustomModal
        show={isDetailsModalOpen}
        handleClose={handleCloseDetailsModal}
        title={
          <Container>
            <Row>
              <h5 className='m-0 p-0'>{t('Payment details')}</h5>
            </Row>
            <Row>
              <p className='fs-normal text-grey p-0 m-0'>
                <span className='me-1'>{t(getSecondWord(subscription?.name))}</span>
                ({periodText})
              </p>
            </Row>
          </Container>
        }
        variantTitle='fs-5'
        body={
          <Container>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('Payment number')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  {selectedPayment?.id}
                </span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('Sum')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  {selectedPayment?.amount} {selectedPayment?.currency === 'uah' ? '₴' : '€'}
                </span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('email')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  {userEmail}
                </span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('dateAndTime')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  {selectedPayment?.createdAt && formatDateTime(selectedPayment?.createdAt, timeZone)}
                </span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('status')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  <StatusBadge text={selectedPayment?.status} variant={selectedPayment?.status} />
                </span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={6} className='p-0 text-start'>
                <span>{t('Account and Receipt')}</span>
              </Col>
              <Col xs={6} className='p-0 text-end'>
                <span className='text-dark'>
                  <Link to={payment?.invoiceUrl}>View</Link>
                </span>
              </Col>
            </Row>
          </Container>
        }
        buttons={[
          {
            text: 'OK',
            onClick: handleCloseDetailsModal,
            variant: 'primary',
            className: 'w-100 fw-normal',
          }
        ]}
      />
    </Container>

  );
};

export default PaymentHistoryTable;
