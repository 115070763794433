import React from 'react';
import {useState} from "react";
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useTranslation} from "react-i18next";

const BookingStep: React.FC<StepFunctions> = ({ goToNextStep, goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <p className='mb-0 fs-7'>{t('To activate online booking for an employee,')} <span className="fs-normal fw-bold fs-7">{t('open \"Online booking settings\".')} </span></p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0"
                                onClick={handleButtonClick}
                        >
                            <i className="bi bi-x-lg "></i>
                        </Button>
                    </Col>
                </Row>
            )}

        </div>
    )
}

export default BookingStep;