import React, { useState } from 'react';
import { Button, Col, Row } from "react-bootstrap";
import StartStep from "./StepsComponents/StartStep";
import {useTranslation} from "react-i18next";


const MyFirstStepTooltip: React.FC = () => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);

    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <Row>
                    <Col xs={11}>
                        <span className="fs-normal fw-bold fs-7">{t('Click \"Add employee\"')} </span>
                        <p className='mb-0 fs-7 d-inline'>{t("to open the form for creating new employees")}</p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default MyFirstStepTooltip;

