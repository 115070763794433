import {TFunction} from "i18next";

class ErrorImportFilesHandling {
    public static validationHeaderErrorHandling = (code : string,
                                                   columns : string[],
                                                   translate :  TFunction<"translation", undefined>) => {
        const DUPLICATED_COLUMNS : string = 'duplicated_columns';
        const FILE_EMPTY : string = 'file_empty';
        const MISSING_COLUMNS : string = 'missing_columns';

        switch (code) {
            case DUPLICATED_COLUMNS: return translate('No data was saved. The file contains duplicate columns. Edit it and re-upload it');
            case FILE_EMPTY: return translate('No data was saved. An empty file has been uploaded. Select the correct file to import data');
            case MISSING_COLUMNS: return `${translate('The data was not saved, the file is missing columns')} : ${columns.join(', ')}`;
            default: return null;
        }
    }
}

export default ErrorImportFilesHandling;
