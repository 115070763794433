import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { currentCompanyIdVar } from "../../apollo/globalVar/state";
import { CompanyProxy } from "../../../graphql/types";
import LogoDefault from "@me-team/common/src/images/logo-add.png";

type SidebarHeaderProps = {
    company: CompanyProxy,
    isMobile?: boolean,
    handleCloseNavbar?: () => void
}
const SidebarSubheader: React.FC<SidebarHeaderProps> = ({company, isMobile = false, handleCloseNavbar}) => {
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const basePath = process.env.REACT_APP_POS_URL;
    const navigate = useNavigate();

    return (
        <>
            <div className={`d-flex align-items-center ${isMobile ? "justify-content-center" : ""}`}>
                <div className="d-flex justify-content-start align-items-center">
                    <img src={company?.image ? `${basePath}/${company.image}` : LogoDefault} alt={company?.name} style={{width: "35px", height: "35px"}} loading="lazy"/>
                </div>
                <Link to={'branches'} className='text-dark text-break text-decoration-none fw-bold fs-20 ms-2 me-3 mb-0' onClick={handleCloseNavbar}>{company?.name}</Link>
            </div>
            <button
              className='edit-button border-0 px-0'
              onClick={()=>navigate(`company/${currentCompanyId}/edit`)
            }>
                <i className='bi bi-pencil text-primary fs-normal'></i>
            </button>
        </>
    );
}

export default SidebarSubheader;
