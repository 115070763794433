import React, {ReactNode} from "react";
import { Alert } from 'react-bootstrap';

interface AlertPaymentExpiredProps {
    children?: ReactNode;
}
const AlertPaymentExpired: React.FC<AlertPaymentExpiredProps> = ({children}) => {

    return (
        <div className="mx-6">
            <Alert variant={"warning"} className="bg-additional-orange border-orange rounded-1">
                {children}
            </Alert>
        </div>
    )
}

export default AlertPaymentExpired;