import TooltipCustom from '@services/ui-components/src/ToolTip/ToolTipCustom';
import {ErrorMessage, Field, FieldProps, useField} from 'formik';
import React, {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface TextareaInputProps {
  label: string;
  placeholder: string;
  name: string;
  id: string;
}

const TextareaInput: React.FC<TextareaInputProps> = ({
                                                       label,
                                                       placeholder,
                                                       name,
                                                       id
}) => {
  const {t} = useTranslation();
  const [field, meta] = useField(name);
  const [charCount, setCharCount] = useState(0);

  useEffect(() => {
    setCharCount(field?.value?.length);
  }, [field.value]);

  return (
    <>
      <Col
        md={2}
        className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-3'>
          {label}
        </label>
      </Col>
      <Col md={10}>
        <div className='position-relative'>
          <Field name={name}>
            {({field, meta, form}: FieldProps) => (
              <textarea
                {...field}
                id={id}
                placeholder={placeholder}
                className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
                onBlur={() => form.setFieldTouched(field.name, true, true)}
                maxLength={1000}
              />
            )}
          </Field>
          <TooltipCustom placement='right' text={t('These data are displayed in the Online Registration form')}>
            <i className={`bi bi-info-circle position-absolute top-0 end-0 translate-middle-y text-dark mt-4 me-2 pe-3'
             ${meta.error ? 'd-none' : ''}`}></i>
          </TooltipCustom>
          <ErrorMessage name="description">{msg => <div className='invalid-feedback'>{msg}</div>}</ErrorMessage>
        </div>
        <span
          id='countSymbols'
          className='fs-7 text-grey'>
          {charCount ? charCount : 0}/1000
        </span>
        {meta.touched && meta.error && <div className='invalid-feedback'>{meta.error}</div>}{' '}
      </Col>
    </>
  );
};

export default TextareaInput;
