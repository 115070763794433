import React from 'react';

interface ReviewProxy {
    __typename?: string; // Optional field
    id: number;
    rating: number;
}

interface RatingProps {
    rating: ReviewProxy[];
}

const RatingStars = ({ rating }: RatingProps) => {
    const calculateAverageRating = (reviews: ReviewProxy[]) => {
        if (reviews?.length === 0) {
            return (
                <span className="d-flex flex-row align-items-center justify-content-center text-orange">
                    <i className="bi bi-star mx-1" />
                    0
                </span>
            );
        }

        const totalRating = reviews?.reduce((acc, review) => acc + review.rating, 0);
        const averageRating = Math.round(totalRating / reviews?.length);

        switch (averageRating) {
            case 0:
                return (
                    <span className="d-flex flex-row align-items-center justify-content-center text-orange">
                        <i className="bi bi-star mx-1" />
                        {averageRating}
                    </span>
                );
            case 5:
                return (
                    <span className="d-flex flex-row align-items-center justify-content-center text-orange ">
                        <i className="bi bi-star-fill mx-1" /> {' '}
                        {averageRating}
                    </span>
                );
            default:
                return (
                    <span className="d-flex flex-row align-items-center justify-content-center text-orange">
                        <i className="bi bi-star-half mx-1" />
                        {averageRating}
                    </span>
                );
        }
    };

    return (
        <div>
            {calculateAverageRating(rating)}
        </div>
    );
};

export default RatingStars;
