import React from 'react';
import { ToastContainer, Toast, CloseButton, Col, Button, Row } from 'react-bootstrap';


interface ToastProps {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    text: string
}

const ToastComponent: React.FC<ToastProps> = ({ show, setShow, text }) => {
    return (
        <>
            <ToastContainer position="bottom-center" className='position-fixed' style={{  zIndex: 9999999999  }}  >
                <Toast
                    onClose={() => setShow(false)}
                    show={show}
                    delay={3000}
                    autohide
                    style={{
                        backgroundColor: '#3E4957',
                        width: 'auto'
                        }}
                    className='m-3'
                >
                    <Toast.Body className='d-flex justify-content-between py-6 px-3 fs-6 fw-normal '>
                        <p className='mb-0 text-white'>{text || null }</p>
                        <CloseButton onClick={() => setShow(false)} variant='white' className='ms-4 text-white' />
                    </Toast.Body>
                </Toast>
            </ToastContainer>

        </>
    );
};

export default ToastComponent;

