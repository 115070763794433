import {ServiceCategoryNewProxy} from '../../main/graphql/types';

export interface CategoryInterface {
  id: number;
  name: string;
  isOpen: boolean;
  isPicked: boolean;
  isSubcategoryPiked: boolean;
  subcategoryList: SubcategoryInterface[];
}

interface SubcategoryInterface {
  id: number;
  name: string;
  isPicked: boolean;
}

const transformCategories = (data: ServiceCategoryNewProxy[]): CategoryInterface[] => {
  const result: CategoryInterface[] = [];

  data.forEach((item) => {
    if (item.parentCategory === null) {
      // Check if a category with this ID exists as a result
      const existingCategory = result.find(cat => cat.id === item.id);
      if (!existingCategory) {
        // Main category
        const mainCategory: CategoryInterface = {
          id: item.id,
          name: item.name,
          isOpen: false,
          isPicked: false,
          isSubcategoryPiked: false,
          subcategoryList: [],
        };

        // Adding unique subcategories to the main category
        (item.childCategories || []).forEach((child) => {
          // Check if there is a subcategory with this ID in subcategoryList
          if (!mainCategory.subcategoryList.some(sub => sub.id === child.id)) {
            mainCategory.subcategoryList.push({
              id: child.id,
              name: child.name,
              isPicked: false,
            });
          }
        });

        result.push(mainCategory);
      }
    } else {
      // If there is a parent category
      const parentCategoryIndex = result.findIndex(
        (cat) => cat.id === item.parentCategory!.id
      );

      if (parentCategoryIndex !== -1) {
        // If the parent category is already in the results
        const parentCategory = result[parentCategoryIndex];

        // Check for duplicate subcategory
        if (!parentCategory.subcategoryList.some(sub => sub.id === item.id)) {
          parentCategory.subcategoryList.push({
            id: item.id,
            name: item.name,
            isPicked: false,
          });
        }
      } else {
        // If there is no parent category, create a new category
        const newParentCategory: CategoryInterface = {
          id: item.parentCategory.id,
          name: item.parentCategory.name,
          isOpen: false,
          isPicked: false,
          isSubcategoryPiked: false,
          subcategoryList: [
            {
              id: item.id,
              name: item.name,
              isPicked: false,
            },
          ],
        };
        result.push(newParentCategory);
      }
    }
  });

  return result;
};

export default transformCategories;