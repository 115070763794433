import React, {useEffect, useRef, useState} from "react";
import {ServiceDropdown} from "../CategoryDropdown/ServiceDropdown";

import {ServicePickCategoryInterfaceInner, ServicePickSubcategoryInner} from "../../../interfaces/interfaces";
import {ServicePickerInnit} from "../../../services/ServicePickerInnit";
import {useTranslation} from "react-i18next";
import styles from './ServicePick.module.scss'
import {AddRecordService} from "../../../services/addRecord/AddRecordService";
import {ServiceCategoryNewProxy} from "@me-team/host/main/graphql/types";


type ServicePickProps = {
    serviceCategoryList: ServiceCategoryNewProxy[],
    selectedCategoryId: number,
    selectedServiceId: number,
    companyCurrencySign: string,
    onServicePick : ( serviceId : number, isPicked : boolean ,categoryId? : number) => void,
    isDisabled : boolean,
    name : string
    hasError? : boolean
    employeeId? : number
}

type inputValues = {
    isPicked: boolean,
    value: string
}

export const ServicePick: React.FC<ServicePickProps> = (props) => {
    const {t: translate} = useTranslation();
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [categoryList, setCategoryList] = useState<ServicePickCategoryInterfaceInner[]>(null);
    const [pickedService, setPickedService] =
        useState<inputValues>(
            AddRecordService.getCategoryAndServiceName(
                categoryList,
                props.selectedCategoryId,
                props.selectedServiceId, translate
            )
        );
    const [serviceInputValue, setServiceInputValue] = useState<string>('');

    const onOpenDropdown = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (showDropdown) {
            setShowDropdown(false);
            inputRef.current?.blur();
        } else {
            setShowDropdown(true);
            inputRef.current?.focus();
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            inputRef.current && !inputRef.current.contains(event.target as Node) &&
            dropdownRef.current && !dropdownRef.current.contains(event.target as Node)
        ) {
            setShowDropdown(false);
            inputRef.current?.blur();
        }
    };




    useEffect(() => {
        setCategoryList(ServicePickerInnit.ServicePikerModuleCreator(
            props.serviceCategoryList,
            props.selectedCategoryId, props.selectedServiceId)
        );
        setPickedService(AddRecordService.getCategoryAndServiceName(
            ServicePickerInnit.ServicePikerModuleCreator(
                props.serviceCategoryList,
                props.selectedCategoryId, props.selectedServiceId),
            props.selectedCategoryId,
            props.selectedServiceId, translate
        ))
        if (!props.selectedServiceId && !props.selectedCategoryId) {
            setServiceInputValue('');
            setPickedService(AddRecordService.getCategoryAndServiceName(
                ServicePickerInnit.ServicePikerModuleCreator(
                    props.serviceCategoryList,
                    props.selectedCategoryId, props.selectedServiceId),
                props.selectedCategoryId,
                props.selectedServiceId, translate
            ))
        }


    }, [props.serviceCategoryList, props.selectedCategoryId, props.selectedServiceId]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const onServicePickHandler = (categoryId: number, serviceId: number, isPicked: boolean) => {
        if (isPicked) {
            setShowDropdown(false);
        }
        setServiceInputValue('');
        setCategoryList(prevState => {
            return prevState.map((category: ServicePickCategoryInterfaceInner) => {
                return {
                    ...category,
                    isOpen: !!((category.id === categoryId) && serviceId),
                    servicePickerList: category.servicePickerList.map((service: ServicePickSubcategoryInner) => {
                        return {
                            ...service,
                            isPicked: (service.id === serviceId && !service.isPicked)
                        }
                    })

                }
            })
        });

        if (isPicked) {
            setPickedService(AddRecordService.getCategoryAndServiceName(
                categoryList,
                categoryId,
                serviceId, translate
            ))
        } else {
            setPickedService(AddRecordService.getCategoryAndServiceName(
                categoryList,
                null,
                null, translate
            ))
        }
        props.onServicePick(serviceId, isPicked, categoryId)
    }

    const onSearchCategoryHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setServiceInputValue(event.target.value)
        const searchValue = event.target.value
        setCategoryList(AddRecordService.searchCategoryAndService(categoryList, searchValue));
    }

    return (
        <div className={'position-relative'}>
            <div className={'position-relative'}>
                <input
                    id={props.name}
                    name={props.name}
                    type="text"
                    className={`form-control select-border-hover 
                    ${pickedService.isPicked ? 'service-picker-placeholder-color' : ''} 
                    ${showDropdown ? 'rounded-bottom-0 no-focus-outline' : ''} 
                    ${props.hasError ? 'border-danger' : ''}`}
                    ref={inputRef}
                    onFocus={() => setShowDropdown(true)}
                    value={serviceInputValue}
                    placeholder={pickedService.value}
                    onChange={onSearchCategoryHandler}
                    disabled={props.isDisabled}
                />
                <span className={' d-flex position-absolute top-50 end-0 me-2 pe-1 translate-middle-y'} onClick={onOpenDropdown}>
                    <i className={`bi d-flex bi-chevron-down transition ${showDropdown ? 'rotate-icon-180' : ''}`}></i>
                </span>
            </div>
            {(showDropdown && categoryList) && (
                <div
                    className={`${styles.maxHeightDropdown} pb-2 px-6 border z-1 border-top-0 rounded rounded-top-0 transition position-absolute w-100 bg-white`}
                    ref={dropdownRef}
                >
                    {categoryList.map((category: ServicePickCategoryInterfaceInner) => {
                        if (!category.isHidden && category.servicePickerList.some(service => !service.isHidden)) {
                            return (
                                <ServiceDropdown
                                    key={`kay-${category.id}`}
                                    category={category}
                                    companyCurrencySign={props.companyCurrencySign}
                                    onServicePick={onServicePickHandler}
                                    employeeId={props.employeeId}
                                />
                            );
                        }
                        return null;
                    })}
                    {AddRecordService.isCategoryEmpty(categoryList) &&
                        <p className={'mb-0 text-gray text-center text-secondary mt-2'}>{translate('No options')}</p>}
                </div>
            )}
        </div>
    );
};
