import React from 'react';
import { Link } from 'react-router-dom';
import {ServiceProxy} from '@me-team/host/graphql/types'; // Здесь предположительно находятся перечисления ролей

interface ServiceNameWithOptionalLinkProps {
  service: ServiceProxy;
  currentCompanyId: number;
  isEmployeeRole: boolean;
}

const ServiceNameWithOptionalLink: React.FC<ServiceNameWithOptionalLinkProps> = ({
                                                                                   service,
                                                                                   currentCompanyId,
                                                                                   isEmployeeRole
                                                                                 }) => {
  if (isEmployeeRole) {
    return (
      <span className={service?.deleted ? 'text-secondary' : 'text-dark'}>
        {service?.name}
      </span>
    );
  }

  return (
    <Link
      to={`/services/service/${currentCompanyId}/service-list/${service?.id}/edit`}
      className={service?.deleted ? 'text-secondary' : 'text-primary'}
      style={{ textDecoration: 'none' }}
    >
      {service?.name}
    </Link>
  );
};

export default ServiceNameWithOptionalLink;
