import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Branch, CreateBranch } from '@me-team/common/src/components/index'
import { Home } from '@me-team/host/src/components/Home/Home';
import WithDocumentTitle from "@me-team/common/src/router/withDocumentTitle";
import { Clients, Reviews, Services } from '@me-team/common/src/components';

const menuRoutes = [
    {
        index: true,
        element: React.createElement(WithDocumentTitle(Home, 'home')),
    },
]

export const router = createBrowserRouter(menuRoutes);

export default menuRoutes;
