import React from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
    generalFormValidationSchema,
    getContactInfoFields,
    getMainInfoFields,
    getSocialNetworkInfoFields
} from './constants';

const GeneralInfoForm: React.FC = () => {
    const { t } = useTranslation();

    const mainInfoFields = getMainInfoFields(t);
    const contactInfoFields = getContactInfoFields(t);
    const socialNetworkInfoFields = getSocialNetworkInfoFields(t);
    const validationSchema = generalFormValidationSchema(t);

    const formik = useFormik({
        initialValues: {
            name: '',
            type: '',
            description: '',
            country: '',
            city: '',
            address: '',
            phone: '',
            email: '',
            website: '',
            image: null,
            instagram: '',
        },
        validationSchema,
        onSubmit: (values) => {
            console.log(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            {/*<FormBlock title={t('basicInfo')} fields={[]} formik={formik} />*/}
            {/*<FormBlock title={t('contactInfo')} fields={[]} formik={formik} />*/}
            {/*<FormBlock title={t('socialNetworks')} fields={[]} formik={formik} />*/}
            <button type="submit" className="btn btn-primary">{t('Create')}</button>
        </form>
    );
};

export default GeneralInfoForm;
