import { useState } from 'react';
import { useLocation } from 'react-router-dom';
export const useToast = () => {
    const location = useLocation()
    const [showToast, setShowToast] = useState(location?.state?.toast || false);
    const [toastText, setToastText] = useState(location?.state?.text || '');

    const toggleShowToast = (text?: string) => {
        text && setToastText(text);
        setShowToast(false);
        setTimeout(() => {
            setShowToast(true);
        }, 100);
    };

    return { showToast, toastText, toggleShowToast, setShowToast, setToastText };
};
