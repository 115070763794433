import { Image, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import React from 'react';

const EmployeeCell: React.FC<{ i: any, link?: boolean }> = ({ i, link }) => {
    const basePath = process.env.REACT_APP_POS_URL;
    const imageUrl = i?.image ? `${basePath}/${i.image}` : `${process.env.REACT_APP_POS_URL}/build/images/person.jpg`;
    return (
        <Row className="align-items-center">
            <Col xs="auto" className='pr-0'>
                <Image className="img-employ-list rounded-circle" src={imageUrl} width="40px" height="40px" />
            </Col>
            <Col className='p-0'>
                {link ? (
                    <Link to={'/employees-list/edit-employee/' + i?.id} className='text-decoration-none text-dark'>
                        <p className="mb-0 fs-16">
                            {i?.name} {i?.surname}
                        </p>
                    </Link>
                ) : (
                    <p className="mb-0 fs-16">
                        {i?.name} {i?.surname}
                    </p>
                )}
                <span className="text-secondary">
                    <small>{i?.position.name}</small>
                </span>
            </Col>
        </Row>
    );
};

export default EmployeeCell;
