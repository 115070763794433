import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageCrop from "@services/ui-components/src/ImageCrop/ImageCrop";


interface MultipleImageCropProp {
    key?: string
    photosArray: (File | null)[];
    setPhotos: Dispatch<SetStateAction<(File | null)[]>>;
    disabled?: boolean;
    setConfiramitoinField?: () => void;
    setFieldValue?: (field: string, file: File) => void;
}

const MultipleImageCrop: React.FC<MultipleImageCropProp> = ({ key, photosArray, setPhotos, disabled, setConfiramitoinField, setFieldValue, }) => {
    const { t } = useTranslation();
    const [error, setError] = useState<boolean | string>(false);
    console.log('photosArray', photosArray)
    const addPhotoToQualification = (index: number, newPhoto: File | null): void => {
        setPhotos((prevPhotos: (File | null)[]) => {
            const updatedPhotos = [...prevPhotos];
            updatedPhotos[index] = newPhoto;
            if (updatedPhotos.length < 5 && !updatedPhotos.includes(null)) {
                updatedPhotos.push(null);
            }
            return updatedPhotos;
        });

        if (setConfiramitoinField) setConfiramitoinField();
        setError(false);
    };

    const rejection = (errorMsg: string) => {
        setError(errorMsg);
    };

    const generateKey = (photo: File | null, index: number) => {
        if (photo) {
            return `${photo.name}-${photo.size}-${index}`;
        }
        return `empty-${index}`;
    };

    const renderDropItems = () => {
        const items = photosArray.map((photo, index) => (
            <div style={{ maxWidth: '116px' }} className="p-0 mb-2" key={key}>
                <ImageCrop
                    key={generateKey(photo, index)}
                    setFieldValue={setFieldValue}
                    modalTitle={t('Photo of the branch')}
                    index={index}
                    handleImage={addPhotoToQualification}
                    dropItemView={true}
                    dropItemLink={photo ? URL.createObjectURL(photo) : null}
                    aspectProp={ 2 / 1}
                    rejection={rejection}
                    disabled={disabled}
                />
            </div>
        ));

        if (photosArray.length < 5 && photosArray[photosArray.length - 1] !== null) {
            items.push(
                <div style={{ maxWidth: '116px' }} className="p-0 mb-2" key={key}>
                    <ImageCrop
                        key={`empty-${photosArray.length}`}
                        setFieldValue={setFieldValue}
                        modalTitle={t('Photo of the branch')}
                        index={photosArray.length}
                        handleImage={addPhotoToQualification}
                        dropItemView={true}
                        aspectProp={ 2 / 1}
                        rejection={rejection}
                        disabled={disabled}
                    />
                </div>
            );
        }

        return items;
    };

    return (
        <>
            {renderDropItems()}
        </>
    );
};

export default MultipleImageCrop;
