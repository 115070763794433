import React, {useEffect, useState} from 'react';
import {Col, Button, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AddEditModal} from '../index';
import AddPaymentForm from '@me-team/common/src/components/Salary/AddPaymentForm';

interface PeriodSummaryRowProps {
  isButton?: boolean,
  totalCost: number,
  currency: string,
  totalItems: number,
  isAddPaymentModalOpened?: boolean
}

const PeriodSummaryRow: React.FC<PeriodSummaryRowProps> = ({
                                                             isButton,
                                                             totalCost,
                                                             totalItems,
                                                             currency,
                                                             isAddPaymentModalOpened
                                                           }) => {
  const {t} = useTranslation();
  const [isModalOpened, setIsModalOpened] = useState(false)

  useEffect(() => {
    if (isAddPaymentModalOpened) {
      setIsModalOpened(isAddPaymentModalOpened)
    }
  }, [isAddPaymentModalOpened]);

  const handleShowAddPaymentModal = () => {
    setIsModalOpened(true);
  }
  const handleModalClose = () => setIsModalOpened(false)

  return (
    <Row className='px-4 py-3 bg-violet-light mx-0'>
      <Col lg={12} className='d-flex align-items-center gap-4 px-0'>
        <p className='m-0'>
          {t('Total for the period -')}
          <span className='fw-bold ms-1'>{totalCost}</span> {t(currency) } {t('for')} <span
          className='fw-bold'>{totalItems}</span> {t('performed services')}
        </p>
        {isButton && (
          <Col
            sm={2}>
            <Button
              variant='primary'
              size='sm'
              className='w-100  mb-3 mb-lg-0'
              onClick={handleShowAddPaymentModal}
            >
              <i className='bi bi-plus-lg fw-bold me-2 w-100 h-100'></i>
              {t('Add Payment')}
            </Button>
          </Col>
        )}
      </Col>
      <AddEditModal
        title={t('Add Payment')}
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
      >
        <AddPaymentForm handleModalClose={handleModalClose}/>
      </AddEditModal>
    </Row>
  );
};

export default PeriodSummaryRow;