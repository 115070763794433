import {CombinedJobInterface} from "../../interfaces/interfaces";
import {JobContainerProxy} from "@me-team/host/graphql/types";
import {DateTime} from 'luxon';

class LoadingStatusService {
    public static combinedAndSortedJobService = (jobs : JobContainerProxy) : CombinedJobInterface[] => {
        const combinedJob : Array<CombinedJobInterface> = [];
        if (jobs.importJobs.length !== 0) {
            jobs.importJobs.forEach((importJob) => combinedJob.push(importJob));
        }
        if (jobs.exportJobs.length !==0) {
            jobs.exportJobs.forEach((exportJob) => combinedJob.push(exportJob));
        }

        const format = "yyyy-MM-dd HH:mm:ss";
        const parseDate = (dateString: string) => DateTime.fromFormat(dateString, format);

        return combinedJob.sort((a, b) => {
            const dateA = parseDate(a.createdAt);
            const dateB = parseDate(b.createdAt);
            return dateB.toMillis() - dateA.toMillis();
        })
    }
}

export default LoadingStatusService