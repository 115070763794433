/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetEmployeeServicesDocument = gql`
    query GetEmployeeServices($id: Int!, $page: Int, $itemsPerPage: Int, $categoryId: Int, $serviceName: String, $accessibility: Boolean, $onlineBooking: Boolean) {
  user {
    id
    company {
      servicesList(employeeId: $id) {
        id
        name
        employeeServiceRelations(employeeId: $id) {
          id
        }
      }
      employees(id: $id) {
        employees {
          id
          name
          surname
          employeeServicesList {
            id
            name
          }
          employeeServices(
            page: $page
            itemsPerPage: $itemsPerPage
            categoryId: $categoryId
            serviceName: $serviceName
            accessibility: $accessibility
            onlineBooking: $onlineBooking
          ) {
            employeeServices {
              id
              name
              price
              duration
              onlineRegistration
              serviceCategory {
                id
                name
              }
              serviceSubcategory {
                id
                name
              }
              employeeServiceRelations(employeeId: $id) {
                id
                deleted
                duration
                price
                online
                salaryType
                salaryValue
                employee {
                  id
                  name
                  surname
                  recordSetting {
                    id
                    isOnlineSignUpAvailable
                  }
                }
                service {
                  name
                }
              }
            }
            itemsPerPage
            totalCount
            currentPage
            pageCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeServicesQuery__
 *
 * To run a query within a React component, call `useGetEmployeeServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeServicesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      categoryId: // value for 'categoryId'
 *      serviceName: // value for 'serviceName'
 *      accessibility: // value for 'accessibility'
 *      onlineBooking: // value for 'onlineBooking'
 *   },
 * });
 */
export function useGetEmployeeServicesQuery(baseOptions: Apollo.QueryHookOptions<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables> & ({ variables: GetEmployeeServicesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>(GetEmployeeServicesDocument, options);
      }
export function useGetEmployeeServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>(GetEmployeeServicesDocument, options);
        }
export function useGetEmployeeServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>(GetEmployeeServicesDocument, options);
        }
export type GetEmployeeServicesQueryHookResult = ReturnType<typeof useGetEmployeeServicesQuery>;
export type GetEmployeeServicesLazyQueryHookResult = ReturnType<typeof useGetEmployeeServicesLazyQuery>;
export type GetEmployeeServicesSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeServicesSuspenseQuery>;
export type GetEmployeeServicesQueryResult = Apollo.QueryResult<GetEmployeeServicesQuery, GetEmployeeServicesQueryVariables>;
export const GetServicesCategoryDocument = gql`
    query GetServicesCategory {
  serviceCategories {
    items {
      id
      name
      childCategories {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetServicesCategoryQuery__
 *
 * To run a query within a React component, call `useGetServicesCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServicesCategoryQuery(baseOptions?: Apollo.QueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
      }
export function useGetServicesCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
        }
export function useGetServicesCategorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>(GetServicesCategoryDocument, options);
        }
export type GetServicesCategoryQueryHookResult = ReturnType<typeof useGetServicesCategoryQuery>;
export type GetServicesCategoryLazyQueryHookResult = ReturnType<typeof useGetServicesCategoryLazyQuery>;
export type GetServicesCategorySuspenseQueryHookResult = ReturnType<typeof useGetServicesCategorySuspenseQuery>;
export type GetServicesCategoryQueryResult = Apollo.QueryResult<GetServicesCategoryQuery, GetServicesCategoryQueryVariables>;
export const GetCategoryServicesDocument = gql`
    query GetCategoryServices {
  user {
    company {
      id
      serviceCategoriesNew(id: null, name: null) {
        id
        name
        childCategories(id: null, name: null) {
          id
          name
          deleted
        }
      }
      serviceCategories {
        serviceCategories {
          deleted
          id
          name
          serviceSubcategories {
            id
            name
            deleted
            serviceCategory {
              id
              name
              deleted
            }
          }
        }
        totalCount
      }
    }
  }
}
    `;

/**
 * __useGetCategoryServicesQuery__
 *
 * To run a query within a React component, call `useGetCategoryServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCategoryServicesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>(GetCategoryServicesDocument, options);
      }
export function useGetCategoryServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>(GetCategoryServicesDocument, options);
        }
export function useGetCategoryServicesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>(GetCategoryServicesDocument, options);
        }
export type GetCategoryServicesQueryHookResult = ReturnType<typeof useGetCategoryServicesQuery>;
export type GetCategoryServicesLazyQueryHookResult = ReturnType<typeof useGetCategoryServicesLazyQuery>;
export type GetCategoryServicesSuspenseQueryHookResult = ReturnType<typeof useGetCategoryServicesSuspenseQuery>;
export type GetCategoryServicesQueryResult = Apollo.QueryResult<GetCategoryServicesQuery, GetCategoryServicesQueryVariables>;
export const UpdateEmployeeServiceRelationDocument = gql`
    mutation UpdateEmployeeServiceRelation($relationId: Int!, $input: EmployeeServiceRelationInput!) {
  updateEmployeeServiceRelation(relationId: $relationId, input: $input) {
    id
    online
    price
    duration
    salaryType
    salaryValue
    deleted
  }
}
    `;
export type UpdateEmployeeServiceRelationMutationFn = Apollo.MutationFunction<UpdateEmployeeServiceRelationMutation, UpdateEmployeeServiceRelationMutationVariables>;

/**
 * __useUpdateEmployeeServiceRelationMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeServiceRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeServiceRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeServiceRelationMutation, { data, loading, error }] = useUpdateEmployeeServiceRelationMutation({
 *   variables: {
 *      relationId: // value for 'relationId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeServiceRelationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeServiceRelationMutation, UpdateEmployeeServiceRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmployeeServiceRelationMutation, UpdateEmployeeServiceRelationMutationVariables>(UpdateEmployeeServiceRelationDocument, options);
      }
export type UpdateEmployeeServiceRelationMutationHookResult = ReturnType<typeof useUpdateEmployeeServiceRelationMutation>;
export type UpdateEmployeeServiceRelationMutationResult = Apollo.MutationResult<UpdateEmployeeServiceRelationMutation>;
export type UpdateEmployeeServiceRelationMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeServiceRelationMutation, UpdateEmployeeServiceRelationMutationVariables>;
export const CreateEmployeeServiceRelationDocument = gql`
    mutation CreateEmployeeServiceRelation($employeeId: Int!, $serviceId: Int!, $duration: String!, $price: Float!, $onlineBooking: Boolean!) {
  createEmployeeServiceRelation(
    employeeId: $employeeId
    serviceId: $serviceId
    input: {duration: $duration, price: $price, onlineBooking: $onlineBooking, salaryType: null, salaryValue: null}
  ) {
    id
  }
}
    `;
export type CreateEmployeeServiceRelationMutationFn = Apollo.MutationFunction<CreateEmployeeServiceRelationMutation, CreateEmployeeServiceRelationMutationVariables>;

/**
 * __useCreateEmployeeServiceRelationMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeServiceRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeServiceRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeServiceRelationMutation, { data, loading, error }] = useCreateEmployeeServiceRelationMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      serviceId: // value for 'serviceId'
 *      duration: // value for 'duration'
 *      price: // value for 'price'
 *      onlineBooking: // value for 'onlineBooking'
 *   },
 * });
 */
export function useCreateEmployeeServiceRelationMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeServiceRelationMutation, CreateEmployeeServiceRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeServiceRelationMutation, CreateEmployeeServiceRelationMutationVariables>(CreateEmployeeServiceRelationDocument, options);
      }
export type CreateEmployeeServiceRelationMutationHookResult = ReturnType<typeof useCreateEmployeeServiceRelationMutation>;
export type CreateEmployeeServiceRelationMutationResult = Apollo.MutationResult<CreateEmployeeServiceRelationMutation>;
export type CreateEmployeeServiceRelationMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeServiceRelationMutation, CreateEmployeeServiceRelationMutationVariables>;
export const DeleteEmployeeServiceRelationDocument = gql`
    mutation DeleteEmployeeServiceRelation($id: Int!) {
  deleteEmployeeServiceRelation(id: $id)
}
    `;
export type DeleteEmployeeServiceRelationMutationFn = Apollo.MutationFunction<DeleteEmployeeServiceRelationMutation, DeleteEmployeeServiceRelationMutationVariables>;

/**
 * __useDeleteEmployeeServiceRelationMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeServiceRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeServiceRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeServiceRelationMutation, { data, loading, error }] = useDeleteEmployeeServiceRelationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeServiceRelationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeServiceRelationMutation, DeleteEmployeeServiceRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeServiceRelationMutation, DeleteEmployeeServiceRelationMutationVariables>(DeleteEmployeeServiceRelationDocument, options);
      }
export type DeleteEmployeeServiceRelationMutationHookResult = ReturnType<typeof useDeleteEmployeeServiceRelationMutation>;
export type DeleteEmployeeServiceRelationMutationResult = Apollo.MutationResult<DeleteEmployeeServiceRelationMutation>;
export type DeleteEmployeeServiceRelationMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeServiceRelationMutation, DeleteEmployeeServiceRelationMutationVariables>;
export const CreateAllRelationsDocument = gql`
    mutation CreateAllRelations($input: EmployeeServiceRelationInputAll!) {
  createAllRelations(input: $input) {
    id
  }
}
    `;
export type CreateAllRelationsMutationFn = Apollo.MutationFunction<CreateAllRelationsMutation, CreateAllRelationsMutationVariables>;

/**
 * __useCreateAllRelationsMutation__
 *
 * To run a mutation, you first call `useCreateAllRelationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllRelationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllRelationsMutation, { data, loading, error }] = useCreateAllRelationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllRelationsMutation(baseOptions?: Apollo.MutationHookOptions<CreateAllRelationsMutation, CreateAllRelationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAllRelationsMutation, CreateAllRelationsMutationVariables>(CreateAllRelationsDocument, options);
      }
export type CreateAllRelationsMutationHookResult = ReturnType<typeof useCreateAllRelationsMutation>;
export type CreateAllRelationsMutationResult = Apollo.MutationResult<CreateAllRelationsMutation>;
export type CreateAllRelationsMutationOptions = Apollo.BaseMutationOptions<CreateAllRelationsMutation, CreateAllRelationsMutationVariables>;
export const DeleteAllRelationsDocument = gql`
    mutation DeleteAllRelations($input: EmployeeServiceRelationInputAll!) {
  deleteAllRelations(input: $input)
}
    `;
export type DeleteAllRelationsMutationFn = Apollo.MutationFunction<DeleteAllRelationsMutation, DeleteAllRelationsMutationVariables>;

/**
 * __useDeleteAllRelationsMutation__
 *
 * To run a mutation, you first call `useDeleteAllRelationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllRelationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllRelationsMutation, { data, loading, error }] = useDeleteAllRelationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAllRelationsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllRelationsMutation, DeleteAllRelationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllRelationsMutation, DeleteAllRelationsMutationVariables>(DeleteAllRelationsDocument, options);
      }
export type DeleteAllRelationsMutationHookResult = ReturnType<typeof useDeleteAllRelationsMutation>;
export type DeleteAllRelationsMutationResult = Apollo.MutationResult<DeleteAllRelationsMutation>;
export type DeleteAllRelationsMutationOptions = Apollo.BaseMutationOptions<DeleteAllRelationsMutation, DeleteAllRelationsMutationVariables>;
export type GetEmployeeServicesQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  categoryId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  serviceName?: Types.InputMaybe<Types.Scalars['String']['input']>;
  accessibility?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  onlineBooking?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetEmployeeServicesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', servicesList?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number }> | null }> | null, employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, employeeServicesList?: Array<{ __typename?: 'ServiceProxy', id: number, name: string }> | null, employeeServices?: { __typename?: 'EmployeeServicesPaginated', itemsPerPage?: number | null, totalCount?: number | null, currentPage?: number | null, pageCount?: string | null, employeeServices?: Array<{ __typename?: 'ServiceProxy', id: number, name: string, price?: number | null, duration?: string | null, onlineRegistration: boolean, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string } | null, serviceSubcategory?: { __typename?: 'ServiceSubcategoryProxy', id: number, name: string } | null, employeeServiceRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number, deleted: boolean, duration?: string | null, price?: number | null, online: boolean, salaryType?: number | null, salaryValue?: number | null, employee?: { __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, recordSetting?: { __typename?: 'RecordSettingProxy', id: number, isOnlineSignUpAvailable: boolean } | null } | null, service?: { __typename?: 'ServiceProxy', name: string } | null }> | null }> | null } | null }> | null } | null } | null } | null };

export type GetServicesCategoryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetServicesCategoryQuery = { __typename?: 'Query', serviceCategories?: { __typename?: 'ServiceCategoryNewPaginated', items?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string } | null> | null }> | null } | null };

export type GetCategoryServicesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCategoryServicesQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', id: number, serviceCategoriesNew?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, childCategories?: Array<{ __typename?: 'ServiceCategoryNewProxy', id: number, name: string, deleted: boolean } | null> | null }> | null, serviceCategories?: { __typename?: 'ServiceCategoryPaginated', totalCount?: number | null, serviceCategories?: Array<{ __typename?: 'ServiceCategoryProxy', deleted: boolean, id: number, name: string, serviceSubcategories?: Array<{ __typename?: 'ServiceSubcategoryProxy', id: number, name: string, deleted: boolean, serviceCategory?: { __typename?: 'ServiceCategoryProxy', id: number, name: string, deleted: boolean } | null }> | null }> | null } | null } | null } | null };

export type UpdateEmployeeServiceRelationMutationVariables = Types.Exact<{
  relationId: Types.Scalars['Int']['input'];
  input: Types.EmployeeServiceRelationInput;
}>;


export type UpdateEmployeeServiceRelationMutation = { __typename?: 'Mutation', updateEmployeeServiceRelation: { __typename?: 'EmployeeServiceRelationProxy', id: number, online: boolean, price?: number | null, duration?: string | null, salaryType?: number | null, salaryValue?: number | null, deleted: boolean } };

export type CreateEmployeeServiceRelationMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  serviceId: Types.Scalars['Int']['input'];
  duration: Types.Scalars['String']['input'];
  price: Types.Scalars['Float']['input'];
  onlineBooking: Types.Scalars['Boolean']['input'];
}>;


export type CreateEmployeeServiceRelationMutation = { __typename?: 'Mutation', createEmployeeServiceRelation: { __typename?: 'EmployeeServiceRelationProxy', id: number } };

export type DeleteEmployeeServiceRelationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteEmployeeServiceRelationMutation = { __typename?: 'Mutation', deleteEmployeeServiceRelation: boolean };

export type CreateAllRelationsMutationVariables = Types.Exact<{
  input: Types.EmployeeServiceRelationInputAll;
}>;


export type CreateAllRelationsMutation = { __typename?: 'Mutation', createAllRelations?: Array<{ __typename?: 'EmployeeServiceRelationProxy', id: number }> | null };

export type DeleteAllRelationsMutationVariables = Types.Exact<{
  input: Types.EmployeeServiceRelationInputAll;
}>;


export type DeleteAllRelationsMutation = { __typename?: 'Mutation', deleteAllRelations: boolean };
