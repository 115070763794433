import React from 'react';
import {BookingMainProxy} from '@me-team/host/main/graphql/types';

interface SalaryRendererProps {
  salaryItem: BookingMainProxy;
  currencySing: string;
}

const SalaryRenderer:React.FC<SalaryRendererProps> = ({ salaryItem, currencySing }) => {
  const isValidSalaryData = salaryItem?.bookingSalary
    && Number.isFinite(salaryItem.bookingSalary.salaryValue)
    && Number.isFinite(salaryItem.price);
  if (!isValidSalaryData) {
    return <span>0 {currencySing}</span>;
  }

  const isSalaryPercentType = salaryItem?.bookingSalary?.salaryType == 1
  let salary;
  let percentageSalary;

  if (isSalaryPercentType) {
    salary = (salaryItem?.bookingSalary?.salaryValue * salaryItem?.price)
    percentageSalary = (salaryItem?.bookingSalary?.salaryValue )  * 100;
  } else {
    salary = salaryItem?.price
  }

  return isSalaryPercentType ? (
      <div>
        <span className='d-inline-block me-1'>
          {salary} {currencySing}
        </span>
        <span className="text-grey">
          ({`${percentageSalary}%`})
        </span>
      </div>
    )
      :
    (
      <span>
        {salaryItem?.bookingSalary?.salaryValue} {currencySing}
      </span>
    );
};

export default SalaryRenderer;