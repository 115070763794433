import {Modal, Button, Row, Col, Container} from 'react-bootstrap';
import React, {ReactNode} from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  variant: string;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

interface CustomModalProps {
  show: boolean;
  handleClose: () => void;
  title: ReactNode;
  body: ReactNode | string;
  variantTitle?: string;
  buttons: ButtonProps[];
}

const CustomModal: React.FC<CustomModalProps> = ({
                                                   show,
                                                   handleClose,
                                                   title,
                                                   variantTitle,
                                                   body,
                                                   buttons
                                                 }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      className='px-1'
    >
      <Modal.Header
        closeButton
        className='border-0 align-items-start '
      >
        <Modal.Title className={`h5 ${variantTitle}`}>{title}</Modal.Title>

      </Modal.Header>
      <Modal.Body className='text-center'>{body}</Modal.Body>
      <Modal.Footer className='border-0'>
        <Container className='m-0 p-0'>
          <Row className='gx-3 w-100 m-0 gap-2'>
            {buttons.map((button, index) => (
              <Col key={index} className='p-0'>
                <Button
                  variant={button.variant}
                  type={button.type}
                  className={button.className}
                  onClick={button.onClick}
                  disabled={button.disabled}
                >
                  {button.text}
                </Button>
              </Col>
            ))}
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
