import React from 'react';
import {useTranslation} from 'react-i18next';

interface LabelStatusPaymentProps {
  status: string
}

const LabelStatusPayment: React.FC<LabelStatusPaymentProps> = ({status}) => {
  const {t}=useTranslation()
  const isPaid = status === 'paid';

  return (
    <div className="d-flex justify-content-center">
      <div className={`d-flex align-items-center justify-content-center badge rounded-1 p-2 fs-7  ${isPaid ? 'bg-additional-green' +
        ' text-green' : 'bg-additional-orange' +
        ' text-orange'}`} style={{width:'110px', height:'30px'}}>
        {isPaid ? t('Paid') : t('Not paid')}
      </div>
    </div>
  );
};

export default LabelStatusPayment;