import React, {useEffect, useState} from 'react';
import {ButtonGroup, Col, Row, ToggleButton} from 'react-bootstrap';
import {ReactSelect} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import {stylesSelect} from './TariffPlans/stylesSelectReact';
import {ApolloError} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import { TariffPeriod } from '@me-team/host/main/graphql/types';
import { useGetTariffPlansQuery } from '@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks';

interface Option {
  value: string;
  label: string;
}

interface Filter {
  currency: Option;
  period: string;
}

interface TariffPlanFilterProps {
  onFilterChange: (filter: Filter) => void;
  onTariffPlans:any;
}

const  TariffPlanFilter: React.FC<TariffPlanFilterProps> = ({onFilterChange,onTariffPlans }) => {
  const {t} = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const currencyOptions = [
    {value: 'uah', label: '₴'},
    {value: 'eur', label: '€'}
  ];
  const [filter, setFilter] = useState<Filter>({
    currency: {value: 'uah', label: '₴'},
    period: 'YEARLY',
  });

  const period: TariffPeriod = filter.period as TariffPeriod;

  const {
    data: getTariffPlans,
    loading: getTariffPlansLoading,
    refetch: refetchTariffPlans
  } = useGetTariffPlansQuery({
    variables: {period: period},
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  useEffect(() => {
    refetchTariffPlans()
    onFilterChange(filter)
    onTariffPlans(getTariffPlans)
  }, [filter]);

  const handleCurrencyChange = (selectedOption: Option | null) => {
    const newFilter = {
      ...filter,
      currency: selectedOption || { value: 'uah', label: '₴' },
    };
    setFilter(newFilter);
  };

  const handlePeriodChange = (period: string) => {
    const newFilter = {
      ...filter,
      period: period,
    };
    setFilter(newFilter);
  };

  return (
    <Row className="d-flex align-items-center">
      <Col xl={6}>
        <h4
          className='fw-normal m-xl-0 mb-2 align-items-center'>{t('Choose the Best Rate for Your' +
          ' Business!')}</h4>
      </Col>
      <Col xl={6} className='d-flex align-items-center gap-4 justify-content-end'>
          <div>
            <ReactSelect
              id='tariffFilter-currency-field'
              value={filter.currency}
              options={currencyOptions}
              placeholder="Select currency"
              onChange={handleCurrencyChange}
              isSearchable={false}
              stylesSelect={stylesSelect}
            />
          </div>
        <ButtonGroup
          className='landing d-flex flex-nowrap h-100'
          style={{minHeight:'32px'}}
        >
          <ToggleButton
            id="tariffFilter-annualSubscription-field"
            type="radio"
            variant={filter.period === 'YEARLY' ? 'orange text-white' : 'outline-orange'}
            name="subscriptionTerm"
            value="YEARLY"
            checked={filter.period === 'YEARLY'}
            onChange={(e) => handlePeriodChange(e.currentTarget.value)}
            className='rounded-end-0 py-1 fw-normal d-flex align-items-center'
          >
            <i className='bo bi-star-fill me-1 me-sm-2'></i>
            <span>{t('Annual Subscription')}</span>
          </ToggleButton>
          <ToggleButton
            id="tariffFilter-monthlySubscription-field"
            type="radio"
            variant={filter.period === 'MONTHLY' ? 'orange text-white' : 'outline-orange'}
            name="subscriptionTerm"
            value="MONTHLY"
            checked={filter.period === 'MONTHLY'}
            onChange={(e) => handlePeriodChange(e.currentTarget.value)}
            className='rounded-start-0 py-1 fw-normal  d-flex align-items-center'
          >
            {t('Monthly Subscription')}
          </ToggleButton>
        </ButtonGroup>
      </Col>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </Row>
  );
};

export default TariffPlanFilter;
