import {
  DateRangeCalendar,
  ReactSelect,
  InfoBanner,
  TableHeader, FiltersButton
} from '@services/ui-components';
import {Row, Col, Container, Button} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PaymentHistoryTable from './PaymentHistoryTable';
import moment from 'moment/moment';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../services/ErrorService';
import { useGetModulesListQuery } from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import { useUpdateCompanyModuleRelationMutation } from '@me-team/host/main/clients/graphql/clients.hooks';
import { useGetAllEmployeesQuery } from '@me-team/host/main/salary/graphql/salary.hooks';

interface Option {
  value: number;
  label: string;
}

const PaymentHistory: React.FC = () => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {data: modulesList} = useGetModulesListQuery()
  const salaryModuleId = modulesList?.relations?.modules.find(module => module.name === "Salary");
  const logbookModuleId = modulesList?.relations?.modules.find(module => module.name === "Logbook");

  const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation({
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  useEffect(() => {
    updateCompanyModuleRelation({
      variables: {
        companyId: currentCompanyId && +currentCompanyId,
        input: {
          id: logbookModuleId && +logbookModuleId?.id,
          isActive: true
        }
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    })
    updateCompanyModuleRelation({
      variables: {
        companyId: currentCompanyId && +currentCompanyId,
        input: {
          id: salaryModuleId && +salaryModuleId?.id,
          isActive: true
        }
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    })
  }, []);

  const formatDate = (date: any) => {
    return moment(date).format('YYYY-MM-DD')
  }
  const initialFiltersRange = {
    startDate: formatDate(moment().subtract(1, 'week')),
    endDate: formatDate(moment()),
  }
  const [dateRangeFilter, setDateRangeFilter] = useState(initialFiltersRange);

  const [showFilters, setShowFilters] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const {data: employeesData} = useGetAllEmployeesQuery();

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee.id,
      label: `${employee.name} ${employee.surname}`
    }
  }) || [];
  const [filter, setFilter] = useState({dateRangeFilter, employeeFilter: null});

  const toggleFilters = () => setShowFilters(!showFilters);

  const handleEmployeesChange = (selectedOption: Option) => {
    setSelectedEmployees(selectedOption);
  };

  const handleApplyFilter = () => {
    setFilter({
      dateRangeFilter: dateRangeFilter,
      employeeFilter: selectedEmployees?.value ? selectedEmployees?.value : null
    });
  };

  const handleClearFilter = () => {
    setFilter({
      dateRangeFilter: initialFiltersRange,
      employeeFilter: null
    });
    setSelectedEmployees(null)
    setDateRangeFilter(initialFiltersRange);
  };

  return (
    <div className='mb-9'>
      <InfoBanner>
        <p
          className='fs-7 mb-0 violet-hover'>{t('A section for keeping a history of salary' +
          ' payments to' +
          ' employees')}
        </p>
      </InfoBanner>

      <TableHeader
        title={t('History of Payments')}
        paths={[
          {path: '/employees-list', label: t('Personnel Management')},
          {path: `/personnel/${currentCompanyId}/payment-history`, label: t('History of Payments')},
        ]}
        colSize={6}
        btnColSize={6}
        buttons={
          <Row
            className='d-flex justify-content-end mt-4 mt-lg-0'>
            <Col
              xs={12}
              sm={5}
              md={4}
              xxl={3}
            >
              <FiltersButton filter={filter} showFilters={showFilters} onClick={toggleFilters} classes="mb-3 mb-lg-0"/>
            </Col>
          </Row>
        }
        showFilters={showFilters}
      >
        <Row className='gx-3 pt-1 mb-4 pb-2 gap-4 gap-lg-0'>
          <Col lg={8} xl={9}>
            <Row className='gx-3'>
              <Col
                xs={12} sm={6}
                className='text-primary mb-3 mb-sm-0'>
                <DateRangeCalendar
                  dateRangeFilter={dateRangeFilter}
                  setDateRangeFilter={setDateRangeFilter}
                  id='paymentHistory-rangeDate-field'
                />
              </Col>

              <Col
                xs={12} sm={6}
                className='text-primary'>
                <ReactSelect
                  name='employees'
                  id='paymentHistory-employees-field'
                  value={selectedEmployees}
                  options={employeesOptions}
                  placeholder={t('employee')}
                  onChange={handleEmployeesChange}
                  isSearchable={true}
                />
              </Col>
            </Row>
          </Col>
          <Col lg={4} xl={3}>
            <Row className='gx-3 h-100'>
              <Col
                xs={6}
                className='mb-3 mb-lg-0'>
                <Button
                  variant='outline-dark'
                  type='button'
                  className=' border-grey w-100 h-100 fw-normal'
                  onClick={handleClearFilter}
                >
                  {t('clear')}
                </Button>
              </Col>
              <Col
                xs={6}
                className='mb-3 mb-lg-0'>
                <Button
                  variant='primary'
                  className='w-100 h-100 px-1'
                  onClick={handleApplyFilter}
                >
                  {t('apply')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

      </TableHeader>

      <PaymentHistoryTable
        filter={filter}
      />
    </div>
  );
};

export default PaymentHistory;
