import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";
import {useTranslation} from "react-i18next";

const AddServiceStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    const windowWidth = window?.innerWidth
    return (
        <div className="p-0">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep} />
            ) : (
                <Row>
                    <Col xs={11}>
                        <p className={`fs-7 ${windowWidth > 745 ? ' ' : 'mb-2'}`} >
                            <span className="fs-normal fw-bold fs-7">{t('Fill in the required fields')} </span>
                            {t('marked with an asterisk')}{' '}
                            (<span className="text-danger">*</span>)
                        </p>
                        {windowWidth > 745 ?
                        <p className='fs-7'>
                            <span className="fs-normal fw-bold fs-7">{t('Select a category')} </span>
                            {t('for the service')}
                        </p> : ''
                        }

                        <p className={`fs-7 ${windowWidth > 745 ? ' ' : 'mb-2'}`}>
                            <span className="fs-normal fw-bold fs-7">{t('Turn on the “Online Registration"')} </span>
                            {t('switch for the service to be displayed in the online booking form')}
                        </p>
                        <span className="fs-normal fw-bold fs-7">{t('Click “Save”')}</span>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg"></i>
                        </Button>
                    </Col>
                </Row>
            )}
        </div>


    )
}

export default AddServiceStep;
