import * as Yup from 'yup';

export const getMainInfoFields = (t: any) => [
  { label: t('nameEstablishment'), name: 'name' },
  { label: t('typeOfEstablishment'), name: 'type' },
  { label: t('establishmentDescription'), name: 'description', type: 'textarea' },
  { label: t('establishmentDescription'), name: 'description', type: 'textarea' },
];

export const getContactInfoFields = (t: any) => [
  { label: t('country'), name: 'country' },
  { label: t('city'), name: 'city' },
  { label: t('address'), name: 'address' },
  { label: t('phone'), name: 'phone' },
  { label: t('email'), name: 'email' },
  { label: t('website'), name: 'website', type: 'url' },
];

export const getSocialNetworkInfoFields = (t: any) => [
  { label: t('instagram'), name: 'instagram' },
  { label: t('facebook'), name: 'facebook' },
  { label: t('youtube'), name: 'youtube' },
  { label: t('whatsapp'), name: 'whatsapp' },
];
export const generalFormValidationSchema = (t: any) =>
  Yup.object({
    name: Yup.string().required(t('validation.required')),
    type: Yup.string().required(t('validation.required')),
    description: Yup.string(),
    country: Yup.string().required(t('validation.required')),
    city: Yup.string().required(t('validation.required')),
    address: Yup.string().required(t('validation.required')),
    phone: Yup.string()
      .matches(/^[0-9]+$/, t('validation.requiredPhone'))
      .required(t('validation.required')),
    email: Yup.string().email(t('validation.requiredEmail')).required(t('validation.required')),
    website: Yup.string().url(t('validation.requiredUrl')),
    image: Yup.mixed(),
    instagram: Yup.string().url(t('validation.requiredUrl')),
    facebook: Yup.string().url(t('validation.requiredUrl')),
    youtube: Yup.string().url(t('validation.requiredUrl')),
    whatsapp: Yup.string().matches(/^[0-9]+$/, t('validation.requiredPhone')),
  });
