/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetModulesListDocument = gql`
    query GetModulesList {
  relations {
    modules {
      id
      name
    }
  }
}
    `;

/**
 * __useGetModulesListQuery__
 *
 * To run a query within a React component, call `useGetModulesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesListQuery(baseOptions?: Apollo.QueryHookOptions<GetModulesListQuery, GetModulesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesListQuery, GetModulesListQueryVariables>(GetModulesListDocument, options);
      }
export function useGetModulesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesListQuery, GetModulesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesListQuery, GetModulesListQueryVariables>(GetModulesListDocument, options);
        }
export function useGetModulesListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesListQuery, GetModulesListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesListQuery, GetModulesListQueryVariables>(GetModulesListDocument, options);
        }
export type GetModulesListQueryHookResult = ReturnType<typeof useGetModulesListQuery>;
export type GetModulesListLazyQueryHookResult = ReturnType<typeof useGetModulesListLazyQuery>;
export type GetModulesListSuspenseQueryHookResult = ReturnType<typeof useGetModulesListSuspenseQuery>;
export type GetModulesListQueryResult = Apollo.QueryResult<GetModulesListQuery, GetModulesListQueryVariables>;
export const GetModulesForCompanyDocument = gql`
    query GetModulesForCompany {
  user {
    id
    email
    company {
      id
      modules {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetModulesForCompanyQuery__
 *
 * To run a query within a React component, call `useGetModulesForCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesForCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesForCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesForCompanyQuery(baseOptions?: Apollo.QueryHookOptions<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>(GetModulesForCompanyDocument, options);
      }
export function useGetModulesForCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>(GetModulesForCompanyDocument, options);
        }
export function useGetModulesForCompanySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>(GetModulesForCompanyDocument, options);
        }
export type GetModulesForCompanyQueryHookResult = ReturnType<typeof useGetModulesForCompanyQuery>;
export type GetModulesForCompanyLazyQueryHookResult = ReturnType<typeof useGetModulesForCompanyLazyQuery>;
export type GetModulesForCompanySuspenseQueryHookResult = ReturnType<typeof useGetModulesForCompanySuspenseQuery>;
export type GetModulesForCompanyQueryResult = Apollo.QueryResult<GetModulesForCompanyQuery, GetModulesForCompanyQueryVariables>;
export const AddModuleDocument = gql`
    mutation AddModule($companyId: Int!, $input: ModuleInputType!) {
  updateCompanyModuleRelation(companyId: $companyId, input: $input) {
    id
    name
  }
}
    `;
export type AddModuleMutationFn = Apollo.MutationFunction<AddModuleMutation, AddModuleMutationVariables>;

/**
 * __useAddModuleMutation__
 *
 * To run a mutation, you first call `useAddModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addModuleMutation, { data, loading, error }] = useAddModuleMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddModuleMutation(baseOptions?: Apollo.MutationHookOptions<AddModuleMutation, AddModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddModuleMutation, AddModuleMutationVariables>(AddModuleDocument, options);
      }
export type AddModuleMutationHookResult = ReturnType<typeof useAddModuleMutation>;
export type AddModuleMutationResult = Apollo.MutationResult<AddModuleMutation>;
export type AddModuleMutationOptions = Apollo.BaseMutationOptions<AddModuleMutation, AddModuleMutationVariables>;
export type GetModulesListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetModulesListQuery = { __typename?: 'Query', relations: { __typename?: 'RelationProxy', modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null } | null> | null } };

export type GetModulesForCompanyQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetModulesForCompanyQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, email?: string | null, company?: { __typename?: 'CompanyProxy', id: number, modules?: Array<{ __typename?: 'ModuleProxy', id: number, name?: string | null }> | null } | null } | null };

export type AddModuleMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ModuleInputType;
}>;


export type AddModuleMutation = { __typename?: 'Mutation', updateCompanyModuleRelation: { __typename?: 'CompanyProxy', id: number, name: string } };
