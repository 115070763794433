import React, {useEffect, useRef, useState} from 'react';
import {ErrorMessage, Form, Formik, FormikHelpers} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {
  DateRangeCalendar,
  ReactSelect,
  TextareaInputWithCounter
} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';
import {convertDateString, currentDate} from '@me-team/host/src/utils/utils';
import {DateTime} from 'luxon';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import moment from 'moment/moment';
import {Link} from 'react-router-dom';
import {addPaymentFormValidationSchema, calculateTotal} from './constans';
import {currencySingVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {ValidationError} from 'yup';
import {
  useCreatePaymentMutation,
  useGetAllEmployeesQuery,
  useGetSalaryQuery
} from '@me-team/host/main/salary/graphql/salary.hooks';
import {SalaryPaymentInput} from '@me-team/host/main/graphql/types';

interface Option {
  value: string | number | string[];
  label: string;
}

interface InitialValuesForm {
  date: string,
  dateRangeFilter: {
    startDate: string,
    endDate: string
  }
  employeeId: number,
  quantity: number,
  sum: number,
  comment?: string
}

interface AddPaymentFormProps {
  handleModalClose: () => void
}

const AddPaymentForm: React.FC<AddPaymentFormProps> = ({handleModalClose}) => {
  const {t} = useTranslation();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currencySing = useReactiveVar(currencySingVar);
  const EPOCH_TIME = moment(new Date(0)).format('YYYY-MM-DD')
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isErrorDateRangeFilter, setIsErrorDateRangeFilter] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [sum, setSum] = useState(0);

  const {data: employeesData} = useGetAllEmployeesQuery()
  const [createSalaryPayment] = useCreatePaymentMutation()
  const initialFiltersRange = {
    startDate: moment(new Date(null)).format('YYYY-MM-DD'),
    endDate: moment(new Date(null)).format('YYYY-MM-DD')
  }
  const [dateRangeFilter,
    setDateRangeFilter
  ] = useState(initialFiltersRange)

  const isValidateStartDate = dateRangeFilter?.startDate !== EPOCH_TIME && dateRangeFilter?.startDate !== null;
  const isValidateEndDate = dateRangeFilter?.endDate !== EPOCH_TIME && dateRangeFilter?.endDate !== null;

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee?.id,
      label: `${employee?.name} ${employee?.surname} ${employee?.position?.name}`
    }
  }) || [];

  const {data: salaryData, loading: salaryDataLoading} = useGetSalaryQuery({
    variables: {
      page: 1,
      itemsPerPage: 1000,
      completedBookings: true,
      filterByCompany: true,
      employeeId: selectedEmployees?.value ? selectedEmployees?.value : null,
      startDate: dateRangeFilter?.startDate,
      endDate: dateRangeFilter?.endDate,
    },
    skip: !selectedEmployees || !isValidateStartDate || !isValidateEndDate,
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  useEffect(() => {
    if (salaryData) {
      const totalBookings = salaryData?.user?.company?.bookings?.totalCount || 0;
      const salary = salaryData?.user?.company?.bookings?.bookings;
      const totalCost = salary ? calculateTotal(salary) : 0;

      setQuantity(totalBookings);
      setSum(totalCost);
    } else {
      setQuantity(0);
      setSum(0);
    }
  }, [salaryData]);

  const handleEmployeesChange = (selectedOption: Option, setFieldValue: FormikHelpers<any>['setFieldValue']) => {
    setSelectedEmployees(selectedOption);
    setFieldValue('employeeId', selectedOption.value)
  };

  const handleDateRangeError = (error: boolean) => {
    setIsErrorDateRangeFilter(error);
  };

  const initialValues: InitialValuesForm = {
    date: DateTime.now().toFormat('yyyy-MM-dd'),
    dateRangeFilter: {
      startDate: null,
      endDate: null
    },
    employeeId: null,
    quantity: 0,
    sum: 0,
    comment: ''
  }

  const handleCreatePaymentSubmit = (
    values: InitialValuesForm,
    {setSubmitting, setErrors}: FormikHelpers<InitialValuesForm>
  ) => {
    if (!dateRangeFilter?.startDate || !dateRangeFilter?.endDate || !isValidateStartDate || !isValidateEndDate) {
      setIsErrorDateRangeFilter(true);
      setSubmitting(false);
      return;
    }

    const input: SalaryPaymentInput = {
      periodStart: dateRangeFilter?.startDate && convertDateString(dateRangeFilter?.startDate),
      periodEnd: dateRangeFilter?.endDate && convertDateString(dateRangeFilter?.endDate),
      comment: values?.comment ? values?.comment : ''
    }
    createSalaryPayment({
      variables: {employeeId: values?.employeeId, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && handleModalClose();
        setSubmitting(false);
      },
      onError: (error: ApolloError) => {
        const graphQLErrors = error?.graphQLErrors ?? [];
        const extensions = graphQLErrors[0]?.extensions;
        const validation = (extensions?.validation as Record<string, ValidationError[]> | undefined) ?? {};
        const errorMessage = validation[""]?.[0]?.message;

        if (errorMessage === "There is already a payment for these dates, please edit them") {
          setIsErrorDateRangeFilter(true);
        } else {
          setIsErrorDateRangeFilter(false);
        }

        setIsErrorModalOpen(ErrorService.errorHandling(error));
        setSubmitting(false);
      }
    })
  }

  const handleLabelClick = (e: React.MouseEvent) => {
    e.preventDefault();
    buttonRef.current?.focus();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addPaymentFormValidationSchema(t)}
        onSubmit={handleCreatePaymentSubmit}
        validateOnMount
      >
        {({
            isSubmitting,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            handleReset,
            touched,
            errors
          }) => {
          useEffect(() => {
            if (isErrorDateRangeFilter) {
              setFieldTouched('dateRangeFilter.startDate', true);
              setFieldError('dateRangeFilter.startDate', t('Please select a start date.'));
              setFieldTouched('dateRangeFilter.endDate', true);
              setFieldError('dateRangeFilter.endDate', t('Please select an end date.'));
            }
          }, [isErrorDateRangeFilter, setFieldTouched, setFieldError, t]);
          return (
            <Form>
              <Row className="align-items-center mt-3 pe-3 ps-6 py-2">
                <Col sm={3} className='text-start'>
                  <span className="fs-7 fw-bold">{t("Date")}</span>
                  <span className="text-danger ms-1">*</span>
                </Col>
                <Col sm={9}>
                  <p className="text-start m-0 fs-7">{currentDate}</p>
                </Col>
              </Row>
              <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
                <Col sm={3} className='text-start'>
                  <label
                    className='w-50'
                    htmlFor='addPayment-rangeDate-field'
                    onClick={handleLabelClick}
                  >
                    <span className="fs-7 fw-bold">{t("Period Payments")}</span>
                    <span className="text-danger ms-1">*</span>
                  </label>
                </Col>
                <Col sm={9}>
                  <div>
                    <DateRangeCalendar
                      id='addPayment-rangeDate-field'
                      name="dateRangeFilter"
                      isErrorDateRangeFilter={Boolean(errors.dateRangeFilter && touched.dateRangeFilter)}
                      dateRangeFilter={dateRangeFilter}
                      setDateRangeFilter={(value) => {
                        setDateRangeFilter(value)
                      }}
                      onError={handleDateRangeError}
                      buttonRef={buttonRef}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />
                    {errors.dateRangeFilter && touched.dateRangeFilter ?
                      <div
                        className='invalid-feedback d-block text-start'>{errors.dateRangeFilter.endDate}</div>
                      : isErrorDateRangeFilter ?
                        <div className="fs-7 text-danger mt-1 text-start">
                          <span
                            className='me-1'>{t('There is a payment for the period that overlaps with the introduced one.')}
                          </span>
                          <span className='text-decoration-underline'>
                            <Link to={`/personnel/${currentCompanyId}/payment-history`} className='text-danger'>
                              {t('Go to edit')}
                            </Link>
                          </span>
                        </div>
                        : null
                    }
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 pe-3 py-2 align-items-center ps-6">
                <Col sm={3} className='text-start'>
                  <label htmlFor='employeeId'>
                    <span className="fs-7 fw-bold text-start">{t("Employee")}</span>
                    <span className="text-danger ms-1">*</span>
                  </label>
                </Col>
                <Col sm={9} className="text-start">
                  <ReactSelect
                    name='employeeId'
                    id='addPayment-employeeId-field'
                    value={selectedEmployees}
                    options={employeesOptions}
                    placeholder={t('employee')}
                    onChange={(option) => handleEmployeesChange(option, setFieldValue)}
                    isSearchable={true}
                  />
                  <ErrorMessage name='employeeId'>
                    {msg => <div className='invalid-feedback d-block'>{msg}</div>}
                  </ErrorMessage>
                </Col>
              </Row>
              <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
                <Col sm={3} className='text-start'>
                  <span className="fs-7 fw-bold">{t("Services performed")}</span>
                </Col>
                <Col sm={9}>
                  {salaryDataLoading
                    ?
                    <div className='preloader__spinner'
                         style={{width: '25px', height: '25px'}}></div>
                    : <p className="text-start m-0">{quantity}</p>
                  }
                </Col>
              </Row>
              <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
                <Col sm={3} className='text-start'>
                  <span className="fs-7 fw-bold">{t("Payment amount")}</span>
                </Col>
                <Col sm={9}>
                  {salaryDataLoading
                    ?
                    <div className='preloader__spinner'
                         style={{width: '25px', height: '25px'}}></div>
                    :
                    <p className="text-start m-0">{sum}<span className='ms-1'>{currencySing}</span>
                    </p>
                  }
                </Col>
              </Row>
              <Row className="mt-3 pe-3 py-2 ps-6">
                <TextareaInputWithCounter
                  id='addPayment-comment-field'
                  name='comment'
                  maxLength={200}
                  labelCol={3}
                  inputCol={9}
                  placeholder={t('Add Comment')}
                  label={t('Comment')}
                />
              </Row>
              <Row className="w-100 m-0 mt-4">
                <Col lg={6} className='offset-lg-6 d-flex justify-content-end gap-2 px-0 ps-lg-4'>
                  <Col xs={6} lg={6}>
                    <Button
                      onClick={() => {
                        handleReset();
                        handleModalClose();
                      }}
                      variant="outline-primary" type="button" disabled={isSubmitting}
                      className="w-100 fw-normal"
                    >
                      {t("Cancel")}
                    </Button>
                  </Col>
                  <Col xs={6} lg={6}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-100"
                    >
                      {t("create")}
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>

      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default AddPaymentForm;
