import {TFunction} from 'i18next';
import * as Yup from 'yup';
import moment from 'moment/moment';

export const addPaymentFormValidationSchema = (t: TFunction) => Yup.object().shape({
  dateRangeFilter: Yup.object().shape({
    startDate: Yup.string()
      .nullable()
      .required(t('validation.required')),
    endDate: Yup.string()
      .nullable()
      .required(t('validation.required')),
  }),
  employeeId: Yup.number().required(t('validation.required')),
});

export   const calculateTotal = (data: any): number => {
  if (!Array.isArray(data)) return 0;
  const total = data.reduce((acc: number, current: any) => {
    let bookingCost = 0;
    if (current?.bookingSalary) {
      if (current?.bookingSalary?.salaryType === 1) {
        bookingCost = current.price * current.bookingSalary.salaryValue;
      } else {
        bookingCost = current.bookingSalary.salaryValue;
      }
    }
    return acc + bookingCost;
  }, 0);

  return Number(total.toFixed(2));
};

export const formatDateInString = (date: Date): string => {
  return moment(date).format('YYYY-MM-DD');
};