import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import {Col} from 'react-bootstrap';
import {ErrorMessage, Field, useField, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currencySingVar} from '@me-team/host/src/apollo/globalVar/state';
import { useGetCurrencyQuery } from '@me-team/host/main/services/graphql/services.hooks';

interface PriceInputProps {
  name: string;
  placeholder: string;
  initialValue?: number;
  id: string;
}

const PriceInput: React.FC<PriceInputProps> = ({
                                                 name,
                                                 placeholder,
                                                 initialValue,
                                                 id
                                               }) => {
  const {t} = useTranslation();
  const currencySing = useReactiveVar(currencySingVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const initialValuePrice = initialValue ? initialValue.toString() : ''
  const {setFieldValue, setFieldTouched, validateField} = useFormikContext();
  const [field, meta] = useField(name);
  const [price, setPrice] = useState(initialValuePrice);

  const handlePriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.target.value.trim();
    const regex = /^(?!0\d)\d{0,6}(\.\d{0,2})?$/;
    if (regex.test(value) || value === '') {
      setPrice(value);
    }
  };

  const handleBlurPrice = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value === "") {
      setPrice('');
      setFieldTouched('price', true, true)
      setFieldValue('price', null)
    } else {
      const numericPrice = parseFloat(value);

      if (!isNaN(numericPrice)) {
        const formattedPrice = numericPrice.toFixed(2);
        setPrice(formattedPrice);
        setFieldValue('price', parseFloat(formattedPrice));
      } else {
        setPrice('');
        setFieldValue('price', null);
      }
    }
  }
  return (
    <>
      <Col
        xs={2}
        className='mb-2 mb-md-0'>
        <label
          htmlFor={id}
          className='text-dark fs-7 fw-bold pt-3'>
          {t('Price')}&nbsp;<span className='text-danger'>*</span>
        </label>
      </Col>
      <Col sm={12} md={3}>
        <div className='position-relative'>
          <Field
            name={name}
            type='text'
            id={id}
            placeholder={placeholder}
            value={price !== null ? price.toString() : ''}
            className={`form-control ${meta.touched && meta.error ? 'is-invalid' : ''}`}
            onBlur={handleBlurPrice}
            onChange={handlePriceChange}
          />
          <i className={`position-absolute end-0 top-50 translate-middle-y text-dark me-3 mt-1 me-2 pe-3'
             ${meta.error ? 'd-none' : ''}`}>
            {currencySing}
          </i>
          <ErrorMessage name={name}>
            {msg => <div className='invalid-feedback'>{msg}</div>}
          </ErrorMessage>
        </div>
      </Col>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default PriceInput;
