import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { StepFunctions } from "../joyrideSteps";
import { useReactiveVar } from "@apollo/client";
import { currentBranchIdVar } from "@me-team/host/src/apollo/globalVar/state";
import {useTranslation} from "react-i18next";

interface StartStepProps {
    setShowStartStep: React.Dispatch<React.SetStateAction<boolean>>;
}

const StartStep: React.FC<StartStepProps & StepFunctions> = ({ setShowStartStep, goToNextStep, goToStep }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const handleBackClick = () => {
        localStorage.setItem('run', 'false');
        localStorage.removeItem('step');
        navigate(`/branches/main/branch/${currentBranchId}`);
    };
    const closeStartStep = () => setShowStartStep(false)
    return (
        <div>
            <div>
                <Row className="border-bottom border-secondary border-2">
                    <Col xs={1} className="p-0">
                        <Button variant='link' className="pt-0" onClick={closeStartStep}>
                            <i className="bi bi-arrow-left"></i>
                        </Button>
                    </Col>
                    <Col xs={11}>
                        <span className="fs-normal fw-bold fs-7 pb-2">{t('Want to learn on your own?')}</span>
                        <p className='fs-7'>{t('Save your time by taking a quick online training course')}</p>
                    </Col>
                </Row>
            </div>
            <div className="mt-3">
                <Button onClick={closeStartStep} className="w-100">{t('Continue training')}</Button>
                <Button variant="link" className="w-100 pb-0" onClick={handleBackClick}>{t('Skip training')}</Button>
            </div>
        </div>
    );
};

export default StartStep;
