import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {CategoryPickerComponent} from "@me-pos/category-picker";
import CategoryService from "../../../services/serviceCategory/ServicesCategoryNewInnit";
import {RequestInputService} from "@me-pos/service-modal";
import { ServiceCategoryNewInput, ServiceCategoryNewProxy } from '@me-team/host/main/graphql/types';

type MoveModalComponentProps = {
    isMoveModalOpen : boolean
    onCloseMoveModal : () => void
    moveCategoryHandler : (inputForReq : ServiceCategoryNewInput) => void
    currentCategory : ServiceCategoryNewProxy
    categoryList :  ServiceCategoryNewProxy[]
}


export const MoveModalComponent : React.FC<MoveModalComponentProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(props.isMoveModalOpen);
    const [currentCategoryId, setCurrentCategoryId] =
        useState<number | null>(props.currentCategory.parentCategory ? props.currentCategory.parentCategory.id : props.currentCategory.id);
    const [currentSubcategoryId, setCurrentSubcategoryId] =
        useState<number | null>(props.currentCategory.parentCategory ? props.currentCategory.id : null)
    const [pickedCategoryId, setPickedCategoryId] = useState<number>(props.currentCategory.id);
    const {t: translate, i18n} = useTranslation();

    useEffect(() => {
        setIsModalOpen(props.isMoveModalOpen)
    }, [props]);

    const moveCategoryHandler = () => {
        if (pickedCategoryId === props.currentCategory.id || pickedCategoryId === props.currentCategory.parentCategory?.id) {
            props.onCloseMoveModal()
            return
        }

        let inputForReq : ServiceCategoryNewInput;

        if (props.currentCategory.parentCategory) {
            if (pickedCategoryId !== props.currentCategory.parentCategory.id) {
                inputForReq = RequestInputService.createServiceCategoryInput(props.currentCategory.parentCategory.id, props.currentCategory.id, props.categoryList);
                inputForReq.parentCategoryId = pickedCategoryId;
                props.moveCategoryHandler(inputForReq)
            }
        } else {
            if (pickedCategoryId !== props.currentCategory.id) {
                inputForReq = RequestInputService.createServiceCategoryInput(props.currentCategory.id, null, props.categoryList);
                if (inputForReq.parentCategoryId !== pickedCategoryId) {
                    inputForReq.parentCategoryId = pickedCategoryId;
                    props.moveCategoryHandler(inputForReq)
                } else {
                    props.onCloseMoveModal()
                }
            }
        }

    }

    return (
        <Modal show={isModalOpen}
               onHide={props.onCloseMoveModal}
               aria-labelledby="contained-modal-title-vcenter"
               centered>
            <Modal.Header closeButton className={"border-bottom-0"}>
                <Modal.Title>
                    <h5 className={"mb-0"}>{translate('servicesPage.moveModal.title')} {props.currentCategory.name}</h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CategoryPickerComponent serviceCategories={CategoryService.categoryModuleCreator(props.categoryList,
                    currentCategoryId,
                    currentSubcategoryId)}
                                         onSearchChange={() => {}}
                                         onChangeCategory={(currentCategory) => {
                                            if (currentCategory.isPicked) {
                                                setPickedCategoryId(currentCategory.id);
                                                setCurrentCategoryId(currentCategory.id);
                                                setCurrentSubcategoryId(null)
                                            } else {
                                                setPickedCategoryId(null);
                                                setCurrentCategoryId(null);
                                                setCurrentSubcategoryId(null)
                                            }
                                         }}
                                         onChangeSubcategory={(pikedSubcategory, currentCategory) => {
                                            if (pikedSubcategory.isPicked) {
                                                setPickedCategoryId(currentCategory.id);
                                                setCurrentCategoryId(currentCategory.id)
                                                setCurrentSubcategoryId(pikedSubcategory.id);
                                            } else {
                                                setPickedCategoryId(null);
                                                setCurrentSubcategoryId(null);
                                                setCurrentCategoryId(null);
                                            }
                                         }}
                                         locale={i18n.language}
                                         isOpen={true}
                />
            </Modal.Body>
            <Modal.Footer className={"border-top-0"}>
                <Button variant="primary" className={"fw-normal col-4"} onClick={moveCategoryHandler}>
                    {translate('servicesPage.moveModal.moveBtn')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
