import React, { useEffect, useState, useMemo} from 'react';
import { Form } from 'react-bootstrap';
import { ReactSelect, TooltipPopover } from '@services/ui-components';
import { useTranslation } from 'react-i18next';

interface SalaryComponentProps {
    service?: any;
    updateSalaryValue: (newValue: number | undefined) => void;
    updateSalaryType: (newType: string) => void;
    currencySign: string;
    salaryError?: boolean;
    salary?: number;
    currType?: string;
}

const SalaryComponent: React.FC<SalaryComponentProps> = ({
                                                             updateSalaryValue,
                                                             updateSalaryType,
                                                             currencySign,
                                                             salaryError,
                                                             salary,
                                                             currType,
                                                         }) => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | null>(null);
    const [inputValue, setInputValue] = useState<string>(
        salary !== undefined && !isNaN(salary) ? salary.toString() : ''
    );

    useEffect(() => {
        if (salary !== undefined && !isNaN(salary)) {
            setInputValue(salary.toString());
        } else {
            setInputValue('');
        }
    }, [salary]);

    const options = [
        { value: '%', label: '%' },
        { value: currencySign, label: currencySign },
    ];

    const getSelectedOption = useMemo(() => {
        return options.find((option) => option.value === currType) || null;
    }, [currType, options]);

    const handleSalaryTypeChange = (selectedOption: any) => {
        updateSalaryType(selectedOption.value);
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let inputVal = e.target.value;
        inputVal = inputVal.replace(',', '.');
        if (inputVal.startsWith('0') && inputVal.length > 1 && !inputVal.includes('.')) {
            inputVal = inputVal.replace(/^0+/, '');
        }
        const validFormat = /^\d{0,6}(\.\d{0,2})?$/;
        if (validFormat.test(inputVal)) {
            setInputValue(inputVal);
            if (inputVal === '') {
                updateSalaryValue(undefined);
                setError(null);
            } else {
                const inputNumber = parseFloat(inputVal);
                if (!isNaN(inputNumber)) {
                    updateSalaryValue(inputNumber);
                    setError(null);
                }
            }
        }
    };

    return (
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
            <TooltipPopover
                placement="bottom"
                text={
                    <div className="d-flex align-items-center">
                        <i className="bi bi-exclamation-square-fill text-orange me-2 fs-5 z-4"></i>
                        <span className="fs-7 text-dark">{error}</span>
                    </div>
                }
                styleBdy="py-1 px-2"
                show={!!error}
                maxWidth="500px"
                trigger="click"
            >
                <Form.Group>
                    <Form.Control
                        type="text"
                        onChange={handlePriceChange}
                        placeholder={currType === '%' ? '0' : '0.00'}
                        value={inputValue}
                        style={{
                            maxWidth: '88px',
                            border: error ? '1px solid red' : '',
                        }}
                        isInvalid={salaryError}
                        id={'Salary'}
                    />
                </Form.Group>
            </TooltipPopover>
            <div style={{ paddingLeft: '16px' }}>
                <ReactSelect
                    options={options}
                    placeholder={currType || ' '}
                    isSearchable={false}
                    onChange={handleSalaryTypeChange}
                    name={'Salary'}
                    value={getSelectedOption}
                />
            </div>
        </div>
    );
};

export default SalaryComponent;
