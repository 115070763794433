import React from 'react';

type spinnerProps = {
    heightContent? : boolean
}

const Spinner: React.FC<spinnerProps> = (props) => {
  return (
    <div className={`d-flex align-items-center justify-content-center bg-white ${props.heightContent ? '' : 'vh-100'}`}>
      <div className='preloader__spinner'></div>
    </div>
  );
};

export default Spinner;