import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import {CombinedJobInterface} from "../../interfaces/interfaces";
import LoadingStatusService from "../../services/loadingStatus/LoadingStatusService";
import styles from "./LoadingStatusStyles.module.scss"
import {useTranslation} from "react-i18next";
import {JobItem} from "./JobItem/JobItem";
import Collapse from 'react-bootstrap/Collapse';
import { JobContainerProxy } from '@me-team/host/main/graphql/types';

type LoadingStatusProps = {
    jobList: JobContainerProxy
    isOpen : boolean
    isVisible : boolean
    onClose : () => void
}


export const LoadingStatus: React.FC<LoadingStatusProps> = (props) => {
    const {t: translate} = useTranslation();
    const [isStatusBlockOpen, setIsStatusBlockOpen] =
        useState<boolean>(props.isOpen);
    const [isVisible, setIsVisible] = useState<boolean>(props.isVisible)
    const [combinedJob, setCombinedJob] =
        useState<CombinedJobInterface[]>(null);

    useEffect(() => {
        setCombinedJob(LoadingStatusService.combinedAndSortedJobService(props.jobList));
    }, [props.jobList]);

    useEffect(() => {
        setIsStatusBlockOpen(props.isOpen);
        setIsVisible(props.isVisible)
    }, [props.isOpen, props.isVisible]);


    const toggleStatusBlockHandler = () => {
        setIsStatusBlockOpen(!isStatusBlockOpen)
    }

    const hideLoadingStatusHandler = () => {
        setIsVisible(false);
        props.onClose();
    }

    return (
        (combinedJob && isVisible) &&
        <div className={`shadow rounded rounded-bottom-0 bg-white`}>
            <div className={"text-dark p-3 d-flex justify-content-between align-items-center"}>
                <h5 className={"mb-0"}>{translate('loadingStatus')}</h5>
                <div className={"d-flex align-items-center"}>
                    <Button className={"bg-transparent p-0 text-dark border-0 me-3 fs-normal"}
                            onClick={toggleStatusBlockHandler}
                            aria-controls="loadingStatus"
                            aria-expanded={isStatusBlockOpen}>
                        <i className={`bi bi-chevron-up d-flex ${isStatusBlockOpen ? 'rotated' : ''}`}></i>
                    </Button>
                    <Button className={"bg-transparent p-0 text-dark border-0 fs-normal"} onClick={hideLoadingStatusHandler}>
                        <i className="bi bi-x-lg d-flex"></i>
                    </Button>
                </div>
            </div>

            <div>
                <Collapse in={isStatusBlockOpen} className={`${styles.statusBlockOpen} pb-4 ${combinedJob.length > 4 ? 'overflow-y-auto' : ''} scrollbar`}>
                    <div id="loadingStatus">
                        {combinedJob.map((job : CombinedJobInterface, index : number) => (
                            <JobItem job={job} key={index}/>
                        ))}
                    </div>
                </Collapse>
            </div>

        </div>
    )
}
