/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetClientsDocument = gql`
    query GetClients($page: Int, $itemsPerPage: Int, $search: String, $amountOfVisits: String, $filterByCompany: Boolean, $completedBookings: Boolean) {
  user {
    company {
      clients(
        page: $page
        itemsPerPage: $itemsPerPage
        amountOfVisits: $amountOfVisits
        search: $search
      ) {
        currentPage
        itemsPerPage
        pageCount
        totalCount
        clients {
          id
          name
          surname
          phone
          email
          image
          bookings(
            completedBookings: $completedBookings
            filterByCompany: $filterByCompany
          ) {
            bookings {
              id
              status {
                id
              }
              client {
                id
              }
              date
              price
              uuid
              needClientRecall
              technologicalMaps {
                name
                id
              }
            }
            totalCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      search: // value for 'search'
 *      amountOfVisits: // value for 'amountOfVisits'
 *      filterByCompany: // value for 'filterByCompany'
 *      completedBookings: // value for 'completedBookings'
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export function useGetClientsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsSuspenseQueryHookResult = ReturnType<typeof useGetClientsSuspenseQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const GetOneClientDocument = gql`
    query GetOneClient($id: Int) {
  user {
    company {
      clients(id: $id) {
        clients {
          id
          name
          comment
          dateOfBirth
          email
          gender {
            id
            name
          }
          image
          patronymic
          phone
          surname
          bookings(clientInputType: {id: $id}, filterByCompany: true) {
            bookings {
              date
              price
            }
            totalCount
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetOneClientQuery__
 *
 * To run a query within a React component, call `useGetOneClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOneClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOneClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOneClientQuery(baseOptions?: Apollo.QueryHookOptions<GetOneClientQuery, GetOneClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOneClientQuery, GetOneClientQueryVariables>(GetOneClientDocument, options);
      }
export function useGetOneClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOneClientQuery, GetOneClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOneClientQuery, GetOneClientQueryVariables>(GetOneClientDocument, options);
        }
export function useGetOneClientSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOneClientQuery, GetOneClientQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOneClientQuery, GetOneClientQueryVariables>(GetOneClientDocument, options);
        }
export type GetOneClientQueryHookResult = ReturnType<typeof useGetOneClientQuery>;
export type GetOneClientLazyQueryHookResult = ReturnType<typeof useGetOneClientLazyQuery>;
export type GetOneClientSuspenseQueryHookResult = ReturnType<typeof useGetOneClientSuspenseQuery>;
export type GetOneClientQueryResult = Apollo.QueryResult<GetOneClientQuery, GetOneClientQueryVariables>;
export const DeleteClientDocument = gql`
    mutation DeleteClient($id: Int!) {
  deleteClient(id: $id)
}
    `;
export type DeleteClientMutationFn = Apollo.MutationFunction<DeleteClientMutation, DeleteClientMutationVariables>;

/**
 * __useDeleteClientMutation__
 *
 * To run a mutation, you first call `useDeleteClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientMutation, { data, loading, error }] = useDeleteClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClientMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientMutation, DeleteClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientMutation, DeleteClientMutationVariables>(DeleteClientDocument, options);
      }
export type DeleteClientMutationHookResult = ReturnType<typeof useDeleteClientMutation>;
export type DeleteClientMutationResult = Apollo.MutationResult<DeleteClientMutation>;
export type DeleteClientMutationOptions = Apollo.BaseMutationOptions<DeleteClientMutation, DeleteClientMutationVariables>;
export const GetGenderDocument = gql`
    query getGender {
  gender {
    id
    name
  }
}
    `;

/**
 * __useGetGenderQuery__
 *
 * To run a query within a React component, call `useGetGenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenderQuery(baseOptions?: Apollo.QueryHookOptions<GetGenderQuery, GetGenderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenderQuery, GetGenderQueryVariables>(GetGenderDocument, options);
      }
export function useGetGenderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenderQuery, GetGenderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenderQuery, GetGenderQueryVariables>(GetGenderDocument, options);
        }
export function useGetGenderSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetGenderQuery, GetGenderQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGenderQuery, GetGenderQueryVariables>(GetGenderDocument, options);
        }
export type GetGenderQueryHookResult = ReturnType<typeof useGetGenderQuery>;
export type GetGenderLazyQueryHookResult = ReturnType<typeof useGetGenderLazyQuery>;
export type GetGenderSuspenseQueryHookResult = ReturnType<typeof useGetGenderSuspenseQuery>;
export type GetGenderQueryResult = Apollo.QueryResult<GetGenderQuery, GetGenderQueryVariables>;
export const CreateClientDocument = gql`
    mutation CreateClient($companyId: Int!, $input: ClientInput!) {
  createClient(companyId: $companyId, input: $input) {
    id
    name
    surname
    patronymic
    dateOfBirth
    email
    phone
    gender {
      id
      name
    }
    category
    discount
    comment
    image
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation UpdateClient($id: Int!, $input: ClientInput!) {
  updateClient(id: $id, input: $input) {
    id
    name
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const UpdateCompanyModuleRelationDocument = gql`
    mutation UpdateCompanyModuleRelation($companyId: Int!, $input: ModuleInputType!) {
  updateCompanyModuleRelation(companyId: $companyId, input: $input) {
    id
    name
    bookingQrLink
    address
    deleted
    phone
  }
}
    `;
export type UpdateCompanyModuleRelationMutationFn = Apollo.MutationFunction<UpdateCompanyModuleRelationMutation, UpdateCompanyModuleRelationMutationVariables>;

/**
 * __useUpdateCompanyModuleRelationMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyModuleRelationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyModuleRelationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyModuleRelationMutation, { data, loading, error }] = useUpdateCompanyModuleRelationMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyModuleRelationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyModuleRelationMutation, UpdateCompanyModuleRelationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyModuleRelationMutation, UpdateCompanyModuleRelationMutationVariables>(UpdateCompanyModuleRelationDocument, options);
      }
export type UpdateCompanyModuleRelationMutationHookResult = ReturnType<typeof useUpdateCompanyModuleRelationMutation>;
export type UpdateCompanyModuleRelationMutationResult = Apollo.MutationResult<UpdateCompanyModuleRelationMutation>;
export type UpdateCompanyModuleRelationMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyModuleRelationMutation, UpdateCompanyModuleRelationMutationVariables>;
export type GetClientsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
  amountOfVisits?: Types.InputMaybe<Types.Scalars['String']['input']>;
  filterByCompany?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  completedBookings?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type GetClientsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', clients?: { __typename?: 'ClientPaginated', currentPage?: number | null, itemsPerPage?: number | null, pageCount?: string | null, totalCount?: number | null, clients?: Array<{ __typename?: 'ClientProxy', id: number, name?: string | null, surname?: string | null, phone?: string | null, email?: string | null, image?: string | null, bookings?: { __typename?: 'BookingPaginated', totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', id: number, date?: string | null, price?: number | null, uuid: any, needClientRecall: boolean, status?: { __typename?: 'BookingStatusProxy', id: number } | null, client?: { __typename?: 'ClientProxy', id: number } | null, technologicalMaps?: Array<{ __typename?: 'TechnologicalMapProxy', name: string, id: number }> | null }> | null } | null }> | null } | null } | null } | null };

export type GetOneClientQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type GetOneClientQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', company?: { __typename?: 'CompanyProxy', clients?: { __typename?: 'ClientPaginated', clients?: Array<{ __typename?: 'ClientProxy', id: number, name?: string | null, comment?: string | null, dateOfBirth?: string | null, email?: string | null, image?: string | null, patronymic?: string | null, phone?: string | null, surname?: string | null, gender?: { __typename?: 'GenderProxy', id: number, name: string } | null, bookings?: { __typename?: 'BookingPaginated', totalCount?: number | null, bookings?: Array<{ __typename?: 'BookingMainProxy', date?: string | null, price?: number | null }> | null } | null }> | null } | null } | null } | null };

export type DeleteClientMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteClientMutation = { __typename?: 'Mutation', deleteClient: boolean };

export type GetGenderQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetGenderQuery = { __typename?: 'Query', gender: Array<{ __typename?: 'GenderProxy', id: number, name: string }> };

export type CreateClientMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ClientInput;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename?: 'ClientProxy', id: number, name?: string | null, surname?: string | null, patronymic?: string | null, dateOfBirth?: string | null, email?: string | null, phone?: string | null, category?: string | null, discount?: string | null, comment?: string | null, image?: string | null, gender?: { __typename?: 'GenderProxy', id: number, name: string } | null } };

export type UpdateClientMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.ClientInput;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename?: 'ClientProxy', id: number, name?: string | null } };

export type UpdateCompanyModuleRelationMutationVariables = Types.Exact<{
  companyId: Types.Scalars['Int']['input'];
  input: Types.ModuleInputType;
}>;


export type UpdateCompanyModuleRelationMutation = { __typename?: 'Mutation', updateCompanyModuleRelation: { __typename?: 'CompanyProxy', id: number, name: string, bookingQrLink: string, address: string, deleted: boolean, phone: string } };
