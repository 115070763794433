export default class SocketConnection {

    /**
     * @param {WebSocket}
     */
    socketInstance;
    _isOpenConnection = false

    constructor(host, onMessage, onConnect, onDisconnect) {
        this._createConnection(host, onMessage, onConnect, onDisconnect)
    }

    _createConnection(host, onMessage, onConnect, onDisconnect) {
        this.socketInstance = new WebSocket(host);
        this.socketInstance.timeout = 5000000
        // Обработчик события при открытии соединения
        this.socketInstance.addEventListener('open', (event) => {
            this._isOpenConnection = true
            if (onConnect)
                onConnect()
        });

        // Обработчик события при получении сообщения от сервера
        this.socketInstance.addEventListener('message', (event) => {
            const message = JSON.parse(event.data);
            if (onMessage)
                onMessage(message)
        });

        // Обработчик события при закрытии соединения
        this.socketInstance.addEventListener('close', (event) => {
            if (onDisconnect)
                onDisconnect(event)
            this._createConnection(host, onMessage, onConnect, onDisconnect)
        });
    }

    /**
     *
     * @param {string} message
     * @returns {boolean}
     */
    sendMessage = (message) => {
        if (this._isOpenConnection) {
            this.socketInstance.send(message);
            return true
        }
        return false
    }
    /**
     *
     * @param {Object} message
     */
    sendMessageWithObject = (message) => {
        return this.sendMessage(JSON.stringify(message));
    }
}