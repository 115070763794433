import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import {useTranslation} from "react-i18next";
interface CommonQuestionsModalProps {
    setCommonQuestionsModal: (value: boolean) => void;
    commonQuestionsModal: boolean;
}

const CommonQuestionsModal: React.FC<CommonQuestionsModalProps> = ({ setCommonQuestionsModal, commonQuestionsModal }) => {
    const { t } = useTranslation();
    return (
        <Modal show={commonQuestionsModal} onHide={() => setCommonQuestionsModal(false)} centered  >

            <Modal.Header className='border-0' closeButton>
                <h5 className='fw-normal'>{t('Frequently asked questions')}</h5>
            </Modal.Header>
            <Modal.Body className='pt-2 pb-6'>
                <Row>
                    <Col>
                        <p className='fs-7 fw-bold  mb-2'>{t('Why does the branch not appear in the online booking form?')}</p>
                        <ul className=" fs-7 mb-3">
                            <li>{t('Online booking for this company or branch is disabled in the settings.')}</li>
                            <li>{t('There is no work schedule for any of the employees who have online appointment enabled for at least one service.')}</li>
                        </ul>
                        <p className='fs-7 fw-bold  mb-2'>{t('Why is the service not displayed in online booking?')}</p>
                        <ul className=" fs-7  mb-3">
                            <li>{t('Online booking is disabled for this service.')}</li>
                            <li>{t('There are no providers for this service.')}</li>
                            <li>{t('Online booking for this service is turned off for all performers of this service.')}</li>
                            <li>{t('All the artists for this service do not have a work schedule.')}</li>
                        </ul>
                        <p className='fs-7 fw-bold mb-2'>{t('Why is the master not displayed in online booking?')}</p>
                        <ul className=" fs-7 mb-2">
                            <li>{t('Online booking is disabled for this master.')}</li>
                            <li>{t('This master has a work schedule.')}</li>
                            <li>{t('This master has services to perform.')}</li>
                            <li>{t('All services performed by this master have online booking enabled.')}</li>
                        </ul>
                    </Col>
                </Row>


            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Col>
                    <Button variant="primary" className="w-100" onClick={() => setCommonQuestionsModal(false)}>
                        {t('Complete')}
                    </Button>
                </Col>
            </Modal.Footer>

        </Modal>
    );
};

export default CommonQuestionsModal;
