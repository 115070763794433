import React, {ReactNode} from "react";

type ErrorImportFileProps = {
    errorContent : ReactNode
}

export const ErrorImportFile : React.FC<ErrorImportFileProps> = (props) => {
    return (
        <div
            className={"border rounded bg-danger bg-opacity-10 border-danger p-3 mb-4 text-danger d-flex"}>
            <i className="bi bi-exclamation-circle me-2"></i>
            {props.errorContent}
        </div>
    )
}