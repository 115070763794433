import React, {useState} from 'react';
import {CalendarWrapperComponent} from './CalendarWrapperComponent';
import {Spinner} from '@services/ui-components';
import {CalendarFilterI} from './constants';
import {useGetInfoCompanyQuery} from '@me-team/host/main/company/graphql/company.hooks';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import {currentBranchIdVar, currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';

type CalendarWrapperProps = {
  filters: CalendarFilterI,
};

const CalendarWrapper: React.FC<CalendarWrapperProps> = ({filters}) => {
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const currentBranchId = useReactiveVar(currentBranchIdVar);
  const [showPreloader, setShowPreloader] = useState<boolean>(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const {data: infoCompanyData, loading} = useGetInfoCompanyQuery({
    context: {
      errorType: "local",
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })
  const timeZone = infoCompanyData?.user?.company?.timeZone?.name

  const showPreloaderHandler = () => {
    setShowPreloader(true)
  }
  const hidePreloaderHandler = () => {
    setShowPreloader(false);
  }

  const secondComponent = React.useMemo(() => <CalendarWrapperComponent
    currentCompanyId={+currentCompanyId}
    currentBranchId={+currentBranchId}
    filters={filters}
    timeZone={timeZone}
    showPreloaderHandler={showPreloaderHandler}
    hidePreloaderHandler={hidePreloaderHandler}
  />, [
    currentCompanyId,
    currentBranchId,
    JSON.stringify(filters)]);

  return (<>
    {showPreloader &&
        <div className='d-flex align-items-center justify-content-center position-fixed top-50 end-50 z-3 all-unset'>
          <div className='preloader__spinner'></div>
        </div>}
    {secondComponent}
    {isErrorModalOpen ?
        <ErrorModalComponent
            i18n={i18next}
            onClose={() => {
              setIsErrorModalOpen(null)
            }}
            isOpen={!!isErrorModalOpen}
        currentError={isErrorModalOpen}
      /> : null
    }
  </>)
}

export default CalendarWrapper;
