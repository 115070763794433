import {TFunction} from 'i18next';
import * as Yup from 'yup';

export interface Filter {
  employeeId: number,
  reviewType: string | null,
  rating: number | null,
  reviewSortField: string,
  order: string
}

// Prohibition of adding HTML tags and links.
const noHtmlAndLinks = (t: TFunction) => Yup.string()
  .nullable()
  .test('no-html-tags', t('validation.HTML tags are not allowed'), value => !/<\/?[^>]+(>|$)/g.test(value))
  .test('no-links', t('validation.links are not allowed'), value => !/https?:\/\/[^\s]+/g.test(value));

export const createServiceFormValidationSchema = (t: TFunction) => Yup.object().shape({
  rating: Yup.number().required(t('validation.required')),
  name: Yup.string().required(t('validation.required')),
  text: noHtmlAndLinks(t),
});

export const editServiceFormValidationSchema = (t: TFunction) => Yup.object().shape({
  name: Yup.string().required(t('validation.required')),
  text: noHtmlAndLinks(t)
});

export const initialValueFilter: Filter = {
  employeeId: null,
  reviewType: null,
  rating: null,
  reviewSortField: 'createdAt',
  order: "DESC"
}