import React from 'react';
import Select, {
  components,
  ControlProps,
  CSSObjectWithLabel,
  GroupBase,
  OptionProps,
  Props as SelectProps,
  StylesConfig
} from 'react-select';
import {useFormikContext} from 'formik';

interface OptionType {
  label: string;
  value: number | string;
}

interface CheckboxSelectProps {
  name: string;
  options: OptionType[];
  isDisabled?: boolean;
  onChange: (values:any) => void;
  inputId?: string;
}

const CheckboxOption = (props: OptionProps<OptionType>) => {
  return (
    <components.Option {...props}>
      <input
        type="checkbox"
        checked={props.isSelected}
        onChange={() => null}
        onClick={(e) => e.stopPropagation()}
        style={{
          marginRight: '8px',
          accentColor: '#5842BE',
        }}
      />
      {props.label}
    </components.Option>
  );
};

const CheckboxSelect: React.FC<CheckboxSelectProps> = ({
                                                         name,
                                                         options,
                                                         onChange,
                                                         isDisabled,
                                                         inputId
}) => {
  const {values, setFieldValue} = useFormikContext<{ [key: string]: any }>();

  const handleChange = (selectedOptions: OptionType[] | null) => {
    const value = selectedOptions ? selectedOptions.map(option => option?.value) : [];
    onChange && onChange(selectedOptions)
    setFieldValue(name, value);
  };

    const getValue = (): OptionType[] => {
        return options.filter(option => values[name]?.includes(option.value));
    };

  const customStyles:StylesConfig<OptionType, false> = {
    control: (provided: CSSObjectWithLabel, state: ControlProps<OptionType, false, GroupBase<OptionType>>) => ({
      ...provided,
      height: '50px', // Change this value to increase the height
      borderColor: state.isFocused ? '#ACA1DF' : '#DEDEDE',
      padding: "6px",
      borderRadius: '4px',
      minHeight: '100%',
      boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(111, 66, 193, 0.25)' : 'none',
      '&:hover': {
        borderColor: '#ACA1DF',
      },
    }),

    option: (provided: CSSObjectWithLabel, state: OptionProps<OptionType, false, GroupBase<OptionType>>) => ({
      ...provided,
      borderRadius: "4px",
      marginBottom: "8px",
      padding: "4px 12px",
      cursor: "pointer",
      backgroundColor: state.isSelected ? '#EEECF9' : '#FFFFFF',
      color: state.isSelected ? '#1F3354' : '#1F3354',
      '&:hover': {
        backgroundColor: '#EEECF9',
        color: '#1F3354',
      },
    }),

    multiValueLabel: (provided) => ({
      ...provided,
      color: '#5842BE',
      fontSize: '14px'
    }),

    multiValueRemove: (provided) => ({
      ...provided,
      color: '#5842BE',
      cursor: 'pointer',
    }),

    input: (provided) => ({
      ...provided,
      fontSize: "14px",
      color: "#1F3354",
    }),

    dropdownIndicator: (provided: CSSObjectWithLabel, {selectProps}) => ({
      ...provided,
      transform: selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      transition: 'transform 0.1s ease',
    }),

    menu: (provided) => ({
      ...provided,
      marginTop: "4px",
    }),

    menuList: (provided) => ({
      ...provided,
      padding: '8px 12px',
    }),
  };

  const selectProps: SelectProps<OptionType> = {
    components: {Option: CheckboxOption},
    options: options,
    isMulti: true,
    closeMenuOnSelect: false,
    hideSelectedOptions: false,
    onChange: handleChange as any,
    value: getValue(),
    isDisabled,
    styles: customStyles,
    id: inputId
  };

    return <Select {...selectProps} />;
};

export default CheckboxSelect;
