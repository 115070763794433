import {createBrowserRouter} from 'react-router-dom';
import React from 'react';
import {
    AddEmployee,
    Branch,
    Clients,
    ClientsHistoryVisit,
    CreateBranch,
    CreateClient,
    CreateService,
    CurrentBranch,
    EditingEmployee,
    EmployeesList,
    HistoryChangeAppointment,
    MainMenu,
    MenuItems,
    OnlineBookingSettings,
    OwnerCreation,
    PaymentHistory,
    PaymentHistoryAndManagement,
    Positions,
    RecoveryService,
    Reviews,
    Salary,
    Services,
    TariffPlans,
    UpdateClient,
    UpdateCompany,
    UpdateService,
    EmployeeServices,
    ServiceProviders,
    MainMenuBeautySalon, Logbook,
    WorkSchedule
} from '../components';
import CreateCompany from '../components/CreateCompany/CreateCompany';
import {AddRecord} from "../components/OnlineRegistration/AddRecord/AddRecord";
import Page500 from './errorPages/Page500';
import WithDocumentTitle from './withDocumentTitle';
import {ServiceCategories} from '../components/Services/ServiceCategories/ServiceCategories';
import ProtectedRegistrationRoute from '../components/CreateCompany/ProtectedRegistrationRoute';
import ProtectedRoute from './ProtectedRoute';
import { UserRole } from '../utils/enums';
import Page403 from './errorPages/Page403';

const commonRoutes = [
    {
        path: "branches",
        element: React.createElement(WithDocumentTitle(Branch, 'Branches')),
    },
    {
        path: 'create-branch',
        element: React.createElement(WithDocumentTitle(CreateBranch, 'Branch')),
    },
    {
        path: "branch/:id",
        element: <CurrentBranch/>,
    },
    {
        path: "branch/menu/:id",
        element: React.createElement(WithDocumentTitle(MainMenu, 'menu')),
    },
    {
        path: "branch/menu/items",
        element: React.createElement(WithDocumentTitle(MenuItems, 'List of dishes')),
    },
    {
        path: "clients-history-visit",
        element: React.createElement(WithDocumentTitle(ClientsHistoryVisit, 'History of client visits')),
    },
    {
        path: "service-categories",
        element: React.createElement(WithDocumentTitle(ServiceCategories, 'Service Categories')),
    },
    {
        path: "/registration",
        element:  <ProtectedRegistrationRoute
            element={WithDocumentTitle(CreateCompany, 'Create company')}
        />,
    },
    {
        path: 'employees-list',
        element: React.createElement(WithDocumentTitle(EmployeesList, 'List of employees')),
    },
    {
        path: 'employees-list/:position',
        element: React.createElement(WithDocumentTitle(EmployeesList, 'List of employees')),
    },
    {
        path: 'employees-list/employee/:userId',
        element: React.createElement(WithDocumentTitle(EmployeesList, 'List of employees')),
    },
    {
        path: 'employees-list/add-employee',
        element: React.createElement(WithDocumentTitle(AddEmployee, 'Add an employee')),
    },
    {
        path: 'employees-list/edit-employee/:id',
        element: React.createElement(WithDocumentTitle(EditingEmployee, 'Edit')),
    },
    {
        path: 'create-owner',
        element: React.createElement(WithDocumentTitle(OwnerCreation, 'Add owner')),
    },
    {
        path: 'positions',
        element: React.createElement(WithDocumentTitle(Positions, 'Positions')),
    },
    {
        path: '/logbook/edit-record/:bookingId/change-history',
        element: React.createElement(WithDocumentTitle(HistoryChangeAppointment, 'History of Changes')),
    },
    {
        path: 'error/500',
        element: React.createElement(WithDocumentTitle(Page500, 'Server error')),
    },
    {
        path: 'error/403',
        element: React.createElement(WithDocumentTitle(Page403, 'Access denied')),
    },
    {
        path: 'services/service/:currentCompanyId/service-list',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(Services, 'Service list'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'service-categories/:currentCompanyId/service-list/categoryId/:categoryId',
        element: (
            <ProtectedRoute>
                {React.createElement(WithDocumentTitle(Services, 'Service list'))}
            </ProtectedRoute>
        )
    },
    {
        path: 'service-categories/:currentCompanyId/service-list/subCategoryId/:subCategoryId',
        element: (
            <ProtectedRoute>
                {React.createElement(WithDocumentTitle(Services, 'Service list'))}
            </ProtectedRoute>
        )
    },
    {
        path: 'services/service/:currentCompanyId/new',
        element: (
          <ProtectedRoute restrictedRoles={[UserRole.EMPLOYEE]}>
              {React.createElement(WithDocumentTitle(CreateService, 'Add Service'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'services/service/:currentCompanyId/service-list/:id/edit',
        element: (
          <ProtectedRoute restrictedRoles={[UserRole.EMPLOYEE]}>
              {React.createElement(WithDocumentTitle(UpdateService, 'Editing Service'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'services/service/:currentCompanyId/service-list/:id/restore',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(RecoveryService, 'Recovery Service'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'clients/:currentCompanyId/list',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(Clients, 'Client list'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'clients/:currentCompanyId/list/:id/edit',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(UpdateClient, 'Editing'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'clients/:currentCompanyId/new',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(CreateClient, 'New Client'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'booking/review/company/:currentCompanyId',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(Reviews, 'List of reviews'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'booking/review/company/:currentCompanyId/:userId',
        element: (
            <ProtectedRoute>
                {React.createElement(WithDocumentTitle(Reviews, 'List of reviews'))}
            </ProtectedRoute>
        )
    },
    {
        path: 'company/:currentCompanyId/edit',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(UpdateCompany, 'Company'))}
          </ProtectedRoute>
        )
    },
    {
        path: "personnel/:currentCompanyId/salary",
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(Salary, 'Salary'))}
          </ProtectedRoute>
        )
    },
    {
        path: "personnel/:currentCompanyId/payment-history",
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(PaymentHistory, 'History of Payments'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'payment/company/:currentCompanyId/tariff',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(TariffPlans, 'Tariff Plans'))}
          </ProtectedRoute>
        )
    },
    {
        path: 'payment/history/company/:currentCompanyId',
        element: (
          <ProtectedRoute>
              {React.createElement(WithDocumentTitle(PaymentHistoryAndManagement, 'History and Payment Management'))}
          </ProtectedRoute>
        )
    },
    {
        path: `booking/company/:currentCompanyId/config`,
        element: React.createElement(WithDocumentTitle(OnlineBookingSettings, 'Online Booking Settings')),
    },
    {
        path: `logbook/add-record`,
        element: React.createElement(WithDocumentTitle(AddRecord, 'Add new booking')),
    },
    {
        path: `logbook/edit-record/:bookingId`,
        element: React.createElement(WithDocumentTitle(AddRecord, 'Edit booking')),
    },
    {

        element: <OnlineBookingSettings />,
    },
    {
        path: "employees-list/employee-services/:id",
        element: React.createElement(WithDocumentTitle(EmployeeServices, 'Employee\'s services')),
    },
    {
        path: "services-providers/:id",
        element: React.createElement(WithDocumentTitle(ServiceProviders, 'Service Providers')),
    },
    {
        path: 'branches/main/branch/:id',
        element: React.createElement(WithDocumentTitle(MainMenuBeautySalon, 'Branches')),
    },
    {
        path: 'logbook/journal/:branchId',
        element: React.createElement(WithDocumentTitle(Logbook, 'Logbook Journal')),
    },
    {
        path: 'work-schedule',
        element: React.createElement(WithDocumentTitle(WorkSchedule, 'Staff Work Schedule')),
    },
    {
        path: 'work-schedule/:id',
        element: React.createElement(WithDocumentTitle(WorkSchedule, 'Staff Work Schedule')),
    },
    {
        path: '/:ln/personnel/employee/:br/list/:cd/:id/edit',
        element: React.createElement(WithDocumentTitle(EditingEmployee, 'Edit')),
    },
    {
        path: '/personnel/employee/:br/list/:cd/:id/edit',
        element: React.createElement(WithDocumentTitle(EditingEmployee, 'Edit')),
    }

]

export const router = createBrowserRouter(commonRoutes);

export default commonRoutes;
