import React from "react";
import {Button} from "react-bootstrap";

type ClearValueBtnProps = {
    onClearValue : () => void
}

export const ClearValueBtn : React.FC<ClearValueBtnProps> = (props) => {

    return <div className={'position-absolute top-50 end-0 me-4 pe-4 translate-middle-y'}>
        <Button variant={'link'} className={'text-dark text-decoration-none p-0 d-flex'} onClick={props.onClearValue}>
            <i className="bi bi-x-lg d-flex"></i>
        </Button>
    </div>
}