import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";
import {useTranslation} from "react-i18next";

interface AccessSwitcherStepProps {
    goToNextStep: () => void;
}

const AccessSwitcherStep: React.FC<StepFunctions> = ({ goToNextStep, goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => {
        setShowStartStep(true);
    }

    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={11}>
                            <p className='fs-7'>{t('To be able to create bookings and set up employee working hours,')} <span className="fs-normal fw-bold fs-7">{t('make sure that the access switch is turned on')} </span> </p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className='text-end p-0'>
                            <Button className="px-4 " onClick={() => {
                                const element = document.querySelector('#table');
                                if (element) {
                                    element.scrollLeft = element.scrollWidth - element.clientWidth;
                                }
                                goToStep('next')
                            }}>{t('Next')}</Button>
                        </Col>

                    </Row>
                </>
                )}
        </div>
    )
};

export default AccessSwitcherStep;
