import React, {useEffect, useState} from 'react';
import {Form, Formik, FormikHelpers} from 'formik';
import {Col, Row} from 'react-bootstrap';
import {
  DateRangeCalendar,
  ReactSelect,
  TextareaInputWithCounter
} from '@services/ui-components';
import {useTranslation} from 'react-i18next';
import Button from 'react-bootstrap/Button';
import {ApolloError, useReactiveVar} from '@apollo/client';
import ErrorService from '../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import {convertDateFormat} from '@me-team/host/src/utils/utils';
import {currencySingVar} from '@me-team/host/src/apollo/globalVar/state';
import {
  useGetAllEmployeesQuery,
  useGetOneSalaryPaymentQuery,
  useUpdatePaymentMutation
} from '@me-team/host/main/salary/graphql/salary.hooks';
import { SalaryPaymentInput, SalaryPaymentProxy } from '@me-team/host/main/graphql/types';

interface OptionReactSelect {
  value: string | number | string[];
  label: string;
}

interface EditPaymentFormProps {
  handleModalClose: () => void,
  paymentId: number
}

const EditPaymentForm: React.FC<EditPaymentFormProps> = ({handleModalClose, paymentId}) => {
  const {t} = useTranslation();
  const currencySing = useReactiveVar(currencySingVar);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [updateSalaryPayment] = useUpdatePaymentMutation()

  const {data: oneSalaryPaymentData, loading} = useGetOneSalaryPaymentQuery({
    variables: {
      id: paymentId && paymentId
    },
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  const payment = oneSalaryPaymentData && oneSalaryPaymentData?.user?.company?.salaryPayments?.salaryPayments[0]
  const initialSelectedEmployees: OptionReactSelect = {
    value: payment?.employee?.id,
    label: payment?.employee?.name,
  }
  const [selectedEmployees, setSelectedEmployees] = useState(initialSelectedEmployees);

  useEffect(() => {
    if (oneSalaryPaymentData) {
      setSelectedEmployees({
        value: payment?.employee?.id,
        label: payment?.employee?.name
      });
    }
  }, [oneSalaryPaymentData]);

  const {data: employeesData} = useGetAllEmployeesQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })

  useEffect(() => {
    if (oneSalaryPaymentData) {
      setDateRangeFilter({
        startDate: payment?.periodStart,
        endDate: payment?.periodEnd,
      });
    }
  }, [oneSalaryPaymentData]);

  const initialFiltersRange = {
    startDate: payment?.periodStart && payment?.periodStart,
    endDate: payment?.periodEnd && payment?.periodEnd
  }

  const [dateRangeFilter, setDateRangeFilter] = useState(initialFiltersRange)

  const employeesOptions = employeesData?.user?.company?.employees?.employees?.map((employee) => {
    return {
      value: employee?.id,
      label: `${employee?.name} ${employee?.surname}`
    }
  }) || [];

  const handleEmployeesChange = (selectedOption: OptionReactSelect, setFieldValue: any) => {
    setSelectedEmployees(selectedOption);
    setFieldValue('employeeId', selectedOption?.value)
  };

  const initialValues = {
    date: payment?.createdAt && convertDateFormat(payment?.createdAt, 'dd.LL.yyyy'),
    employeeId: selectedEmployees?.value ? selectedEmployees?.value : null,
    quantity: oneSalaryPaymentData?.user?.company?.salaryPayments?.salaryPayments?.length,
    sum: payment?.paymentValue,
    comment: payment?.comment ? payment?.comment : null
  }

  const handleEditPaymentSubmit = (
    values: SalaryPaymentProxy,
    {setSubmitting}: FormikHelpers<any>) => {
    const input: SalaryPaymentInput = {
      periodStart: dateRangeFilter?.startDate && dateRangeFilter?.startDate,
      periodEnd: dateRangeFilter?.startDate && dateRangeFilter?.endDate,
      comment: values?.comment ? values?.comment : ''
    }
    updateSalaryPayment({
      variables: {
        employeeId: payment?.employee?.id && payment?.employee?.id,
        salaryPaymentId: payment?.id && payment?.id,
        input
      },
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        data && handleModalClose();
        setSubmitting(false);

      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
        setSubmitting(false);
      }
    })
  }

  if (loading) return <Spinner/>;

  return (
    <>
      <Formik
        initialValues={initialValues}
        // validationSchema={addPaymentFormValidationSchema(t)}
        onSubmit={handleEditPaymentSubmit}
      >
        {({isSubmitting, setFieldValue, initialValues, isValid, dirty, handleReset}) => (
          <Form>
            <Row className="align-items-center mt-3 pe-3 ps-6 py-2">
              <Col sm={3} className='text-start'>
                <span className="fs-7 fw-bold">{t("Date")}</span>
                <span className="text-danger ms-1">*</span>
              </Col>
              <Col sm={9}>
                <p className="text-start m-0 fs-7"> {initialValues.date}</p>
              </Col>
            </Row>
            <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
              <Col sm={3} className='text-start'>
                <label className='w-50' htmlFor='dateRangeFilter'>
                  <span className="fs-7 fw-bold">{t("Period Payments")}</span>
                  <span className="text-danger ms-1">*</span>
                </label>
              </Col>
              <Col sm={9}>
                <DateRangeCalendar
                  name="dateRangeFilter"
                  dateRangeFilter={dateRangeFilter}
                  setDateRangeFilter={setDateRangeFilter}
                  id='editPayment-dateRange-field'
                />
              </Col>
            </Row>
            <Row className="mt-3 pe-3 py-2 align-items-center ps-6">
              <Col sm={3} className='text-start'>
                <label htmlFor="employeeId">
                  <span className="fs-7 fw-bold text-start">{t("Employee")}</span>
                  <span className="text-danger ms-1">*</span>
                </label>
              </Col>
              <Col sm={9} className="text-start">
                <ReactSelect
                  name='employeeId'
                  id='editPayment-employeeId-field'
                  value={selectedEmployees}
                  options={employeesOptions}
                  placeholder={t('employee')}
                  onChange={(option) => handleEmployeesChange(option, setFieldValue)}
                  isSearchable={true}
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
              <Col sm={3} className='text-start'>
                <span className="fs-7 fw-bold">{t("Services performed")}</span>
              </Col>
              <Col sm={9}>
                <p className="text-start m-0">{initialValues.quantity}</p>
              </Col>
            </Row>
            <Row className="align-items-center mt-3 pe-3 py-2 ps-6">
              <Col sm={3} className='text-start'>
                <span className="fs-7 fw-bold">{t("Payment amount")}</span>
              </Col>
              <Col sm={9}>
                <p className="text-start m-0">{initialValues.sum}<span className='ms-1'>{currencySing}</span></p>
              </Col>
            </Row>
            <Row className="mt-3 pe-3 py-2 ps-6">
              <TextareaInputWithCounter
                id='editPayment-comment-field'
                name='comment'
                maxLength={200}
                labelCol={3}
                inputCol={9}
                placeholder={t('Add Comment')}
                label={t('Comment')}
              />
            </Row>
            <Row className="w-100 m-0 mt-4">
              <Col lg={6} className='offset-lg-6 d-flex justify-content-end gap-2 px-0 ps-lg-4' >
                <Col xs={6} lg={6}>
                  <Button
                    onClick={() => {
                      handleReset();
                      handleModalClose();
                    }}
                    variant="outline-primary" type="button" disabled={isSubmitting}
                    className="w-100 fw-normal"
                  >
                    {t("Cancel")}
                  </Button>
                </Col>
                <Col xs={6} lg={6}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={isSubmitting || !isValid}
                  >
                    {t("edit")}
                  </Button>
                </Col>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </>
  );
};

export default EditPaymentForm;
