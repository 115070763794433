import React, {useRef, useState} from 'react';
import {Field, ErrorMessage, useFormikContext} from 'formik';
import {useTranslation} from 'react-i18next';
import {Col} from 'react-bootstrap';
import TooltipCustom from '@services/ui-components/src/ToolTip/ToolTipCustom';

interface CheckBox {
  name: string;
  label: string;
  value?:boolean;
  id: string;
}

const CheckBox: React.FC<CheckBox> = ({
                                        name,
                                        label,
                                        value,
                                        id
}) => {
  const {t} = useTranslation();
  const startValue= value ? value : false
  const [checked, setChecked] = useState(startValue);
  const {setFieldValue} = useFormikContext();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const handleChecked = async () => {
    try {
      const newChecked = !checked;
      setChecked(newChecked);
      await setFieldValue(name, newChecked);
    } catch (error) {
      console.error('Error setting value:', error);
    }
  };

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    checkboxRef.current?.focus();
  };

  return (
    <>
      <Col
        md={2}
        className='mb-2 mb-md-0'>
        <label
          className='text-dark fs-7 fw-bold'
          htmlFor={id}
          onClick={handleLabelClick}
        >
          {label}
        </label>
      </Col>
      <Col
        md={1}
        className='form-check form-switch ps-0'>
        <div className='form-group ps-3 d-flex'>
          <div className='form-check form-switch'>
            <Field
              type='checkbox'
              name={name}
              id={id}
              className='form-check-input cursor-pointer'
              checked={checked}
              onChange={handleChecked}
              innerRef={checkboxRef}
            />
          </div>
          <label
            className='form-check-label d-flex'
          >
            {checked ? t('allowed') : t('forbidden')}
            <TooltipCustom placement='right'
                           text={t('Prohibit/Allow display of the service in the form of an online record')}>
              <i className="bi bi-info-circle text-dark ms-2 align-self-center"></i>
            </TooltipCustom>
          </label>
        </div>
        <ErrorMessage name={name}>{msg => <div
          className='invalid-feedback'>{msg}</div>}</ErrorMessage>
      </Col>
    </>
  );
};

export default CheckBox;
