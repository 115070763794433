import React from "react";
import {PosScheduleCalendar} from "@me-pos/calendar";
import i18next from 'i18next';
import {useTranslation} from "react-i18next";
import {CalendarClientFactory} from "./CalendarClientFactory"

type ScheduleCalendarWrapperComponentProps = {
    branchId : number,
    locale?: string,
    filters : any,
    timeZone: string,
    companyId : number,
    showPreloaderHandler : () => void,
    hidePreloaderHandler : () => void
}

export const ScheduleCalendarWrapperComponent : React.FC<ScheduleCalendarWrapperComponentProps> =
    ({
         branchId,
         locale,
         filters,
         timeZone,
         showPreloaderHandler,
         hidePreloaderHandler,
         companyId
     }) => {
        const {t,  i18n} = useTranslation();
        const basePath = process.env.REACT_APP_POS_URL;
        const WSS_URL = process.env.REACT_APP_SOCKET;
        return <PosScheduleCalendar
            i18n={i18next}
            companyId={companyId}
            branchId={branchId}
            locale={i18n.language}
            timeZone={timeZone}
            positionId={filters.positionId}
            employeeId={filters.employeeId}
            onRequestStart={showPreloaderHandler}
            onRequestEnd={hidePreloaderHandler}
            apiUrl={basePath + "/"}
            socketUrl={WSS_URL}
            axios={CalendarClientFactory.create()}
            graphQlApi={basePath + "/graphql/"}

        />;
    }
