import React from 'react';
import {Button, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {downloadQRCode} from '@me-team/host/src/utils/utils';

interface QRCodeDownloadProps {
  qrCodeUrl: string;
}

const QrCodeDownload: React.FC<QRCodeDownloadProps> = ({qrCodeUrl}) => {
  const {t} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;

  const replaceBasePath = (url: string, basePath: string) => {
    const urlObj = new URL(url);
    return url.replace(urlObj.origin, basePath);
  };

  const updatedUrl = replaceBasePath(qrCodeUrl, basePath);

  const handleDownloadClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    await downloadQRCode(updatedUrl);
  };

  return (
    <Col lg={9} className='offset-lg-3'>
      <Button
        variant='outline-primary'
        className='fw-normal rounded-1 px-xxl-4 w-100 generate-qr'
        style={{maxWidth: '170px'}}
        onClick={handleDownloadClick}
      >
        {t('Generate QR')}
      </Button>
    </Col>
  );
}

export default QrCodeDownload;