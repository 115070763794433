import React, { ReactNode } from 'react';

interface InfoBannerProps {
    children?: ReactNode;
}

const InfoBanner: React.FC<InfoBannerProps> = ({ children }) => {

    return (
        <div className='d-flex align-items-center gap-2 px-6 py-2 mb-4 rounded bg-violet-light text-violet-hover rounded-1'>
            <i className='bi bi-info-circle fs-normal'></i>
            <div className='fs-7'>
                {children}
            </div>
        </div>
    );
};

export default InfoBanner;
