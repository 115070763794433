import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useReactiveVar} from "@apollo/client";
import {currentCompanyIdVar} from "@me-team/host/src/apollo/globalVar/state";
import {useTranslation} from "react-i18next";

interface AccessSwitcherStepProps {
    goToNextStep: () => void;
}

const ScheduleStepCheck: React.FC<StepFunctions> = ({ goToNextStep, goToStep }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const companyId = useReactiveVar(currentCompanyIdVar);
    const handleButtonClick = () => setShowStartStep(true);
    const handleNavigate = () => {
        localStorage.setItem('step', '10');
        navigate(`/services/service/${companyId}/service-list`);
    };
    return (
        <div className="p-1">
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={11}>
                            <p className='fs-7'>{t('Check the set schedule of the employee and')} <span className="fs-normal fw-bold fs-7">{t('click “Next”')}</span> </p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                                </Button>
                                </Col>
                                </Row>
                                <Row className='m-0 pt-3  border-top'>
                                <Col className='text-end p-0'>
                                <Button className="px-4" onClick={handleNavigate}>{t('Next')}</Button>
                        </Col>

                    </Row>
                </>

            )}

        </div>
    )
};

export default ScheduleStepCheck;