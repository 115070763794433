import { useGetBranchesQuery } from '@me-team/host/main/branches/graphql/branches.hooks';

interface BranchesFilter {
    branchTypeId?: string;
    name?: string;
}

export const useBranches = (currentPage: number, itemsPerPage: number, filter: BranchesFilter) => {
    const { data, loading, error, refetch } = useGetBranchesQuery({
        variables: {
            itemsPerPage,
            page: currentPage,
            branchTypeId: filter.branchTypeId ? Number(filter.branchTypeId) : null,
            name: filter.name ? filter.name : null,
        },
    });

    return { data, loading, error, refetch };
};
