import { Modal, Button, Row, Col } from 'react-bootstrap';
import React from 'react';

interface ButtonProps {
  text: string;
  onClick: () => void;
  variant: string;
  className?: string;
}

interface ModalDeleteProps {
  show: boolean;
  handleClose: () => void;
  title: string;
  body: string;
  variantTitle?: string;
  buttons: ButtonProps[];
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  show,
  handleClose,
  title,
  variantTitle,
  body,
  buttons,
}) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered>
      <Modal.Header
        closeButton
        className='border-0'>
        <Modal.Title className={`h5  ${variantTitle}`}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>{body}</Modal.Body>
      <Modal.Footer className='border-0'>
        <Row className='gx-3 w-100 d-flex m-0 gap-2'>
          {buttons.map((button, index) => (
            <Col key={index} className='px-0'>
              <Button
                variant={button.variant}
                className={button.className}
                onClick={button.onClick}>
                {button.text}
              </Button>
            </Col>
          ))}
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDelete;
