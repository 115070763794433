import axios, {AxiosInstance} from 'axios';
const basePath = process.env.REACT_APP_POS_URL;


const AXIOS_CONFIG = {
  baseURL: basePath + "/",
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
};

export class CalendarClientFactory {
  public static create(): AxiosInstance {
    return axios.create(AXIOS_CONFIG);
  }
}
