import React from 'react';
import Joyride , {LIFECYCLE, ACTIONS, EVENTS } from 'react-joyride';
import { useState } from 'react';
import { getJoyrideSteps } from "./joyrideSteps";
import { handleJoyrideCallback } from "./joyrideCallbackHandler";

interface JoyrideWrapperProps {
    run: boolean | string;
    stepIndex?: number;
    setStepIndex?: (index: number) => void;
    goToStepHoc?: (direction: string) => void;
    booleanValue?: boolean
    setRun?:  (boolean: boolean) => void;
    setModalJoyride?:  (boolean: boolean) => void;
}

const JoyrideWrapper: React.FC<JoyrideWrapperProps> = ({ run, stepIndex, setStepIndex, goToStepHoc, booleanValue, setRun, setModalJoyride }) => {
    const runJoy = localStorage.getItem('run') || false;
    const step = localStorage.getItem('step') || '0';
    const [stepIndexWrp, setStepIndexWrp] = useState(+step);
    const [runWrp, setRunWrp] = useState(false);
    const goToStep = (direction: string) => {
        let newStepIndex = stepIndex;
        if (direction === 'next') {
            newStepIndex = stepIndex + 1;
        } else if (direction === 'back' && stepIndex > 0) {
            newStepIndex = stepIndex - 1;
        }
        setStepIndex(newStepIndex);
        localStorage.setItem('step', newStepIndex.toString());
    }
    return (
        <Joyride
            steps={getJoyrideSteps({ goToStep, booleanValue, setRun, setModalJoyride })}
            run={run}
            stepIndex={stepIndex}
            continuous={true}
            scrollToFirstStep={true}
            showProgress={true}
            showSkipButton={true}
            spotlightClicks={true}
            callback={(data) => handleJoyrideCallback(data, setStepIndex)}
            tooltipComponent={({ step }) => <div>{step.content}</div>}
            // disableScrollParentFix={stepIndex !== 8}
        />
    );
};

export default JoyrideWrapper;
