import React from 'react';
import {useState} from "react";
import { Button, Col, Row } from "react-bootstrap";
import { StepFunctions } from '../joyrideSteps';
import StartStep from "./StartStep";
import {useTranslation} from "react-i18next";


const AddEmployeeStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    return (
        <div className="p-1">
            {showStartStep ? (
                    <StartStep setShowStartStep={setShowStartStep}/>
                ) : (
                <Row>
                    <Col xs={11}>
                        <p className='mb-0 fs-7'><span className="fs-normal fw-bold fs-7">{t('Fill in the required fields')}</span> {t('marked with an asterisk')} (<span className="text-danger">*</span>) {t('and click “Save” to save the new employee')} </p>
                    </Col>
                    <Col xs={1} className="p-0 text-center">
                        <Button variant='link' className="p-0" onClick={handleButtonClick}>
                            <i className="bi bi-x-lg "></i>
                        </Button>
                    </Col>
                </Row>
            ) }

        </div>
    )
}

export default AddEmployeeStep;
