 import React, {useEffect, useRef, useState} from 'react';
import {Formik, Form, ErrorMessage, FormikHelpers} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Row, Col, Button, Container} from 'react-bootstrap';
import {
  createServiceFormValidationSchema,
  getServiceInputFor
} from './constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import InputWithCounter from './inputs/TextareaInput';
import TextInputWithCounter from './inputs/TextInputWithCounter';
import CheckBox from './inputs/CheckBox';
import {CustomModal, DurationInput} from '@services/ui-components';
import PriceInput from './inputs/PriceInput';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import ErrorService from '../../../services/ErrorService';
import {ServiceModalWrapper} from '@me-pos/service-modal';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import {
   useCreateServCategoryMutation,
   useCreateServiceMutation, useGetCategoryServQuery,
  useGetOneServiceFromServicesListLazyQuery,
  useUpdateServiceMutation
} from '@me-team/host/main/services/graphql/services.hooks';
 import categoryService from './utils/CategoryService';
 import RequestInputService from './utils/RequestInputService';
 import {CategoryInterface, VariablesForCreateCategoryForm} from './type';
 import CategoryService from './utils/CategoryService';
 import {
  ServiceCategoryNewProxy,
  ServiceInput
} from '@me-team/host/main/graphql/types';
 import { changeStepStorage } from '../../Joyride/joyrideCallbackHandler';
 import Spinner from "@services/ui-components/src/Spinner/Spinner";
 import JoyrideWrapper from "../../Joyride/JoyrideWrapper";
 import {normalizeCategoryData} from './utils/normalizeCategoryData';
export interface FormData {
  name: string;
  category: number;
  price: number;
  duration: string;
  onlineBooking?: boolean;
  description?: string;
  deleted?: boolean;
  image?: File | null;
}

const CreateServiceForm: React.FC<{}> = () => {
  const {t} = useTranslation();
  const runJoy = localStorage.getItem('run') || false;
  const step = localStorage.getItem('step') || '0';
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const companyId = currentCompanyId && +currentCompanyId
  const navigate = useNavigate();
  const buttonCategoryRef = useRef<HTMLButtonElement>(null);
  const [isDuplicateOfArchivalService, setIsDuplicateOfArchivalService] = useState(false);
  const [serviceDuplicate, setServiceDuplicate] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);

  const [isServiceModalOpen, setIsServiceModalOpen] = useState<boolean>(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [categoryList, setCategoryList] = useState<CategoryInterface[]>(null)
  const [categoryInput, setCategoryInput] = useState(null);
  const [pickedCategoryId, setPickedCategoryId] = useState<number>(null);
  const [stepIndex, setStepIndex] = useState(+step);
  const [run, setRun] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const [updateService] = useUpdateServiceMutation();

  const handleOpenDuplicateOfServiceModal = () => {
    setIsDuplicateOfArchivalService(true)
  }
  const handleCloseDuplicateOfServiceModal = () => {
    setIsDuplicateOfArchivalService(false)
  }

  const [getService, {data, loading}] = useGetOneServiceFromServicesListLazyQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    },
  });

  const handleLabelClick = (event: React.MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();
    if (buttonCategoryRef.current) {
      buttonCategoryRef.current.focus();
    }
  };

  const {data: serviceCategoriesData, refetch: refetchCategories, loading: loadingServiceCategories} = useGetCategoryServQuery( {
    variables: {id: null, categoryName: null, serviceDeleted: true, page: 1, itemsPerPage: 1000},
    context: {
      errorType: 'local',
    },
    onCompleted: (data) => {
      const normalizedItems = normalizeCategoryData(data);
      setCategoryList(categoryService.categoryModuleCreator(normalizedItems, null, null))
      setCategoryInput(RequestInputService.createServiceCategoryInput(null, null, normalizedItems));
    },
    onError: (error) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error));
    }
  })

  const categoryListData: ServiceCategoryNewProxy[] = normalizeCategoryData(serviceCategoriesData)

  const [createService] = useCreateServiceMutation();
  const [createCategoryService] = useCreateServCategoryMutation()

  const [
    currentName,
    setCurrentName
  ] = useState({
    categoryName: '',
    subCategoryName: '',
  });

  const [
    categoryText,
    setCategoryText
  ] = useState([null, null]);
  const selectedCategory = categoryText[0] || '';
  const selectedSubcategory = categoryText[1 ] || '';

  const handleSubmitCreate = async (variables: VariablesForCreateCategoryForm) => {
    try {
      await createCategoryService({
        variables,
        context: {
          errorType: 'local',
        },
        onCompleted: (data) => {
          data && setCurrentCategory(data.createServiceCategoryNew);
        },
        onError: (error: ApolloError) => {
          setIsErrorModalOpen(ErrorService.errorHandling(error));
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCategoryNameChange = (categoryName: string) => {
    setCurrentName(prevState => ({
      ...prevState,
      categoryName: categoryName,
      subCategoryName: '',
    }));
    setCategoryText([categoryName]);
  };

  const handleSubCategoryNameChange = (  subCategoryName: string, categoryName: string ) => {
    setCurrentName((prevState) => ({
      ...prevState,
      categoryName: categoryName,
      subCategoryName: subCategoryName,
    }));
    setCategoryText([categoryName, subCategoryName]);
  };

  const handleCancel = () => {
    changeStepStorage('back');
    navigate(`/services/service/${currentCompanyId}/service-list`)
  }

  const initialValues: FormData = {
    name: '',
    category: null,
    duration: '',
    price: null,
    onlineBooking: false,
    description: '',
    deleted: false,
    image: null,
  };

  const handleCreateSubmit = async (
    values: FormData,
    {setSubmitting, setErrors}: FormikHelpers<FormData>
  ) => {
    await getService({
      variables: {
        name: values?.name.trim(),
        categoryId: values?.category,
      },
      onCompleted: async (data) => {
        const servicesList = data?.user?.company?.servicesList || [];
        const activeDuplicateService = servicesList?.find(service => service.deleted === false);
        if (activeDuplicateService) {
          setErrors({
            name: t('A service with this name and category already exists.'),
          });
          return  setSubmitting(false);
        }
        const archiveDuplicateService = servicesList.find(service => service.deleted === true);
        if (archiveDuplicateService) {
          setServiceDuplicate(archiveDuplicateService)
          handleOpenDuplicateOfServiceModal();
          return  setSubmitting(false);
        }
        const input = getServiceInputFor('create',companyId, values);
        await createService({
          variables: { companyId: +currentCompanyId, input },
          context: {
            errorType: 'local',
          },
          onCompleted: (data) => {
            run && data && changeStepStorage('next')
            navigate(`/services/service/${currentCompanyId}/service-list`, {
              state: { text: t('Created'), toast: true, service: run ?  data?.createService?.id : null },
            });
            setSubmitting(false);
          },
          onError: (error: ApolloError) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error));
            setSubmitting(false);
          },
        });
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error));
        setSubmitting(false);
      },
    })
  }

  const handleRestoreDeletedService = async ()=>{
    const input: ServiceInput = getServiceInputFor(
      'restoreDuplicate',
      companyId,
      undefined,
      serviceDuplicate
    );

    await updateService({
      variables: {id: serviceDuplicate?.id, input},
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        data && navigate(`/services/service/${currentCompanyId}/service-list`, {
          state: {
            text: t('Restored'),
            toast: true
          }
        });
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  }

  const handleCreateDuplicateService = async () => {
    const input: ServiceInput = getServiceInputFor(
      'duplicate',
      companyId,
      undefined,
      serviceDuplicate
    );
    await createService({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local',
      },
      onCompleted: (data) => {
        data && navigate(`/services/service/${currentCompanyId}/service-list`, {
          state: {
            text: t('Created'),
            toast: true
          }
        });
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      },
    });
  }

  if (loadingServiceCategories || loading) return <Spinner/>;
  return (
      <>
        <div className={!loadingServiceCategories ? "add-service-step" : ""}>
          <Formik
              initialValues={initialValues}
              validationSchema={createServiceFormValidationSchema(t)}
              onSubmit={handleCreateSubmit}>
            {({
                setFieldValue,
                touched,
                errors
              }) => {
              useEffect(() => {
                setFieldValue('category', pickedCategoryId);
                setRun(runJoy ? true : false);
              }, [pickedCategoryId]);

              return (
                  <Form>
                    <Row className='container-max-widths-xxl flex-column-reverse flex-lg-row  mb-5'>
                      <Col
                          lg={8} xxl={9}
                          className='pe-xl-5'>
                        <h4 className='mb-3'>{t('Information')}</h4>
                        <Row className='mb-4'>
                          <TextInputWithCounter
                              id='addService-name-field'
                              label={t('Service name')}
                              name='name'
                              placeholder={t('Enter a service name')}
                          />
                        </Row>
                        <Row className='mb-4'>
                          <Col
                              md={2}
                              className='mb-2 mb-md-0'>
                            <label
                                htmlFor='addService-category-field'
                                className='text-dark fs-7 fw-bold py-1'
                                onClick={handleLabelClick}
                            >
                              {t('category')}&nbsp;<span className='text-danger'>*</span>
                            </label>
                          </Col>
                          <Col md={10}>
                            <div>
                        <span className={`${selectedCategory ? 'me-4' : 'd-none'}  mb-2 mb-lg-0 text-grey`}>
                          {selectedCategory} {selectedSubcategory ?
                            <>
                              <i className="bi bi-arrow-right"></i> {selectedSubcategory}
                            </>
                            : null
                        }
                        </span>
                              <Button
                                  ref={buttonCategoryRef}
                                  variant={`${touched.category && errors.category ? 'outline-danger' : 'outline-primary'}`}
                                  className='w-100 fw-normal py-1'
                                  id='addService-category-field'
                                  style={{maxWidth: '149px'}}
                                  onClick={() => {
                                    setIsServiceModalOpen(true)
                                  }}
                              >
                                {categoryText[0] ? t('Change Category') : t('Select Category')}
                              </Button>
                              <ErrorMessage name="category">{msg => <div
                                  className='text-danger fs-7 mt-1'>{msg}</div>}
                              </ErrorMessage>
                            </div>
                          </Col>
                        </Row>
                        <Row className='mb-4'>
                          <InputWithCounter
                              label={t('description')}
                              name='description'
                              id='addService-description-field'
                              placeholder={t('Add a short description of the service')}
                          />
                        </Row>
                        <Row className='mb-4 durationWrapper'>
                          <DurationInput
                              name='duration'
                              id='addService-duration-field'
                              label={t('Duration')}
                          />
                        </Row>
                        <Row className='mb-4'>
                          <PriceInput
                              name='price'
                              placeholder='0.00'
                              id='addService-price-field'
                          />
                        </Row>
                        <Row className='flex align-items-center'>
                          <CheckBox
                              name='onlineBooking'
                              id='addService-onlineBooking-field'
                              label={t('Online Registration')}
                          />
                        </Row>
                      </Col>
                      <Col lg={4} xxl={3}>
                        <div
                            className='d-flex mb-3 w-100 flex-column justify-content-lg-end'>
                          <h4 className='mb-3 w-100'>
                            {t('Services Photo')}
                          </h4>
                          <ImageCrop
                              setFieldValue={setFieldValue}
                              aspectProp={2.1 / 1}
                              modalTitle={t('Services Photo')}
                              notBGinfo={true}
                          />
                          <ErrorMessage name="image">{msg => <div>{msg}</div>}</ErrorMessage>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-2 mt-lg-5'>
                      <Col lg={4} className='d-flex gap-4'>
                        <Button
                            type='button'
                            variant='outline-primary'
                            className='custom-btn'
                            onClick={handleCancel}
                        >
                          {t('cancel')}
                        </Button>
                        <Button
                            type='submit'
                            variant='primary'
                            className='custom-btn'
                        >
                          {t('Add')}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
              );
            }}
          </Formik>
        </div>

          {run && !loadingServiceCategories &&
              <JoyrideWrapper run={run} stepIndex={stepIndex} setStepIndex={setStepIndex}/>}
          {
            isServiceModalOpen && serviceCategoriesData && !loadingServiceCategories ?
                <ServiceModalWrapper
                    i18n={i18next}
                    selectedCategoryId={null}
                    selectedSubcategoryId={null}
                    apolloUrl={process.env.REACT_APP_API_URL}
                    isOpen={isServiceModalOpen}
                    onClose={() => {
                      setIsServiceModalOpen(!isServiceModalOpen)
                    }}
                    onCategorySelect={(selectedCategory) => {
                      handleCategoryNameChange(selectedCategory?.name);
                      setPickedCategoryId(selectedCategory?.id)
                      setIsServiceModalOpen(!isServiceModalOpen)
                    }}
                    onSubcategorySelect={(selectedSubcategory) => {
                      handleSubCategoryNameChange(
                          selectedSubcategory?.name,
                          selectedSubcategory?.parentCategory?.name
                      );
                      setPickedCategoryId(selectedSubcategory?.id)
                      setIsServiceModalOpen(!isServiceModalOpen)

                    }}
                    categoryList={categoryList}
                    categoryInput={categoryInput}
                    categoryListData={categoryListData}
                    refetchCategories={refetchCategories}
                    onSubmitCreate={handleSubmitCreate}
                    currentCategory={currentCategory}
                    categoryService={CategoryService}
                    requestInputService={RequestInputService}
                    normalizeCategoryData={normalizeCategoryData}
                />
                : null
          }
          <CustomModal
              show={isDuplicateOfArchivalService}
              handleClose={handleCloseDuplicateOfServiceModal}
              title={
                <Container>
                  <Row>
                    <h5 className='m-0 p-0 fw-normal'>{t('Duplicate of the archived service')}</h5>
                  </Row>
                </Container>
              }
              body={
                <Container>
                  <Row>
                    <Col>
                      <span className='fs-6 me-1'>{t('There is a deleted service called')}</span>
                      <span>"{serviceDuplicate?.name}",</span>
                      <span className='fs-6 ms-1'>{t('do you want to restore it or do you want to' +
                          ' create a new one?')}</span>
                    </Col>
                  </Row>
                </Container>
              }
              buttons={[
                {
                  text: t('Restore'),
                  onClick: handleRestoreDeletedService,
                  variant: 'outline-primary',
                  className: 'w-100 fw-normal',
                },
                {
                  text: t('Create new'),
                  onClick: handleCreateDuplicateService,
                  variant: 'primary',
                  className: 'w-100 fw-normal',
                },
              ]}
          />
          {isErrorModalOpen ?
              <ErrorModalComponent
                  i18n={i18next}
                  onClose={() => {
                    setIsErrorModalOpen(null)
                  }}
                  isOpen={!!isErrorModalOpen}
                  currentError={isErrorModalOpen}
              /> : null
          }
        </>
        );
        };

        export default CreateServiceForm;
