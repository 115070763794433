import React from 'react';
import person from '@me-team/host/src/images/person.jpg'

interface EmployeeInfoProps {
  name: string,
  surname: string,
  position: string,
  image: string
}

const EmployeeInfo: React.FC<EmployeeInfoProps> = ({
                                                     name,
                                                     surname,
                                                     position,
                                                     image
                                                   }) => {
  return (
    <div className="d-flex align-items-center">
      {image ?
        <div>
          <img
            className="img-employ-list rounded-circle d-flex"
            src={`${process.env.REACT_APP_POS_URL}/${image}`} alt='Avatar' width="40px"
            height="40px"
          />
        </div>
        :
        <div>
          <img
            className="img-employ-list rounded-circle"
            src={person} alt='Avatar'
            width="40px"
            height="40px"
          />
        </div>
      }

      <div className="ms-2">
        <p className="mb-0 text-dark">
          <span className="text-reset text-decoration-none">
            {name} {surname}
          </span>
        </p>
        <span className="text-grey fs-7">
          {position}
        </span>
      </div>
    </div>
  );
};

export default EmployeeInfo;