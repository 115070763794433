
import {ServicePickCategoryInterfaceInner, ServicePickSubcategoryInner} from "../interfaces/interfaces";
import {ServiceCategoryNewProxy, ServiceProxy} from "@me-team/host/main/graphql/types";

export class ServicePickerInnit {
    public static ServicePikerModuleCreator = (
        serviceCategoryList : ServiceCategoryNewProxy[],
        categoryId : number,
        serviceId: number) : ServicePickCategoryInterfaceInner[] => {
        return serviceCategoryList.map((currentCategory : ServiceCategoryNewProxy) => {
            return {
                ...currentCategory,
                isOpen : !!(currentCategory.id === categoryId && serviceId),
                isHidden : false,
                isServicePicked : !!(currentCategory.id === categoryId && serviceId),
                servicePickerList : this.ServiceSubcategoryInner(currentCategory.services, serviceId)
            }
        })
    }

    public static ServiceSubcategoryInner = (serviceSubcategoryList : ServiceProxy[], serviceId : number) : ServicePickSubcategoryInner[] => {
        return serviceSubcategoryList.map((currentSubcategory) => {
            return {
                ...currentSubcategory,
                isPicked : currentSubcategory.id === serviceId,
                isHidden : false
            }
        })

    }
}