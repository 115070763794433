import React from 'react';
import {Row} from 'react-bootstrap';
import {getSecondWord, getTariffDescription} from '../constants';
import {useTranslation} from 'react-i18next';

interface SubscriptionNameFieldProps{
  subscriptionName:string
  isYearly:boolean
}

const SubscriptionNameField:React.FC<SubscriptionNameFieldProps> = ({subscriptionName,isYearly}) => {
  const { t } = useTranslation();
  const period = isYearly ? t('Annual Subscription') : t('Monthly Subscription');
  const tariffDescription = getTariffDescription(subscriptionName)

  return (
    <Row>
      <p className='fs-normal mb-0'>
        <span className='me-1'>{t(getSecondWord(subscriptionName))}</span>
        ({period})
      </p>
      <span className='fs-7 text-grey'>
        {t(tariffDescription)}
      </span>
    </Row>
  );
};

export default SubscriptionNameField;