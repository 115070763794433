import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";

interface FormValuesReview {
  name: string;
  text?: string;
  rating: number;
  employeeId: number;
}

interface AddEditModalProps {
  isModalOpened: boolean;
  setIsModalOpened: (isOpened: boolean) => void;
  // onSuccess?: () => void;
  title: string;
  children: React.ReactNode;
}


const AddEditModal: React.FC<AddEditModalProps> = ({
                                                     isModalOpened,
                                                     setIsModalOpened,
                                                     // onSuccess,
                                                     children,
                                                     title
                                                   }) => {
  const {t} = useTranslation();


  const handleModalClose = () => {
    // setSelectedReviewEmployees(null); // Reset the selected employee when closing the modal window
    setIsModalOpened(false);
  };


  return (
    <Modal
      show={isModalOpened}
      size="lg"
      onHide={handleModalClose}
      centered>
      <Modal.Header
        closeButton
        className='border-0'>
        <Modal.Title className="h5">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default AddEditModal;