import React from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import {
    currentBranchIdVar,
    currentCompanyIdVar,
    userUsernameVar
} from "@me-team/host/src/apollo/globalVar/state";
import { Row, Col, Button } from "react-bootstrap";
import error403 from "../../images/errors/error403.png";

const Page403: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const userId = useReactiveVar(userUsernameVar);
    const currentBranchId = useReactiveVar(currentBranchIdVar);
    const currentCompanyId = useReactiveVar(currentCompanyIdVar);
    const basePath = process.env.REACT_APP_POS_URL;

    const handleButtonNavigate = () => {navigate(-1);
    };
    const handleButtonOnMain = () => window.location.href = `${basePath}/branch/company/${currentCompanyId}/${currentBranchId}`;

    return (
        <Row className="align-items-center text-dark py-5 bg-white">
            <Col xs={12} lg={6} className="my-auto order-2 order-lg-0">
                <h1 className="error-heading text-violet-light fw-bold d-none d-lg-block mb-4">403</h1>
                <h2 className="text-capitalize fw-bold text-center text-lg-start mb-3">{t("Access denied")}</h2>
                <Col xs={12} sm={10} className="mx-auto mx-lg-0 mb-5">
                    <p className="text-grey text-center text-lg-start mb-0 me-lg-4">{t("Make sure you enter the correct web address in the address bar and refresh the page, or return to the main menu.")}</p>
                </Col>
                <Row className="col-xxl-8">
                    <Col xs={12} sm={6} className="mb-3 mb-sm-0">
                        <Button
                            onClick={handleButtonNavigate}
                            variant="success"
                            className="fs-7 text-white fw-bold rounded-1 w-100">{t("Back")}</Button>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Button
                            onClick={handleButtonOnMain}
                            variant="outline-success"
                            className="fs-7 text-capitalize fw-normal rounded-1 w-100">{t("Main menu")}</Button>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} lg={6} className="d-flex justify-content-center align-items-center py-5">
                <img src={error403} width="609" height="598" loading="lazy" className="img-fluid" alt="403 Error"/>
            </Col>
        </Row>
    )
}

export default Page403;
