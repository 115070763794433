/* eslint-disable */
import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetReviewsDocument = gql`
    query GetReviews($page: Int, $itemsPerPage: Int, $employeeId: Int, $reviewSortField: String, $order: String, $reviewType: String, $rating: Int, $employeeEmail: String) {
  user {
    id
    company {
      id
      timeZone {
        id
        name
      }
      reviews(
        page: $page
        itemsPerPage: $itemsPerPage
        employeeId: $employeeId
        reviewSortField: $reviewSortField
        order: $order
        reviewType: $reviewType
        rating: $rating
        employeeEmail: $employeeEmail
      ) {
        reviews {
          createdAt
          id
          name
          rating
          text
          type
          booking {
            client {
              id
              name
              surname
            }
            date
            id
            needClientRecall
            uuid
          }
          employee {
            name
            surname
            email
            id
            manualBlocking
            phone
            position {
              name
            }
            image
          }
        }
        currentPage
        totalCount
        currentPage
        itemsPerPage
        pageCount
      }
    }
  }
}
    `;

/**
 * __useGetReviewsQuery__
 *
 * To run a query within a React component, call `useGetReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReviewsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      employeeId: // value for 'employeeId'
 *      reviewSortField: // value for 'reviewSortField'
 *      order: // value for 'order'
 *      reviewType: // value for 'reviewType'
 *      rating: // value for 'rating'
 *      employeeEmail: // value for 'employeeEmail'
 *   },
 * });
 */
export function useGetReviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetReviewsQuery, GetReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReviewsQuery, GetReviewsQueryVariables>(GetReviewsDocument, options);
      }
export function useGetReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReviewsQuery, GetReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReviewsQuery, GetReviewsQueryVariables>(GetReviewsDocument, options);
        }
export function useGetReviewsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetReviewsQuery, GetReviewsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReviewsQuery, GetReviewsQueryVariables>(GetReviewsDocument, options);
        }
export type GetReviewsQueryHookResult = ReturnType<typeof useGetReviewsQuery>;
export type GetReviewsLazyQueryHookResult = ReturnType<typeof useGetReviewsLazyQuery>;
export type GetReviewsSuspenseQueryHookResult = ReturnType<typeof useGetReviewsSuspenseQuery>;
export type GetReviewsQueryResult = Apollo.QueryResult<GetReviewsQuery, GetReviewsQueryVariables>;
export const GetEmployeesForReviewsDocument = gql`
    query GetEmployeesForReviews($id: Int, $itemsPerPage: Int, $filterByNameAndPosition: Boolean, $email: String) {
  user {
    id
    company {
      id
      employees(
        id: $id
        itemsPerPage: $itemsPerPage
        filterByNameAndPosition: $filterByNameAndPosition
        email: $email
      ) {
        employees {
          id
          name
          surname
          email
          position {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeesForReviewsQuery__
 *
 * To run a query within a React component, call `useGetEmployeesForReviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeesForReviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeesForReviewsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      itemsPerPage: // value for 'itemsPerPage'
 *      filterByNameAndPosition: // value for 'filterByNameAndPosition'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetEmployeesForReviewsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>(GetEmployeesForReviewsDocument, options);
      }
export function useGetEmployeesForReviewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>(GetEmployeesForReviewsDocument, options);
        }
export function useGetEmployeesForReviewsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>(GetEmployeesForReviewsDocument, options);
        }
export type GetEmployeesForReviewsQueryHookResult = ReturnType<typeof useGetEmployeesForReviewsQuery>;
export type GetEmployeesForReviewsLazyQueryHookResult = ReturnType<typeof useGetEmployeesForReviewsLazyQuery>;
export type GetEmployeesForReviewsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeesForReviewsSuspenseQuery>;
export type GetEmployeesForReviewsQueryResult = Apollo.QueryResult<GetEmployeesForReviewsQuery, GetEmployeesForReviewsQueryVariables>;
export const DeleteReviewsDocument = gql`
    mutation DeleteReviews($id: Int!) {
  deleteReview(id: $id)
}
    `;
export type DeleteReviewsMutationFn = Apollo.MutationFunction<DeleteReviewsMutation, DeleteReviewsMutationVariables>;

/**
 * __useDeleteReviewsMutation__
 *
 * To run a mutation, you first call `useDeleteReviewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReviewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReviewsMutation, { data, loading, error }] = useDeleteReviewsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReviewsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReviewsMutation, DeleteReviewsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReviewsMutation, DeleteReviewsMutationVariables>(DeleteReviewsDocument, options);
      }
export type DeleteReviewsMutationHookResult = ReturnType<typeof useDeleteReviewsMutation>;
export type DeleteReviewsMutationResult = Apollo.MutationResult<DeleteReviewsMutation>;
export type DeleteReviewsMutationOptions = Apollo.BaseMutationOptions<DeleteReviewsMutation, DeleteReviewsMutationVariables>;
export const CreateReviewDocument = gql`
    mutation CreateReview($employeeId: Int!, $input: ReviewAdminInput!) {
  createReview(employeeId: $employeeId, input: $input) {
    id
    rating
    name
    text
    type
    createdAt
    employee {
      name
      id
    }
  }
}
    `;
export type CreateReviewMutationFn = Apollo.MutationFunction<CreateReviewMutation, CreateReviewMutationVariables>;

/**
 * __useCreateReviewMutation__
 *
 * To run a mutation, you first call `useCreateReviewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReviewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReviewMutation, { data, loading, error }] = useCreateReviewMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReviewMutation(baseOptions?: Apollo.MutationHookOptions<CreateReviewMutation, CreateReviewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReviewMutation, CreateReviewMutationVariables>(CreateReviewDocument, options);
      }
export type CreateReviewMutationHookResult = ReturnType<typeof useCreateReviewMutation>;
export type CreateReviewMutationResult = Apollo.MutationResult<CreateReviewMutation>;
export type CreateReviewMutationOptions = Apollo.BaseMutationOptions<CreateReviewMutation, CreateReviewMutationVariables>;
export type GetReviewsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  reviewSortField?: Types.InputMaybe<Types.Scalars['String']['input']>;
  order?: Types.InputMaybe<Types.Scalars['String']['input']>;
  reviewType?: Types.InputMaybe<Types.Scalars['String']['input']>;
  rating?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  employeeEmail?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetReviewsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, timeZone?: { __typename?: 'TimeZoneProxy', id: number, name: string } | null, reviews?: { __typename?: 'ReviewPaginated', currentPage?: number | null, totalCount?: number | null, itemsPerPage?: number | null, pageCount?: string | null, reviews?: Array<{ __typename?: 'ReviewProxy', createdAt?: string | null, id: number, name: string, rating: number, text?: string | null, type?: string | null, booking?: { __typename?: 'BookingMainProxy', date?: string | null, id: number, needClientRecall: boolean, uuid: any, client?: { __typename?: 'ClientProxy', id: number, name?: string | null, surname?: string | null } | null } | null, employee?: { __typename?: 'EmployeeProxy', name?: string | null, surname?: string | null, email: string, id: number, manualBlocking: boolean, phone: string, image?: string | null, position?: { __typename?: 'EmployeePositionProxy', name: string } | null } | null }> | null } | null } | null } | null };

export type GetEmployeesForReviewsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  itemsPerPage?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  filterByNameAndPosition?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetEmployeesForReviewsQuery = { __typename?: 'Query', user?: { __typename?: 'UserProxy', id: number, company?: { __typename?: 'CompanyProxy', id: number, employees?: { __typename?: 'EmployeePaginated', employees?: Array<{ __typename?: 'EmployeeProxy', id: number, name?: string | null, surname?: string | null, email: string, position?: { __typename?: 'EmployeePositionProxy', id: number, name: string } | null }> | null } | null } | null } | null };

export type DeleteReviewsMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type DeleteReviewsMutation = { __typename?: 'Mutation', deleteReview: boolean };

export type CreateReviewMutationVariables = Types.Exact<{
  employeeId: Types.Scalars['Int']['input'];
  input: Types.ReviewAdminInput;
}>;


export type CreateReviewMutation = { __typename?: 'Mutation', createReview: { __typename?: 'ReviewProxy', id: number, rating: number, name: string, text?: string | null, type?: string | null, createdAt?: string | null, employee?: { __typename?: 'EmployeeProxy', name?: string | null, id: number } | null } };
