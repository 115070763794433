import React, { useState } from 'react';
import { DateTime } from 'luxon';
import { TimePicker } from "@me-pos/time-picker";
import i18next from 'i18next';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { Col } from 'react-bootstrap';

interface DurationInputProps {
    name: string
    label: string
    prevValue?: string
    serviceValue: any
    updateDuration: any
    isDisabled?: boolean
}

const DurationInput: React.FC<DurationInputProps> = ({ name, label, prevValue, serviceValue, updateDuration, isDisabled = false }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);

    const interval = 15
    const startDateTime = DateTime.now().startOf('day');
    const endDateTime = DateTime.now().endOf('day');
    const startValue = prevValue ? prevValue.substring(0, 5) : ""
    const [value, setValue] = useState(startValue);

    const handleChange = async (newDateTime: DateTime) => {
        const formattedTime = newDateTime.toFormat('HH:mm');
        try {
            setValue(formattedTime)
            updateDuration(serviceValue, formattedTime)
            await setFieldValue(name, formattedTime);
        } catch (error) {
            console.error('Error setting value:', error);
        }
    };

    return (
        <>

            <Col sm={12} md={3} className='w-100' >
                <TimePicker
                    i18n={i18next}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    inactiveIntervals={[]}
                    stepInMinutes={interval}
                    onChange={handleChange}
                    value={value}
                    timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
                    inputName="duration"
                    isDisabled={isDisabled}
                    isRequired={true}
                />
                <ErrorMessage name="duration">{msg => <div>{msg}</div>}</ErrorMessage>
            </Col>
        </>
    );
};

export default DurationInput;