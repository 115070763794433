import React from 'react';
import { DateTime } from 'luxon';
import { TimePicker } from '@me-pos/time-picker';
import i18next from 'i18next';
import { ErrorMessage, useField } from 'formik';
import { Col } from 'react-bootstrap';

interface DurationInputProps {
    name: string;
    isDisabled?: boolean;
}

const DurationInput: React.FC<DurationInputProps> = ({ name, isDisabled = false }) => {
    const [field, meta, helpers] = useField(name);

    const interval = 15;
    const startDateTime = DateTime.now().startOf('day');
    const endDateTime = DateTime.now().endOf('day');
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleChange = (newDateTime: DateTime) => {
        const formattedTime = newDateTime.toFormat('HH:mm:ss');
        helpers.setValue(formattedTime);
    };

    return (
        <>

            <Col sm={12} md={3} className="w-100">
                <TimePicker
                    i18n={i18next}
                    startDateTime={startDateTime}
                    endDateTime={endDateTime}
                    inactiveIntervals={[]}
                    stepInMinutes={interval}
                    onChange={handleChange}
                    value={field.value.slice(0, 5) || ''}
                    timeZone={timeZone}
                    inputName={name}
                    isDisabled={isDisabled}
                    isRequired={false}
                />

            </Col>
        </>
    );
};

export default DurationInput;
