import React, {useEffect, useState} from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker";
import { Link, useNavigate } from 'react-router-dom';
import { Col, Modal, OverlayTrigger, Tooltip, Button, Row, Popover } from 'react-bootstrap';
import i18next from "i18next";
import { ErrorModalComponent } from "@me-pos/error-modal";
import { TableHeader } from '@services/ui-components';
import CreatePositionModal from '../ui-components/CreatePositionModal/CreatePositionModal';
import VioletBadge from '@services/ui-components/src/VioletBadge/VioletBadge';
import Spinner from '@services/ui-components/src/Spinner/Spinner';
import { addEmployeeFormValidationSchema } from './constants/constants';
import CustomCreatableReactSelect from '@services/ui-components/src/select/CustomCreatableReactSelect';
import PhonePicker from '@services/ui-components/src/PhonePicker/PhonePicker';
import ImageCrop from '@services/ui-components/src/ImageCrop/ImageCrop';
import DropItem from '@services/ui-components/src/DropItem/DropItem';
import AccessService from "@me-team/host/services/AccessService";
import DropPhotosMultiply from '../DropPhotosMultiply/DropPhotosMultiply';
import ErrorService from "../../services/ErrorService";
import CustomReactSelect from "@services/ui-components/src/select/CustomReactSelect";
import useNavigateOnRole from "../../hooks/useNavigateOnRole";
import {useReactiveVar} from "@apollo/client";
import {currentUserRoleVar} from "@me-team/host/src/apollo/globalVar/state";
import { useGetCompanyIdQuery, useGetEmployeesQuery } from '@me-team/host/main/employeeList/graphql/employeeList.hooks';
import {
    useCreateEmployeeMutation,
    useGetEmployeesPositionsQuery,
    useGetRolesQuery,
    useGetSpecSpecializationQuery
} from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';
import JoyrideWrapper from '../Joyride/JoyrideWrapper';

const AddEmployee: React.FC = () => {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const runJoy = localStorage.getItem('run') === 'true';
    const [run, setRun] = useState(runJoy);
    const step = localStorage.getItem('step') || '0';
    const [stepIndex, setStepIndex] = useState(+step);

    const [startDate, setStartDate] = useState();
    const [date] = useState('');
    const [showPositionModal, setShowPositionModal] = useState(false);
    const [photosQualification, setPhotosQualification] = useState([null]);
    const [show, setShow] = useState(false);
    const [confirming, setConfirming] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(null);
    const [newPossition, setNewPossition] = useState(null);
    const [key, setKey] = useState(0);
    const userRole = useReactiveVar(currentUserRoleVar);
    useNavigateOnRole()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const accessService = AccessService.getInstance().isPremium
    const { data, loading, error, refetch } = useGetEmployeesQuery({
        variables: { page: 1, itemsPerPage: 8 }
    })
    const { data: dataSpecialization, loading: loadingSpecialization } = useGetSpecSpecializationQuery()
    const { data: roles } = useGetRolesQuery()
    const specialization = dataSpecialization?.employeeSpecializations

    const handleChange = (date: string | null | Date, setFieldValue: (field: string, value: string) => void) => {
        if (date) {
            const formattedDate = new Date(date);
            const year = formattedDate.getFullYear();
            const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
            const day = String(formattedDate.getDate()).padStart(2, '0');
            const formattedDateString = `${year}-${month}-${day}`;
            setStartDate(formattedDate);
            setFieldValue('dateOfBirth', formattedDateString);
        }
    };

    const { data: possitions, loading: loadingPossitions, refetch: refetchPositions } = useGetEmployeesPositionsQuery()
    const { data: companyId, loading: loadingCompanyId } = useGetCompanyIdQuery({
        onError: (error) => {
            setIsErrorModalOpen(ErrorService.errorHandling(error))
        },
        context: {
            errorType: 'global',
        },
    })

    const [createEmployee] = useCreateEmployeeMutation({
        context: {
            errorType: 'local',
        },
    })

    const handlePositionModal = () => {
        setShowPositionModal(prevState => !prevState);
        setKey(prevKey => prevKey + 1);
    };
    const employees = data?.user?.company?.employees?.employees;

    const handleConfirm = (handleSubmit: (value: any) => void, setConfirming: (value: boolean) => void): void => {
        handleSubmit(true);
        setConfirming(true);
    };
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 14);
    const goToNextStep = (id: number) => {

        const nextStepIndex = stepIndex + (accessService ? 2 : 1);
        localStorage.setItem('step', nextStepIndex.toString());
        setStepIndex(nextStepIndex);
        navigate('/employees-list/employee/' + id, { state:  {  text: t('Created'), toast: true } } )
    };
    const handleCancel = () => {
        if (run && stepIndex === 2) {
            const currentStep = localStorage.getItem('step') || '0';
            const previousStep = Math.max(0, parseInt(currentStep) - 1);
            localStorage.setItem('step', "1");
            navigate('/employees-list');
        } else {
            navigate('/employees-list');
        }
    }
    if (loadingPossitions || loadingCompanyId || loading || loadingSpecialization) return <Spinner />

    return (
        <>
            <VioletBadge>
                {t('Required fields are marked with an asterisk *. The data that customers see in the online record form is marked ')}
                {' '} <i className="bi bi-info-circle"></i>
            </VioletBadge>
            <TableHeader
                title={t('Add an employee')}
                paths={[{ path: "/employees-list/add-employee", label: "Personnel management" }, {
                    path: "/employees-list",
                    label: "List of employees"
                }, { path: "/employees-list/add-employee", label: "Add an employee" }]}
            />
            <div className='add-employee-step'>
                <Formik
                    initialValues={{
                        company: companyId?.user.company.id,
                        name: '',
                        surname: '',
                        patronymic: '',
                        dateOfBirth: date,
                        email: '',
                        phone: '',
                        passportData: '',
                        itn: '',
                        aboutMe: '',
                        employeePosition: '',
                        specialization: '',
                        role: '',
                        status: 3,
                        manualBlocking: true,
                        tariffBlocking: true,
                    }}
                    validationSchema={addEmployeeFormValidationSchema(t)}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        try {
                            const cleanedPhotosQualification = photosQualification?.filter(photo => photo != null);

                            const updatedValues = {
                                ...values,
                                qualificationConfirmations: [...cleanedPhotosQualification]
                            };

                            if (!AccessService.getInstance().canAddEmployee(employees, values) && !confirming) {
                                handleShow()
                            } else {
                                const response = await createEmployee({
                                    variables: {
                                        companyId: companyId?.user.company.id,
                                        input: updatedValues
                                    }
                                })
                                run ? goToNextStep(+response.data.createEmployee.id) : navigate('/employees-list', { state:  {  text: t('Created'), toast: true } } )
                            }
                        } catch (error) {
                            console.error("ERROR", error);

                            const graphQLError = error.graphQLErrors?.[0];

                            if (graphQLError?.extensions?.validation?.phone?.[0]?.message === 'This value is not a valid phone number.') {
                                setErrors({ phone: t('Invalid phone number') });
                            } else if (graphQLError?.message === "Employee already exists") {
                                console.error("Error!", graphQLError.message);
                                setErrors({ email: `Email  ${values.email} ${t('already exists in system.')}` });
                            }

                        } finally {
                            setSubmitting(false);
                        }
                    }}
                >

                    {({ errors, touched, setFieldValue, values, handleSubmit, setErrors,  }) => (
                        <>
                            <CreatePositionModal
                                show={showPositionModal}
                                handleModal={handlePositionModal}
                                companyId={companyId?.user.company.id}
                                refetch={refetchPositions}
                                setNewPossition={setNewPossition}
                                setFieldValue={setFieldValue}
                            />
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="d-flex flex-column-reverse flex-lg-row">
                                        <Col xs={12} lg={9} className="me-0">
                                            <div className="row ">
                                                <h4 className="fw-normal">{t('Personal data')}</h4>
                                            </div>
                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-name-field"
                                                            className="mb-0 text-dark form-label required">{t('Name')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="text" name="name"
                                                               placeholder={t('Add name')}  id="addEmployee-name-field"
                                                               maxLength="50"
                                                               className={`fs-7 form-control ${errors.name && touched.name ? "border-danger" : ""}`}
                                                        />

                                                        <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                                <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                    <i className="bi bi-info-circle"></i>
                                                                </div>
                                                            </OverlayTrigger>

                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="name">{msg => <p
                                                        className="fs-7 p-1 text-danger m-0">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-surname-field"
                                                            className="mb-0 text-dark form-label required">{t('Last name')} </label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="text" name="surname"
                                                               id="addEmployee-surname-field"
                                                               placeholder={t('Add Last name')}
                                                               className={`fs-7 form-control ${errors.surname && touched.surname ? "border-danger" : ""}`}
                                                               maxLength="50"
                                                        />
                                                        <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                                <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                    <i className="bi bi-info-circle"></i>
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="surname">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-middleName-field"
                                                            className="mb-0 text-dark form-label required">{t('Middle name')}</label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="text" name="patronymic"
                                                               id="addEmployee-middleName-field"
                                                               placeholder={t('Add Middle name')}
                                                               className="fs-7 form-control"
                                                               maxLength="50"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="patronymic">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-date-field"
                                                            className="mb-0 text-dark form-label required">{t('Date of birth')} </label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <DatePicker
                                                            id='addEmployee-date-field'
                                                            className="form-control w-100"
                                                            wrapperClassName='w-100'
                                                            placeholderText={t('dd.mm.yyyy')}
                                                            selected={startDate}
                                                            onChange={(date) => handleChange(date, setFieldValue)}
                                                            dateFormat="dd.MM.yyyy"
                                                            maxDate={maxDate}
                                                            showYearDropdown
                                                            yearDropdownItemNumber={60}
                                                            scrollableYearDropdown
                                                        />

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-E-mail-field"
                                                            className="mb-0 text-dark form-label required">{t('E-mail')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="email" name='email'
                                                               id="addEmployee-E-mail-field"
                                                               placeholder={t('Add e-mail')}
                                                               className={`fs-7 form-control ${errors.email && touched.email ? "border-danger" : ""}`}
                                                               maxLength="50"
                                                        />
                                                        <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                            <i className="bi bi-shield-check"
                                                               style={{ paddingRight: '12px' }}> </i>
                                                        </div>
                                                    </div>
                                                    <ErrorMessage name="email">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-phone-field"
                                                            className="mb-0 text-dark form-label required">{t('Phone')} </label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '}*
                                                    </span>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">

                                                    <PhonePicker
                                                        name='phone'
                                                        id='addEmployee-phone-field'
                                                    />
                                                    <ErrorMessage name='phone'>{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-passportData-field"
                                                            className="mb-0 text-dark form-label required">{t('Passport Data')} </label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="text" name="passportData"
                                                               placeholder={t('Enter Passport Data')}
                                                               className="fs-7 form-control"
                                                               maxLength="50"
                                                               id="addEmployee-passportData-field"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-12 col-lg-10 ms-auto">
                                                    <ErrorMessage name="passportData">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}
                                                    </ErrorMessage>
                                                    <span
                                                        className="text-grey fs-7"> {values.passportData.length}/10</span>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-SSN-field"
                                                            className="mb-0 text-dark form-label required">{t('SSN (Social Security Number)')} </label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field type="text" name="itn" placeholder={t('Enter SSN')}
                                                               id="addEmployee-SSN-field"
                                                               className="fs-7 form-control"
                                                               maxLength="20"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="itn">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="addEmployee-About-field"
                                                            className="mb-0 text-dark form-label required">{t('About')} </label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <Field as="textarea" name='aboutMe' className="form-control"
                                                               id="addEmployee-About-field"
                                                               placeholder={t('Enter a short description for the master presentation')}

                                                        />
                                                        <div className='position-absolute top-50 end-0 translate-middle-y '>
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                                <div className="position-absolute top-50 translate-middle-y end-0 me-3">
                                                                    <i className="bi bi-info-circle"></i>
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                        <ErrorMessage name="aboutMe">{msg => <p
                                                            className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-10 ms-auto">
                                                <span className="text-grey fs-7">
                                                    {values.aboutMe.length} /400
                                                </span>
                                                </div>
                                            </div>
                                            <div className="row mt-5">
                                                <h4 className="text-dark fw-normal">{t('Professional information')}</h4>
                                            </div>
                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="position-field"
                                                            className="mb-0 text-dark form-label required">{t('Position')}</label>
                                                    </small>
                                                    <span className="text-danger">
                                                        {' '} *
                                                    </span>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <div className='d-flex align-items-center w-100'>
                                                            <div className='w-100'>
                                                                <div className="position-relative w-100">
                                                                    <CustomReactSelect
                                                                        key={key}
                                                                        optionsArray={possitions?.user.company.employeePositions.employeePositions}
                                                                        placeholder={t('Select Position')}
                                                                        setField={setFieldValue}
                                                                        fieldName="employeePosition"
                                                                        value="id"
                                                                        isTooltip={true}
                                                                        initialValue={newPossition}
                                                                        name={"position-field"}
                                                                        id={"addEmployee-Position-field"}
                                                                    />

                                                                    <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                                        <OverlayTrigger
                                                                            overlay={
                                                                                <Popover >
                                                                                    <Popover.Body  >
                                                                                        <Row>
                                                                                            <p className="m-0 mb-1" >{t('The position is displayed inside the system, and the specialization is in the form of an online record.')} </p>
                                                                                        </Row>
                                                                                    </Popover.Body>
                                                                                </Popover>
                                                                            }
                                                                        >
                                                                            <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                                <i className="bi bi-info-circle"></i>
                                                                            </div>
                                                                        </OverlayTrigger>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div>
                                                                <Button
                                                                    type="button"
                                                                    onClick={handlePositionModal}
                                                                    variant="outline-gray"
                                                                    className=" text-dark ms-2  px-3 border square-btn"
                                                                    style={{ minWidth: '50px', height: '50px' }}
                                                                    disabled={ userRole === 5 }
                                                                >

                                                                    <i className="bi bi-plus-lg"></i>
                                                                </Button>
                                                            </div>


                                                        </div>
                                                        <ErrorMessage name="employeePosition">{msg => <p
                                                            className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            htmlFor="specialization-field"
                                                            className="mb-0 text-dark form-label required">
                                                            {t('Specialization')}
                                                            <span className="text-danger"> {' '}*  </span>
                                                        </label>
                                                    </small>
                                                </span>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className="position-relative">
                                                        <CustomCreatableReactSelect
                                                            optionsArray={specialization}
                                                            placeholder={t('Enter specialization')}
                                                            setField={setFieldValue}
                                                            fieldName="specialization"
                                                            value='name'
                                                            isTooltip={true}
                                                            name={"specialization-field"}
                                                            id={"addEmployee-specialization-field"}
                                                        />

                                                        <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                            <OverlayTrigger
                                                                overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                                <div className=" position-absolute top-50 translate-middle-y end-0 me-3">
                                                                    <i className="bi bi-info-circle"></i>
                                                                </div>
                                                            </OverlayTrigger>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-10 ms-auto">
                                                <span
                                                    className="text-grey fs-7"> {values.specialization.length}/50</span>
                                                    <ErrorMessage name="specialization">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-3">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                    <label htmlFor="permissions-field" className="form-label mb-0 text-dark fw-bold">
                                                        <small>
                                                            {t('Editing Permissions')}
                                                            <span className="text-danger"> {' '}*  </span>
                                                        </small>
                                                    </label>
                                                </div>
                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <div className=' w-100'>
                                                        <div className="position-relative">
                                                            <CustomReactSelect
                                                                optionsArray={roles?.role.filter(role => role.id !== 1)}
                                                                placeholder={t('Choose a role')}
                                                                setField={setFieldValue}
                                                                fieldName="role"
                                                                value="id"
                                                                isTooltip={true}
                                                                name={"permissions-field"}
                                                                id={"addEmployee-permissions-field"}
                                                            />

                                                            <div className='position-absolute top-50 end-0 translate-middle-y ' style={{ height: '16px', paddingRight: '12px' }}>
                                                                <OverlayTrigger
                                                                    overlay={
                                                                        <Popover >
                                                                            <Popover.Body  >
                                                                                <Row>
                                                                                    <p className="m-0 mb-1" > {t('\"Editing permissions\" - defines what access the employee has.')} </p>
                                                                                </Row>
                                                                                <Row>
                                                                                    <p className="m-0 mb-1" > {t('Owner - unlimited access to all sections.')}</p>
                                                                                </Row>
                                                                                <Row>
                                                                                    <p className="m-0 mb-1" >{t('Administrator - access to all sections except "Tariff Management."')}</p>
                                                                                </Row>
                                                                                <Row>
                                                                                    <p className="m-0 mb-1" >{t('Employee - has limited rights, he can only view his schedule, his record log, information about himself and the services he performs.')}  </p>
                                                                                </Row>
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <div className="position-absolute top-50 translate-middle-y end-0 me-3">
                                                                        <i className="bi bi-info-circle"></i>
                                                                    </div>
                                                                </OverlayTrigger>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <ErrorMessage name="role">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>

                                            </div>
                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            className="mb-0 text-dark form-label required">{t('Qualification Confirmation')}</label>
                                                    </small>
                                                </span>
                                                </div>

                                                <div className="col-12 col-lg-10 pe-lg-5">

                                                    <div className="mb-0 ps-sm-1 mb-2 text-dark">
                                                    <span className="fs-7">
                                                        {t('Add JPEG images of diplomas, certificates')}{' '}
                                                    </span>
                                                        <OverlayTrigger
                                                            overlay={<Tooltip>{t('This data is displayed in the Online Booking form')}</Tooltip>}>
                                                            <i className="bi bi-info-circle" style={{ paddingRight: '12px' }}></i>
                                                        </OverlayTrigger>
                                                    </div>
                                                    <Row className='m-0'>
                                                        <DropPhotosMultiply
                                                            photosArray={photosQualification}
                                                            setPhotos={setPhotosQualification}
                                                        />
                                                    </Row>
                                                </div>
                                                <div className="col-12 col-lg-10 ms-auto">
                                                    <span className="text-grey fs-7 "> {t('Maximum size: 3 MB')}</span>
                                                    <ErrorMessage name="fileRejection">{msg => <p
                                                        className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                                </div>
                                            </div>

                                            <div className="row mt-lg-4">
                                                <div className="col-12 col-lg-2 mt-2 pt-1">
                                                <span className="text-dark fw-bold">
                                                    <small>
                                                        <label
                                                            className="mb-0 text-dark form-label required"> {t('Contract')}</label>
                                                    </small>
                                                </span>
                                                </div>

                                                <div className="col-12 col-lg-10 pe-lg-5">
                                                    <DropItem
                                                        index={0}
                                                        handleFile={setFieldValue}
                                                        fieldName={"contract"}
                                                        file={true}
                                                    />
                                                </div>
                                                <div className="col-12 col-lg-10 ms-auto">
                                                <span
                                                    className="text-grey fs-7">{t('Document formats: PDF, DOC, DOCX')}
                                                </span>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col xs={12} lg={3} className="ps-lg-3 ps-0">
                                            <h4 className="text-dark pb-3 mt-1">
                                                {t('Employee\'s photo')}
                                            </h4>
                                            <ImageCrop setFieldValue={setFieldValue} modalTitle={t('Employee\'s photo')} notBGinfo/>
                                        </Col>
                                    </div>


                                    <div className="d-sm-flex mb-5 mt-3">
                                        <Button variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-3 custom-btn rounded-1" onClick={() => handleCancel()}>
                                            {t("Cancel")}
                                        </Button>
                                        <Button type="submit" variant="primary" className="fw-bold custom-btn rounded-1">
                                            {t('save')}
                                        </Button>
                                    </div>

                                </div>


                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('Limit on Number of Employees')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p className="text-center mb-0">
                                            {t('Your tariff plan includes a limit on the number of employees (5 technicians + 1 administrator + 1 owner). The current employee will be created with disabled access.')}  <Link to={`/payment/company/${companyId?.user?.company?.id}/tariff`}>Premium.</Link>
                                        </p>
                                        <p className="text-center mb-0">
                                            <ErrorMessage name="email">{msg => <p
                                                className="fs-7 m-0 p-1 text-danger">{msg}</p>}</ErrorMessage>
                                        </p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Col>
                                            <Link className="btn btn-outline-primary border-grey w-100 h-100 fw-normal"
                                                  onClick={handleClose} >
                                                {t('cancel')}
                                            </Link>
                                        </Col>
                                        <Col>
                                            <button
                                                className="btn btn-primary w-100 text-truncate"
                                                onClick={() => handleConfirm(handleSubmit, setConfirming)}
                                            >
                                                OK
                                            </button>
                                        </Col>
                                    </Modal.Footer>
                                </Modal>
                            </Form >
                        </>

                    )}
                </Formik >
            </div>

            {isErrorModalOpen &&
                <ErrorModalComponent
                    i18n={i18next}
                    onClose={() => {
                        setIsErrorModalOpen(null)
                    }}
                    isOpen={!!isErrorModalOpen}
                    currentError={isErrorModalOpen}
                />
            }
            {run && stepIndex === 2 && <JoyrideWrapper run={run} />}
        </>
    );
};

export default AddEmployee;
