import React from 'react';
import { Modal, Row, Col, Form as Formb, Button } from 'react-bootstrap';
import { Form, Formik, Field, ErrorMessage, FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useUpdateEmployeePositionMutation } from '@me-team/host/main/addEmployee/graphql/addEmployee.hooks';

interface EmployeePositionProxy {
    __typename?: "EmployeePositionProxy";
    id: number;
    name: string;
    description?: string;
    employees?: { __typename?: "EmployeeProxy"; id: number; specialization?: string; }[];
}

interface EditPositionModalProps {
    show: boolean;
    handleModal: (employee: EmployeePositionProxy) => void;
    companyId: number;
    refetch?: () => void;
    edit?: boolean;
    editValues?: any;
    selectedPosition?: any;
    toggleShowToast?: (text: string) => void
}

const EditPositionModal: React.FC<EditPositionModalProps> = ({
    show,
    handleModal,
    companyId,
    refetch,
    edit,
    editValues,
    selectedPosition,
    toggleShowToast
}) => {
    const { t } = useTranslation();
    const [updatePosition] = useUpdateEmployeePositionMutation();

    const initialValues = {
        companyId: companyId,
        positionId: selectedPosition?.id,
        name: selectedPosition?.name || '',
        description: selectedPosition?.description || ''
    };

    const handleSubmit = async (values: FormikValues) => {
        try {
            const response = await updatePosition({
                variables: {
                    positionId: values.positionId,
                    companyId: values.companyId,
                    name: values.name,
                    description: values.description
                }
            });
            refetch();
            setTimeout(() => handleModal(null), 0);
            toggleShowToast(t('Saved'))
        } catch (error) {
            console.error('Error updating position:', error);
        }
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().trim(t('Name cannot be empty or contain only spaces')).max(50, t('The maximum length of the Job Title field is 50 characters')).required(t('Position\'s name')),
        description: Yup.string().max(400, t('The maximum length of the Description field is 400 characters'))
    });

    return (
        <Modal size="lg" show={show} onHide={() => handleModal(null)} centered>
            <Modal.Header className="border-0" closeButton>
                <h5 className="m-0"> {t('Edit position')}</h5>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                >
                    {({ values, errors }) => (
                        <Form>
                            <Formb.Group as={Row} className="mb-2">
                                <Formb.Label column sm={2} className="text-dark fw-bold" htmlFor="editPositionModal-name-field">
                                    <small>{t('Position\'s name')}  <span className="text-danger">{' '}*</span></small>
                                </Formb.Label>
                                <Col sm={10}>
                                    <Field
                                        as={Formb.Control}
                                        type="text"
                                        name="name"
                                        placeholder={t('Select Position')}
                                        className={`fs-7 ${errors.name ? 'is-invalid' : ''}`}
                                        maxLength={50}
                                        id="editPositionModal-name-field"
                                    />
                                    <div className={`fs-7 ${values?.name?.length > 50 ? 'text-danger' : 'text-grey'} mt-1`}>
                                        {values?.name?.length}/50
                                    </div>
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </Col>
                            </Formb.Group>

                            <Formb.Group as={Row} className="mb-2">
                                <Formb.Label htmlFor="editPositionModal-descriptionfield" column sm={2} className="text-dark fw-bold">
                                    <small>{t('Description')}</small>
                                </Formb.Label>
                                <Col sm={10}>
                                    <Field
                                        as="textarea"
                                        className={`fs-7 form-control ${errors.description ? 'is-invalid' : ''}`}
                                        name="description"
                                        maxLength={400}
                                        id="editPositionModal-descriptionfield"
                                    />
                                    <div className={`fs-7 ${values.description.length > 400 ? 'text-danger' : 'text-grey'} mt-1`}>
                                        {values?.description?.length}/400
                                    </div>
                                    <ErrorMessage name="description" component="div" className="text-danger" />
                                </Col>
                            </Formb.Group>


                            <div className="d-flex justify-content-end mt-3">
                                <Button variant="outline-primary" className="fw-normal mb-3 mb-sm-0 me-2 custom-btn rounded-1" onClick={() => handleModal(null)}>
                                    {t("Cancel")}
                                </Button>
                                <Button type="submit" variant="primary" className="fw-bold custom-btn rounded-1">
                                    {t('Save')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default EditPositionModal;
