import React, {useRef, useState} from 'react';
import {Button, Col, Card, Row, Container, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import CustomModal from '@services/ui-components/src/Modal/CustomModal';
import {getButtonProps, getTariffDescription, variantButton} from '../constants';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import {TooltipPopover} from '@services/ui-components';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import ErrorService from '../../../services/ErrorService';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import { useGeneratePaymentUrlMutation } from '@me-team/host/main/tariffPlans/graphql/tariffPlans.hooks';

interface TariffCardProps {
  tariff: string;
  planName: string;
  planNameForFind: string;
  price: number;
  currency: string;
  period: string;
  features: string[];
  currentPlan: boolean;
  planId: number;
  isCurrentTariff: boolean;
}

const TariffCard: React.FC<TariffCardProps> = ({
                                                 tariff,
                                                 planName,
                                                 price,
                                                 currency,
                                                 period,
                                                 features,
                                                 currentPlan,
                                                 planNameForFind,
                                                 planId,
                                                 isCurrentTariff
                                               }) => {
  const {t, i18n} = useTranslation();
  const basePath = process.env.REACT_APP_POS_URL;
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const languageUK = i18n?.language === 'uk' ? 'ua/' : ''
  const checkBoxRef = useRef(null);
  const checkBoxInputRef = useRef(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [showCheckboxError, setShowCheckboxError] = useState(false)
  const periodText = period === 'YEARLY' ? t('Annual Subscription') : t('Monthly Subscription');
  const styleBgHeader = tariff === 'PREMIUM' ? 'bg-primary' : 'bg-violet';
  const textColorPremium = tariff === 'PREMIUM'  ? 'text-additional-violet' : '';
  const textColorPriceLite = tariff === 'LITE' || 'Tariff Lite' ? 'text-primary' : '';
  const BgForBodyPremium = tariff === 'PREMIUM' ? 'bg-violet' : '';
  const currencyForCard = currency === "₴" ? 'UAH' : 'EUR'

  const [generatePaymentUrl] = useGeneratePaymentUrlMutation()

  const [isSubscribeModalOpen, setIsSubscribeModalOpen] = useState(false);
  const handleOpenSubscribeModal = () => {
    setIsSubscribeModalOpen(true);
  }

  const handleCloseSubscribeModal = () => {
    setIsSubscribeModalOpen(false)
  }

  const handleSubscribe = () => {
    if (!isCheckboxChecked) {
      setShowCheckboxError(true);
      checkBoxInputRef.current.focus();
      return
    }
    setShowCheckboxError(false);
    const input: any = {
      plan: planId,
      currency: currency === "₴" ? "UAH" : "EUR"
    }
    generatePaymentUrl({
      variables: {companyId: +currentCompanyId, input},
      context: {
        errorType: 'local'
      },
      onCompleted: (data) => {
        if (data) {
          window.location.href = data.generatePaymentUrl;
        }
        handleCloseSubscribeModal()
      },
      onError: (error: ApolloError) => {
        setIsErrorModalOpen(ErrorService.errorHandling(error))
      }
    });
  }
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e && setIsCheckboxChecked(e.target.checked);
    e.target.checked === true && setShowCheckboxError(false)
  };

  useOnClickOutside(checkBoxRef, () => setShowCheckboxError(false));

  const buttonProps = getButtonProps(isCurrentTariff, currentPlan, planNameForFind);

  const renderButton = (isDisabled: boolean, text: string) => (
    <Button
      variant={variantButton(tariff)}
      disabled={isDisabled}
      onClick={!isDisabled ? handleOpenSubscribeModal : undefined}
    >
      {t(text)}
    </Button>
  );

  const handleLabelClick = (event: React.MouseEvent) => {
    event.preventDefault();
    checkBoxInputRef.current?.focus();
  };

  return (
    <Col sm={12} lg={4} className={`${currentPlan ? 'current-plan' : ''}`}>
      <Card
        className='border border-additional-grey d-flex flex-column justify-content-between  h-100'>
        <Card.Body className={`${BgForBodyPremium} flex-grow-1 `}>
          <div>
            <Card.Title
              className={`d-flex flex-column align-items-center p-4 m-0 gap-2 h-100 ${styleBgHeader}`}
            >
              <span className={`fs-6 TextColorPremium ${textColorPremium}`}>{t(tariff)}</span>
              <div>
                <h3
                  className={`m-0 fw-bold ${textColorPremium} ${textColorPriceLite}`}
                >
                  {price !== 0 ? price.toFixed(2) : price} {t(currencyForCard)} {tariff !== 'STARTER' &&
                  <span
                    className={`${tariff === 'PREMIUM' ? 'text-white' : 'text-grey'} fw-normal fs-normal`}>
                  {t('/month')}
                </span>}
                </h3>
              </div>
              <span
                className={`text-grey fs-7 ${textColorPremium}`}>{tariff && t(getTariffDescription(tariff))}
              </span>
            </Card.Title>
            <Row className='m-0'>
              <ul className='p-4 m-0 d-grid row-gap-3'>
                {features.map((feature, index) => (
                  <li key={index} className='list-unstyled'><i
                    className="bi bi-check-circle text-primary me-2"></i>{t(feature)}</li>
                ))}
              </ul>
            </Row>
          </div>
        </Card.Body>
        <Row className={`${BgForBodyPremium} p-4 m-0`}>
          {buttonProps ? renderButton(buttonProps?.isDisabled, buttonProps?.text) : null}
        </Row>
      </Card>
      <CustomModal
        show={isSubscribeModalOpen}
        handleClose={handleCloseSubscribeModal}
        title={
          <Container>
            <Row>
              <h5 className='m-0 p-0 fw-normal'>{t('Registration of Subscription')}</h5>
            </Row>
            <Row>
              <p className='fs-normal fw-normal text-grey p-0 m-0'>
                <span className='me-1'>{planName}</span>
                ({periodText})
              </p>
            </Row>
          </Container>
        }
        variantTitle='fs-5'
        body={
          <Container>
            <Row className='mb-3'>
              <Col className='d-flex text-start p-0'>
                <span className='fs-6 '>{t('Payment information')}</span>
              </Col>
            </Row>
            <Row className='fs-7 text-grey py-2'>
              <Col xs={8} className='p-0 text-start'>
                <span className='me-1'>{planName}</span>
                <span>({periodText})</span>
              </Col>
              <Col xs={4} className='p-0 text-end'>
                <span className='text-dark'>
                  {`${price.toFixed(2)} ${currency}`}
                </span>
              </Col>
            </Row>
            <Row>
              <hr className='my-2'/>
            </Row>
            <Row className='fs-7 text-grey py-2 mb-4'>
              <Col xs={8} className='p-0 text-start'>
                <span className='me-1'>{t('Total')}</span>
              </Col>
              <Col xs={4} className='p-0 text-end'>
                <span className='text-dark fs-6 fw-bold'>
                  {`${price.toFixed(2)}`}
                </span>
                <span className='text-dark fs-6 fw-medium ms-1'>{currency}</span>
              </Col>
            </Row>
            <Row ref={checkBoxRef}>
              <TooltipPopover
                placement="bottom"
                text={
                  <div className='d-flex align-items-center'>
                    <i className="bi bi-exclamation-square-fill text-orange me-2 fs-5"></i>
                    <span className='fs-7 text-dark'>
                      {t('Please check the box to agree to the User Agreement and Privacy Policy')}
                    </span>
                  </div>
                }
                styleBdy='py-1 px-2'
                show={showCheckboxError}
                maxWidth='500px'
                trigger='click'
              >
                <Form.Check
                  id='tariffPlans-agreement-field'
                  ref={checkBoxInputRef}
                  className='text-start pe-0'
                  required
                  label={
                    <Form.Check.Label htmlFor='tariffPlans-agreement-field' onClick={handleLabelClick}>
                      <Col className='fs-7'>
                        <span>{t('By clicking "To Payment", you accept')}</span>
                        &nbsp;
                        <a href={`${basePath}/${languageUK}terms-and-conditions`}
                           target="_blank"
                           className='text-decoration-none'
                        >
                          <span>{t('User Agreement')}</span>
                        </a>
                        &nbsp;
                        <span>{t('and')}<br/></span>
                        <a href={`${basePath}/${languageUK}privacy-policy`}
                           className='text-decoration-none'
                           target="_blank"
                        >
                          <span>{t('Privacy Policy')}</span>
                        </a>
                      </Col>
                    </Form.Check.Label>
                  }
                  onChange={handleCheckboxChange}
                />
              </TooltipPopover>
            </Row>
          </Container>
        }
        buttons={[
          {
            text: t('Cancel'),
            onClick: handleCloseSubscribeModal,
            variant: 'outline-primary',
            className: 'w-100 fw-normal',
          },
          {
            text: t('To Payment'),
            onClick: handleSubscribe,
            variant: 'primary',
            className: 'w-100 fw-normal',
          },
        ]}
      />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </Col>
  )
};

export default TariffCard;
