import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { StepFunctions } from '../joyrideSteps';
import {useState} from "react";
import StartStep from "./StartStep";
import {useTranslation} from "react-i18next";

const UrlStep: React.FC<StepFunctions> = ({ goToStep }) => {
    const { t } = useTranslation();
    const [showStartStep, setShowStartStep] = useState(false);
    const handleButtonClick = () => setShowStartStep(true);
    return (
        <>
            {showStartStep ? (
                <StartStep setShowStartStep={setShowStartStep}/>
            ) : (
                <>
                    <Row>
                        <Col xs={1} className="p-0 text-center">

                            <i className="bi bi-info-circle"></i>

                        </Col>
                        <Col xs={10}>
                            <p className='fs-7 text-dark'>{t('The URL column displays a link to the online booking form for a company or branch.')}</p>
                        </Col>
                        <Col xs={1} className="p-0 text-center">
                            <Button variant='link' className="p-0" onClick={handleButtonClick}>
                                <i className="bi bi-x-lg "></i>
                            </Button>
                        </Col>
                    </Row>
                    <Row className='m-0 pt-3  border-top'>
                        <Col className='text-start p-0'>
                            <Button variant='outline-primary' className="px-4" onClick={() => {
                                const element = document.querySelector('#table');
                                const tr1 = document.querySelector('.tr1')?.clientWidth
                                const tr2 = document.querySelector('.tr2')?.clientWidth
                                const tr3 = document.querySelector('.tr3')?.clientWidth
                                if (element) {
                                    element.scrollLeft = (tr1 + tr2 + tr3)
                                }
                                goToStep('back')
                            }}>{t('BackOnline')}</Button>
                        </Col>
                        <Col className='text-end p-0'>
                            <Button className="px-4" onClick={() => {
                                const element = document.querySelector('#table');
                                if (element) {
                                    element.scrollLeft = element.scrollWidth - element.clientWidth;
                                }
                                goToStep('next')
                            }}>{t('Next')}</Button>
                        </Col>
                    </Row>
                </>
            )}

        </>
    );
}

export default UrlStep;
