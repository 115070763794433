import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBanner, TableHeader } from '@services/ui-components';
import CreateUpdateClientForm from './CreateUpdateClientForm';
import {ApolloError, useReactiveVar} from '@apollo/client';
import {currentCompanyIdVar} from '@me-team/host/src/apollo/globalVar/state';
import ErrorService from '../../services/ErrorService';
import {useNavigate} from 'react-router-dom';
import {ErrorModalComponent} from '@me-pos/error-modal';
import i18next from 'i18next';
import { useGetModulesListQuery } from '@me-team/host/main/modulesList/graphql/modulesList.hooks';
import { useUpdateCompanyModuleRelationMutation } from '@me-team/host/main/clients/graphql/clients.hooks';

const CreateClient: React.FC = () => {
  const { t } = useTranslation();
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<number>(null);
  const [isLoadAddModule, setIsLoadAddModule] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentCompanyId = useReactiveVar(currentCompanyIdVar);
  const {data: modulesList} = useGetModulesListQuery({
    context: {
      errorType: 'local'
    },
    onError: (error: ApolloError) => {
      setIsErrorModalOpen(ErrorService.errorHandling(error))
    }
  })
  const clientsModuleId = modulesList && modulesList?.relations?.modules?.find(module => module.name === "Clients");


  const [updateCompanyModuleRelation] = useUpdateCompanyModuleRelationMutation({
    variables: {companyId: +currentCompanyId, input: {id: clientsModuleId?.id, isActive: true}},
    context: {
      errorType: 'local'
    },
    onCompleted: (data) => {
      data && setIsLoadAddModule(false)
    },
    onError: (error: ApolloError) => {
      const graphQLErrors = error?.graphQLErrors[0]?.extensions?.category
      error && setIsLoadAddModule(false)
      if (graphQLErrors === 'user') {
        navigate("/error/403", {replace: true} )
      }
      else if (graphQLErrors === 'internal') {
        navigate("/error/500", {replace: true} )
      } else {
        console.error(`[GraphQL error]: ${error?.graphQLErrors[0]?.extensions}`);
      }
    }
  })

  useEffect(() => {
    if (clientsModuleId) {
      updateCompanyModuleRelation()
    }
  }, [currentCompanyId, clientsModuleId]);


  return (
    <div className='mb-3'>
      <TableHeader
        title={t('New Client')}
        paths={[
          { path: `/clients/${currentCompanyId}/new`, label: t('Customers') },
          { path: `/clients/${currentCompanyId}/list`, label: t('List of Clients') },
          { path: `/clients/${currentCompanyId}/new`, label: t('New Client') },
        ]}
        colSize={12}
      />
      <CreateUpdateClientForm />
      {isErrorModalOpen ?
        <ErrorModalComponent
          i18n={i18next}
          onClose={() => {
            setIsErrorModalOpen(null)
          }}
          isOpen={!!isErrorModalOpen}
          currentError={isErrorModalOpen}
        /> : null
      }
    </div>
  );
};

export default CreateClient;
